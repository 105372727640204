import React, { useEffect, useRef } from 'react';
import { X, AlertCircle, CheckCircle, Info, AlertTriangle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export type ToastType = 'success' | 'error' | 'warning' | 'info';

interface ToastProps {
  id: string;
  type: ToastType;
  message: string;
  onDismiss: (id: string) => void;
}

const toastIcons = {
  success: <CheckCircle className="h-6 w-6 text-green-400" />,
  error: <AlertCircle className="h-6 w-6 text-red-400" />,
  warning: <AlertTriangle className="h-6 w-6 text-yellow-400" />,
  info: <Info className="h-6 w-6 text-blue-400" />,
};

const toastColors = {
  success: {
    border: 'border-l-4 border-l-green-500',
    glow: 'shadow-[0_0_15px_rgba(34,197,94,0.3)]',
    icon: 'text-green-400',
    gradient: 'from-green-500/10 to-transparent',
  },
  error: {
    border: 'border-l-4 border-l-red-500',
    glow: 'shadow-[0_0_15px_rgba(239,68,68,0.3)]',
    icon: 'text-red-400',
    gradient: 'from-red-500/10 to-transparent',
  },
  warning: {
    border: 'border-l-4 border-l-yellow-500',
    glow: 'shadow-[0_0_15px_rgba(234,179,8,0.3)]',
    icon: 'text-yellow-400',
    gradient: 'from-yellow-500/10 to-transparent',
  },
  info: {
    border: 'border-l-4 border-l-blue-500',
    glow: 'shadow-[0_0_15px_rgba(59,130,246,0.3)]',
    icon: 'text-blue-400',
    gradient: 'from-blue-500/10 to-transparent',
  },
};

export const Toast: React.FC<ToastProps> = ({ id, type, message, onDismiss }) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const toastRef = useRef<HTMLDivElement>(null);

  // Set up auto-dismiss timer
  useEffect(() => {
    timer.current = setTimeout(() => {
      onDismiss(id);
    }, 5000);

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [id, onDismiss]);

  // Pause timer on hover
  const handleMouseEnter = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  // Resume timer on mouse leave
  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      onDismiss(id);
    }, 3000);
  };

  return (
    <motion.div
      ref={toastRef}
      className={`relative overflow-hidden rounded-lg ${toastColors[type].border} bg-gradient-to-r from-black to-gray-900 backdrop-blur-sm shadow-lg ${toastColors[type].glow}`}
      initial={{ opacity: 0, x: 100, scale: 0.9 }}
      animate={{ opacity: 1, x: 0, scale: 1 }}
      exit={{ opacity: 0, x: 100, scale: 0.9 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Gradient overlay */}
      <div className={`absolute inset-0 bg-gradient-to-r ${toastColors[type].gradient} opacity-20`} />
      
      {/* Gold accent line at top */}
      <div className="absolute top-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-yellow-500/70 to-transparent" />
      
      {/* Content */}
      <div className="relative flex items-center justify-between p-4">
        <div className="flex items-center space-x-3">
          <div className={`p-2 rounded-full bg-black/50 ${toastColors[type].icon}`}>
            {toastIcons[type]}
          </div>
          <p className="font-medium text-white">{message}</p>
        </div>
        <button
          onClick={() => onDismiss(id)}
          className="ml-4 p-1 rounded-full hover:bg-black/30 text-gray-400 hover:text-white transition-colors"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      
      {/* Progress bar */}
      <motion.div 
        className="absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-600"
        initial={{ width: "0%" }}
        animate={{ width: "100%" }}
        transition={{ duration: 5, ease: "linear" }}
        style={{ originX: 0 }}
      />
    </motion.div>
  );
};