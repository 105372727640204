import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail, Lock, Eye, EyeOff, User, Phone } from 'lucide-react';
import { signupSchema } from '../../utils/validation';
import { SignupFormData } from '../../types/auth';
import { useNavigate } from 'react-router-dom';
import { useToastContext } from '../../context/ToastContext';

// Example country codes
const countryCodes = [
  { code: '+1', name: 'United States' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+91', name: 'India' },
  { code: '+61', name: 'Australia' },
  // Add more country codes as needed
];

interface SignupFormProps {
  onSubmit: (data: SignupFormData) => Promise<void>;
}

const SignupForm: React.FC<SignupFormProps> = ({ onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('+91'); // Default country code
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormData>({
    resolver: zodResolver(signupSchema),
  });

  const handleFormSubmit = async (data: SignupFormData) => {
    try {
      setIsLoading(true);
      // Combine country code with phone number
      data.phone = `${selectedCountryCode}${data.phone}`;
      await onSubmit(data);
      showToast('success', 'Registration successful! Please verify your email.');
      
      // Navigate to check email page with the email as state
      navigate('/check-email', { state: { email: data.email } });
    } catch (error: any) {
      showToast('error', error.response?.data?.message || 'Registration failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
      {/* Full Name Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Full Name
        </label>
        <div className="relative">
          <User className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('name')}
            type="text"
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-2 px-10 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10"
            placeholder="Enter your full name"
          />
        </div>
        {errors.name && (
          <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
        )}
      </div>

      {/* Email Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Email
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('email')}
            type="email"
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-2 px-10 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10"
            placeholder="Enter your email"
          />
        </div>
        {errors.email && (
          <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
        )}
      </div>

      {/* Phone Number Input with Country Code */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Phone Number
        </label>
        <div className="flex">
          <div className="relative w-1/4 mr-2">
            <select
              value={selectedCountryCode}
              onChange={(e) => setSelectedCountryCode(e.target.value)}
              className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-2 px-3 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10"
            >
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.code} ({country.name})
                </option>
              ))}
            </select>
          </div>
          <div className="relative w-3/4">
            <Phone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            <input
              {...register('phone')}
              type="tel"
              className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-2 px-10 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10"
              placeholder="1234567890"
            />
          </div>
        </div>
        {errors.phone && (
          <p className="mt-1 text-sm text-red-500">{errors.phone.message}</p>
        )}
      </div>

      {/* Password Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Password
        </label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('password')}
            type={showPassword ? 'text' : 'password'}
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-2 px-10 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10"
            placeholder="Enter your password"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white"
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        </div>
        {errors.password && (
          <p className="mt-1 text-sm text-red-500">{errors.password.message}</p>
        )}
      </div>

      {/* Confirm Password Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Confirm Password
        </label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('confirmPassword')}
            type={showConfirmPassword ? 'text' : 'password'}
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-2 px-10 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10"
            placeholder="Confirm your password"
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white"
          >
            {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        </div>
        {errors.confirmPassword && (
          <p className="mt-1 text-sm text-red-500">{errors.confirmPassword.message}</p>
        )}
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isLoading}
        className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white py-2 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Creating account...
          </span>
        ) : (
          'Create Account'
        )}
      </button>
    </form>
  );
};

export default SignupForm;