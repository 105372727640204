import React from 'react';
import { Crown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface GoldButtonProps {
  variant?: 'header' | 'default';
  className?: string;
}

const GoldButton: React.FC<GoldButtonProps> = ({ 
  variant = 'default',
  className = '' 
}) => {
  const navigate = useNavigate();
  const { isAuthenticated, setShowAuthModal } = useAuth();

  const handleClick = () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }
    navigate('/subscription');
  };

  if (variant === 'header') {
    return (
      <button
        onClick={handleClick}
        className={`flex items-center space-x-1 bg-yellow-500/10 hover:bg-yellow-500/20 text-yellow-500 px-3 py-1.5 rounded-full transition-all ${className}`}
      >
        <Crown size={16} />
        <span className="font-medium">Get Gold</span>
      </button>
    );
  }

  return (
    <button
      onClick={handleClick}
      className={`flex items-center space-x-2 bg-yellow-500 text-black px-6 py-3 rounded-full font-semibold hover:bg-yellow-400 transition-colors ${className}`}
    >
      <Crown size={20} />
      <span>Upgrade to Gold</span>
    </button>
  );
};
export default GoldButton;