import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeft, Loader, AlertCircle } from 'lucide-react';
import { useToastContext } from '../../context/ToastContext';
import { predictionSchema } from '../../utils/validation';
import { ROUTES } from '../../utils/constants';
import { predictionsService } from '../../services/predictions.service';
import { PredictionData } from '../../types';

const EditPrediction = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(predictionSchema),
  });

  // Fetch prediction data
  useEffect(() => {
    const fetchPrediction = async () => {
      if (!id) {
        setError('Invalid prediction ID: ID is missing');
        setIsFetching(false);
        showToast('error', 'Invalid prediction ID');
        return;
      }
      
      try {
        setIsFetching(true);
        setError(null);
        console.log('Fetching prediction with ID:', id);
        const prediction = await predictionsService.getPredictionDetails(id);
        console.log('Fetched prediction:', prediction);
        
        // Reset form with prediction data
        reset({
          symbol: prediction.symbol,
          category: prediction.category,
          subscriptionTier: prediction.subscriptionTier,
          type: prediction.type,
          entryPrice: prediction.entryPrice,
          stopLoss: prediction.stopLoss,
          takeProfit: prediction.takeProfit,
          confidence: prediction.confidence,
          analysis: prediction.analysis,
          status: prediction.status,
        });
      } catch (err: any) {
        console.error('Error fetching prediction:', err);
        setError(err.message || err.response?.data?.message || 'Failed to fetch prediction');
        showToast('error', 'Failed to fetch prediction details');
      } finally {
        setIsFetching(false);
      }
    };

    fetchPrediction();
  }, [id, reset, showToast]);

  const onSubmit = async (data: any) => {
    if (!id) {
      setError('Invalid prediction ID: ID is missing');
      showToast('error', 'Invalid prediction ID');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      console.log('Updating prediction with ID:', id);
      console.log('Update data:', data);
      await predictionsService.updatePrediction(id, data);
      showToast('success', 'Prediction updated successfully');
      navigate(ROUTES.ADMIN.MANAGE_PREDICTIONS);
    } catch (err: any) {
      console.error('Error updating prediction:', err);
      setError(err.message || err.response?.data?.message || 'Failed to update prediction');
      showToast('error', 'Failed to update prediction');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Admin Header */}
      <header className="bg-gray-900 border-b border-gray-800 px-6 py-4">
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigate(ROUTES.ADMIN.MANAGE_PREDICTIONS)}
            className="flex items-center text-gray-400 hover:text-white"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Predictions
          </button>
          <h1 className="text-2xl font-bold text-white">Edit Prediction</h1>
          <div className="w-24"></div> {/* Spacer for alignment */}
        </div>
      </header>

      <div className="container mx-auto px-6 py-8">
        {isFetching ? (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
            <p className="ml-4 text-gray-400">Loading prediction data...</p>
          </div>
        ) : error ? (
          <div className="bg-red-500/10 border border-red-500 rounded-lg p-6 max-w-2xl mx-auto">
            <div className="flex items-start">
              <AlertCircle className="h-6 w-6 text-red-500 mr-3 mt-0.5" />
              <div>
                <h3 className="text-lg font-semibold text-white mb-2">Error Loading Prediction</h3>
                <p className="text-gray-400">{error}</p>
                <button
                  onClick={() => navigate(ROUTES.ADMIN.MANAGE_PREDICTIONS)}
                  className="mt-4 bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition-colors"
                >
                  Return to Predictions
                </button>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl mx-auto space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Symbol
              </label>
              <input
                {...register('symbol')}
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                placeholder="e.g., BTC/USD"
              />
              {errors.symbol && (
                <p className="mt-1 text-sm text-red-500">{errors.symbol.message as string}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Category
              </label>
              <select
                {...register('category')}
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                <option value="forex">Forex</option>
                <option value="stocks">Stocks</option>
                <option value="commodities">Commodities</option>
              </select>
              {errors.category && (
                <p className="mt-1 text-sm text-red-500">{errors.category.message as string}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Subscription Tier
              </label>
              <select
                {...register('subscriptionTier')}
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                <option value="free">Free</option>
                <option value="regular">Regular</option>
                <option value="pro">Pro</option>
                <option value="premium">Premium</option>
                <option value="gold">Gold</option>
              </select>
              {errors.subscriptionTier && (
                <p className="mt-1 text-sm text-red-500">{errors.subscriptionTier.message as string}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Type
              </label>
              <select
                {...register('type')}
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                <option value="buy">Buy</option>
                <option value="sell">Sell</option>
              </select>
              {errors.type && (
                <p className="mt-1 text-sm text-red-500">{errors.type.message as string}</p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Entry Price
                </label>
                <input
                  {...register('entryPrice', { valueAsNumber: true })}
                  type="number"
                  step="0.0001"
                  className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                />
                {errors.entryPrice && (
                  <p className="mt-1 text-sm text-red-500">{errors.entryPrice.message as string}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Stop Loss
                </label>
                <input
                  {...register('stopLoss', { valueAsNumber: true })}
                  type="number"
                  step="0.0001"
                  className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                />
                {errors.stopLoss && (
                  <p className="mt-1 text-sm text-red-500">{errors.stopLoss.message as string}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Take Profit
                </label>
                <input
                  {...register('takeProfit', { valueAsNumber: true })}
                  type="number"
                  step="0.0001"
                  className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                />
                {errors.takeProfit && (
                  <p className="mt-1 text-sm text-red-500">{errors.takeProfit.message as string}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Confidence (%)
              </label>
              <input
                {...register('confidence', { valueAsNumber: true })}
                type="number"
                min="0"
                max="100"
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              />
              {errors.confidence && (
                <p className="mt-1 text-sm text-red-500">{errors.confidence.message as string}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Status
              </label>
              <select
                {...register('status')}
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                <option value="active">Active</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Analysis
              </label>
              <textarea
                {...register('analysis')}
                rows={4}
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                placeholder="Enter your market analysis..."
              />
              {errors.analysis && (
                <p className="mt-1 text-sm text-red-500">{errors.analysis.message as string}</p>
              )}
            </div>

            <div className="flex space-x-4">
              <button
                type="submit"
                disabled={isLoading}
                className="flex-1 bg-yellow-500 text-black py-3 rounded-lg font-semibold hover:bg-yellow-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <Loader className="animate-spin h-5 w-5 mr-2" />
                    Updating...
                  </span>
                ) : (
                  'Update Prediction'
                )}
              </button>
              
              <button
                type="button"
                onClick={() => navigate(ROUTES.ADMIN.MANAGE_PREDICTIONS)}
                className="flex-1 bg-gray-800 text-white py-3 rounded-lg font-semibold hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditPrediction;