import axiosInstance from '../utils/axios';
import { SubscriptionPlan, SubscriptionStatus } from '../types/subscription';

export const subscriptionService = {
  getPlans: async (): Promise<SubscriptionPlan[]> => {
    const { data } = await axiosInstance.get('/subscription/plans');
    return data.plans;
  },

  getStatus: async (): Promise<{
    status: 'active' | 'inactive' | 'expired';
    subscriptionType: string | null;
    startDate: string | null;
    endDate: string | null;
    features: string[];
  }> => {
    const { data } = await axiosInstance.get('/subscription/status');
    return data;
  },

  createOrder: async (planId: string, amount: number, planType: string): Promise<{
    orderId: string;
    amount: number;
    currency: string;
  }> => {
    const { data } = await axiosInstance.post('/subscription/create-order', {
      planId,
      amount,
      planType,
      currency: 'INR'
    });
    return data;
  },

  verifyPayment: async (paymentData: {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
    planType: string;
  }): Promise<{
    success: boolean;
    subscription?: SubscriptionStatus;
  }> => {
    const { data } = await axiosInstance.post('/subscription/verify-payment', paymentData);
    return data;
  }
};