import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { subscriptionService } from '../services/subscription.service';
import { SubscriptionTier } from '../types/subscription';
import { useToastContext } from './ToastContext';

interface SubscriptionContextType {
  subscriptionStatus: 'active' | 'inactive' | 'expired';
  subscriptionType: SubscriptionTier | null;
  subscriptionStartDate: Date | null;
  subscriptionEndDate: Date | null;
  features: string[];
  isLoading: boolean;
  refreshStatus: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { showToast } = useToastContext();
  const [subscriptionStatus, setSubscriptionStatus] = useState<'active' | 'inactive' | 'expired'>('inactive');
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionTier | null>(null);
  const [subscriptionStartDate, setSubscriptionStartDate] = useState<Date | null>(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<Date | null>(null);
  const [features, setFeatures] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refreshStatus = async () => {
    if (!isAuthenticated) {
      setSubscriptionStatus('inactive');
      setSubscriptionType(null);
      setSubscriptionStartDate(null);
      setSubscriptionEndDate(null);
      setFeatures([]);
      return;
    }

    try {
      setIsLoading(true);
      const data = await subscriptionService.getStatus();
      
      setSubscriptionStatus(data.status);
      setSubscriptionType(data.subscriptionType as SubscriptionTier);
      setSubscriptionStartDate(data.startDate ? new Date(data.startDate) : null);
      setSubscriptionEndDate(data.endDate ? new Date(data.endDate) : null);
      setFeatures(data.features || []);

      // Check if subscription is about to expire
      if (data.endDate) {
        const endDate = new Date(data.endDate);
        const daysUntilExpiry = Math.ceil((endDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
        
        if (daysUntilExpiry <= 3 && daysUntilExpiry > 0) {
          showToast('warning', `Your subscription will expire in ${daysUntilExpiry} days`);
        }
      }
    } catch (error) {
      console.error('Failed to fetch subscription status:', error);
      showToast('error', 'Failed to fetch subscription status');
      setSubscriptionStatus('inactive');
      setSubscriptionType(null);
      setSubscriptionStartDate(null);
      setSubscriptionEndDate(null);
      setFeatures([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Refresh status when auth state changes
  useEffect(() => {
    refreshStatus();
  }, [isAuthenticated, user]);

  // Check subscription status periodically
  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(refreshStatus, 60000); // Check every minute
      return () => clearInterval(interval);
    }
  }, [isAuthenticated]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionStatus,
        subscriptionType,
        subscriptionStartDate,
        subscriptionEndDate,
        features,
        isLoading,
        refreshStatus,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};

export default SubscriptionProvider;