import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TrendingUp, TrendingDown, Clock } from 'lucide-react';

interface BarData {
  value: number;
  timestamp: number;
  isPositive: boolean;
}

interface BarChartProps {
  symbol: string;
  initialValue?: number;
  volatility?: number;
  updateInterval?: number;
  trendBias?: number; // 0-1, higher means more likely to trend up
  type?: 'forex' | 'stock' | 'crypto' | 'commodity';
}

const BarChart: React.FC<BarChartProps> = ({
  symbol,
  initialValue = 100,
  volatility = 1,
  updateInterval = 15,
  trendBias = 0.5,
  type = 'forex',
}) => {
  const [bars, setBars] = useState<BarData[]>([]);
  const [isUptrend, setIsUptrend] = useState<boolean>(Math.random() > 0.5);
  const [countdown, setCountdown] = useState<number>(updateInterval);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [predictionConfidence, setPredictionConfidence] = useState<number>(
    Math.floor(Math.random() * 16) + 80
  );
  const [currentValue, setCurrentValue] = useState<number>(initialValue);
  
  // Use refs to prevent infinite update loops
  const currentValueRef = useRef<number>(initialValue);
  const isUptrendRef = useRef<boolean>(Math.random() > 0.5);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Generate random bar data
  const generateBarData = (count: number, isUptrend: boolean, startValue: number): BarData[] => {
    const barsData: BarData[] = [];
    let baseValue = startValue;
    const now = Date.now();
    
    for (let i = 0; i < count; i++) {
      // Create some volatility but follow the general trend
      const randomFactor = Math.random() * 2 - 1; // -1 to 1
      const volatilityFactor = randomFactor * volatility;
      const trendFactor = isUptrend ? volatility * 0.2 : -volatility * 0.2;
      
      // Determine if this bar is positive or negative (with bias based on overall trend)
      const barIsPositive = Math.random() > (isUptrend ? 0.3 : 0.7);
      
      // Calculate value change
      const valueChange = (barIsPositive ? Math.abs(volatilityFactor) : -Math.abs(volatilityFactor)) + trendFactor;
      baseValue += valueChange;
      
      // Ensure we don't go below zero
      if (baseValue < 1) baseValue = 1 + Math.random() * 5;
      
      barsData.push({
        value: baseValue,
        timestamp: now - (count - i) * 1000,
        isPositive: barIsPositive
      });
    }
    
    return barsData;
  };

  // Initialize data
  useEffect(() => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    // Initialize with data
    const initialBars = generateBarData(15, isUptrendRef.current, initialValue);
    setBars(initialBars);
    currentValueRef.current = initialBars[initialBars.length - 1].value;
    setCurrentValue(currentValueRef.current);
    
    // Set up interval for countdown
    intervalRef.current = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          // Trigger update animation
          setIsUpdating(true);
          
          // Generate new data with possibly new trend
          const newIsUptrend = Math.random() > (1 - trendBias);
          isUptrendRef.current = newIsUptrend;
          setIsUptrend(newIsUptrend);
          
          const newBars = generateBarData(15, newIsUptrend, currentValueRef.current);
          setBars(newBars);
          
          currentValueRef.current = newBars[newBars.length - 1].value;
          setCurrentValue(currentValueRef.current);
          
          // Generate new confidence level (80-95%)
          setPredictionConfidence(Math.floor(Math.random() * 16) + 80);
          
          // Reset updating state after animation
          setTimeout(() => setIsUpdating(false), 1000);
          
          return updateInterval; // Reset countdown
        }
        return prev - 1;
      });
    }, 1000);
    
    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [initialValue, volatility, trendBias, updateInterval]); // Remove dependencies that change frequently

  // Format price based on asset type
  const formatPrice = (value: number): string => {
    switch (type) {
      case 'forex':
        return value.toFixed(4);
      case 'stock':
        return value.toFixed(2);
      case 'crypto':
        return value.toFixed(2);
      case 'commodity':
        return value.toFixed(2);
      default:
        return value.toFixed(2);
    }
  };

  // Get currency/unit symbol
  const getCurrencySymbol = (): string => {
    switch (type) {
      case 'forex':
        return '';
      case 'stock':
        return '$';
      case 'crypto':
        return '$';
      case 'commodity':
        return '$';
      default:
        return '$';
    }
  };
  
  return (
    <div className="relative w-full h-full">
      {/* Glassmorphism Card */}
      <motion.div 
        className="absolute inset-0 rounded-xl backdrop-blur-sm bg-black/30 border border-blue-500/20 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Shimmer Effect */}
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-500/10 to-transparent"
          animate={{ 
            x: ['-100%', '100%'],
            opacity: [0, 0.5, 0]
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 3,
            ease: "easeInOut"
          }}
        />
        
        {/* Chart Title */}
        <div className="absolute top-4 left-4 flex items-center space-x-2">
          <div className={`p-1 rounded-full ${isUptrend ? 'bg-green-500/20' : 'bg-red-500/20'}`}>
            {isUptrend ? 
              <TrendingUp className="h-4 w-4 text-green-500" /> : 
              <TrendingDown className="h-4 w-4 text-red-500" />
            }
          </div>
          <div>
            <h3 className="text-sm font-semibold text-white">AI Prediction</h3>
            <p className="text-xs text-gray-400">{symbol}</p>
          </div>
        </div>
        
        {/* Confidence Level */}
        <div className="absolute top-4 right-4 bg-blue-500/20 px-2 py-1 rounded-full">
          <p className="text-xs text-blue-400 font-medium">{predictionConfidence}% Confidence</p>
        </div>
        
        {/* Countdown Timer */}
        <div className="absolute bottom-4 right-4 flex items-center space-x-1 bg-gray-900/50 px-2 py-1 rounded-full">
          <Clock className="h-3 w-3 text-gray-400" />
          <AnimatePresence mode="wait">
            <motion.p 
              key={countdown}
              className="text-xs text-gray-300"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              Next update in: <span className="font-medium text-white">{countdown}s</span>
            </motion.p>
          </AnimatePresence>
        </div>
        
        {/* Price Value */}
        <div className="absolute bottom-4 left-4">
          <AnimatePresence mode="wait">
            <motion.p 
              key={bars.length ? bars[bars.length - 1].value.toFixed(4) : '0'}
              className={`text-lg font-bold ${isUptrend ? 'text-green-500' : 'text-red-500'}`}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              {getCurrencySymbol()}{bars.length ? formatPrice(bars[bars.length - 1].value) : 0}
            </motion.p>
          </AnimatePresence>
          <p className="text-xs text-gray-400">Current Price</p>
        </div>
        
        {/* Bar Chart SVG */}
        <div className="absolute inset-0 flex items-center justify-center">
          <svg 
            width="300" 
            height="150" 
            viewBox="0 0 300 150" 
            className="overflow-visible"
          >
            {/* Grid Lines */}
            {[...Array(5)].map((_, i) => (
              <line 
                key={`grid-${i}`}
                x1="0" 
                y1={30 * i + 15} 
                x2="300" 
                y2={30 * i + 15} 
                stroke="rgba(255,255,255,0.1)" 
                strokeDasharray="2,2"
              />
            ))}
            
            {/* Bars */}
            {bars.length > 0 && (() => {
              // Find min and max values for scaling
              const allValues = bars.map(b => b.value);
              const minValue = Math.min(...allValues);
              const maxValue = Math.max(...allValues);
              const valueRange = maxValue - minValue || 1; // Avoid division by zero
              
              const barWidth = 12;
              const spacing = 8;
              const totalWidth = barWidth + spacing;
              
              return bars.map((bar, index) => {
                // Calculate position
                const x = 20 + index * totalWidth;
                
                // Scale values to fit in our SVG
                const scaleY = (val: number) => {
                  return 150 - 20 - ((val - minValue) / valueRange) * (150 - 40);
                };
                
                const barHeight = Math.max(2, 150 - 20 - scaleY(bar.value));
                const y = 150 - 20 - barHeight;
                
                return (
                  <motion.rect
                    key={`bar-${index}`}
                    x={x}
                    y={y}
                    width={barWidth}
                    height={barHeight}
                    fill={bar.isPositive ? "#10B981" : "#EF4444"}
                    initial={{ scaleY: 0, opacity: 0 }}
                    animate={{ scaleY: 1, opacity: 1 }}
                    transition={{ duration: 0.5, delay: index * 0.03 }}
                    style={{ transformOrigin: `${x + barWidth / 2}px ${150 - 20}px` }}
                  />
                );
              });
            })()}
          </svg>
          
          {/* Update Flash Animation */}
          <AnimatePresence>
            {isUpdating && (
              <motion.div 
                className="absolute inset-0 bg-white"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.3 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default BarChart;