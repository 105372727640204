import React from 'react';

interface StepCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  isLast: boolean;
}

const StepCard: React.FC<StepCardProps> = ({ icon, title, description, isLast }) => {
  return (
    <div className="relative group">
      {/* Connecting lines between steps */}
      {!isLast && (
        <div className="hidden lg:block absolute top-1/2 left-full w-full h-0.5 bg-gradient-to-r from-blue-500/50 to-transparent -translate-y-1/2 z-0" />
      )}

      {/* Step card */}
      <div className="relative bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-8 rounded-xl border border-blue-500/10 transform transition-all duration-300 group-hover:-translate-y-2 group-hover:shadow-xl group-hover:shadow-blue-500/10 z-10">
        {/* Icon with gradient background */}
        <div className="p-4 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg text-white w-16 h-16 flex items-center justify-center mb-6">
          {icon}
        </div>
        <h3 className="text-xl font-semibold text-white mb-4">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
};

export default StepCard;