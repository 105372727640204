import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Star } from 'lucide-react';

const reviews = [
  {
    id: 1,
    name: 'John Smith',
    role: 'Professional Trader',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?fit=crop&w=64&h=64',
    rating: 5,
    review: "Vevrish's AI predictions have transformed my trading strategy. The accuracy is remarkable.",
  },
  {
    id: 2,
    name: 'Sarah Johnson',
    role: 'Investment Analyst',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?fit=crop&w=64&h=64',
    rating: 5,
    review: 'The institutional insights provided are invaluable. A must-have tool for serious traders.',
  },
  {
    id: 3,
    name: 'Michael Chen',
    role: 'Forex Trader',
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?fit=crop&w=64&h=64',
    rating: 5,
    review: 'Outstanding platform with real-time market analysis. The predictions are spot on.',
  },
  {
    id: 4,
    name: 'Emma Davis',
    role: 'Portfolio Manager',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?fit=crop&w=64&h=64',
    rating: 5,
    review: 'The Smart Money Concepts integration gives a real edge in the market.',
  },
];

const ReviewCard: React.FC<{ review: typeof reviews[0]; index: number }> = ({ review, index }) => {
  return (
    <motion.div
      className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-xl p-6 border border-blue-500/10 shadow-xl transform-gpu"
      initial={{ opacity: 0, rotateY: -30, z: -100 }}
      animate={{ opacity: 1, rotateY: 0, z: 0 }}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      whileHover={{ scale: 1.05, rotateY: 10 }}
    >
      <div className="flex items-center mb-4">
        <img
          src={review.avatar}
          alt={review.name}
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h4 className="text-white font-semibold">{review.name}</h4>
          <p className="text-gray-400 text-sm">{review.role}</p>
        </div>
      </div>
      <div className="flex mb-3">
        {[...Array(review.rating)].map((_, i) => (
          <Star key={i} className="w-4 h-4 text-yellow-500 fill-current" />
        ))}
      </div>
      <p className="text-gray-300">{review.review}</p>
    </motion.div>
  );
};

const ReviewsSection = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    let currentIndex = 0;
    const totalCards = reviews.length;
    
    const rotateCards = async () => {
      await controls.start(i => ({
        opacity: i === currentIndex ? 1 : 0.3,
        scale: i === currentIndex ? 1 : 0.9,
        z: i === currentIndex ? 0 : -100,
        transition: { duration: 0.5 }
      }));
      
      currentIndex = (currentIndex + 1) % totalCards;
    };

    const interval = setInterval(rotateCards, 3000);
    return () => clearInterval(interval);
  }, [controls]);

  return (
    <section className="py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black relative overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold text-white mb-4">
            Trusted by{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              Traders
            </span>
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Join thousands of successful traders who trust Vevrish for their trading decisions
          </p>
        </motion.div>

        <div
          ref={containerRef}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
        >
          {reviews.map((review, index) => (
            <ReviewCard key={review.id} review={review} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;