import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthResponse } from '../types/auth';
import { authApi } from '../services/api';
import { STORAGE_KEYS, USER_ROLES, REDIRECT_PATHS } from '../utils/constants';

interface User {
  id: string;
  name: string;
  email: string;
  phone: string;
  role?: string;
  isVerified?: boolean;
  subscriptionType?: string;
  subscriptionStatus?: string;
  subscriptionEndDate?: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
  login: (token: string, user: User) => void;
  logout: () => void;
  showAuthModal: boolean;
  setShowAuthModal: (show: boolean) => void;
  refreshUser: () => Promise<User | null>;
  isLoading: boolean;
}

interface LocationState {
  from?: {
    pathname: string;
  };
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = useCallback(() => {
    console.log('Logging out user');
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    localStorage.removeItem(STORAGE_KEYS.USER);
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);
    navigate(REDIRECT_PATHS.AFTER_LOGOUT);
  }, [navigate]);

  const handleLogin = useCallback((newToken: string, newUser: User) => {
    console.log('Logging in user:', newUser);
    localStorage.setItem(STORAGE_KEYS.TOKEN, newToken);
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(newUser));
    setToken(newToken);
    setUser(newUser);
    setIsAuthenticated(true);
    setShowAuthModal(false);

    // If user is not verified, redirect to check email page
    if (newUser.isVerified === false) {
      console.log('User not verified, redirecting to check email page');
      navigate('/check-email', { state: { email: newUser.email } });
      return;
    }

    // If user is verified, redirect based on role or default path
    console.log('User verified, redirecting to appropriate page');
    const from = (location.state as LocationState)?.from?.pathname || 
                 (newUser.role === USER_ROLES.ADMIN ? REDIRECT_PATHS.ADMIN : REDIRECT_PATHS.AFTER_LOGIN);
    navigate(from, { replace: true });
  }, [navigate, location]);

  const refreshUser = useCallback(async (): Promise<User | null> => {
    try {
      console.log('Refreshing user data');
      const storedToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
      if (!storedToken) {
        console.log('No token found, cannot refresh user');
        return null;
      }

      // Make API call to get current user data
      const response = await authApi.getCurrentUser();
      
      if (!response || !response.user) {
        console.error('Invalid response from getCurrentUser:', response);
        handleLogout();
        return null;
      }

      const newUser = response.user;
      console.log('Refreshed user data:', newUser);
      
      // Update the stored user data with the latest information
      localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(newUser));
      
      // Update state
      setUser(newUser);
      setIsAuthenticated(true);

      return newUser;
    } catch (error) {
      console.error('Failed to refresh user data:', error);
      handleLogout();
      return null;
    }
  }, [handleLogout]);

  useEffect(() => {
    const initializeAuth = async () => {
      setIsLoading(true);
      try {
        console.log('Initializing authentication');
        const storedToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
        const storedUser = localStorage.getItem(STORAGE_KEYS.USER);

        if (storedToken && storedUser) {
          try {
            const decodedToken = jwtDecode(storedToken);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              console.log('Valid token found, setting user from storage');
              const parsedUser = JSON.parse(storedUser);
              setToken(storedToken);
              setUser(parsedUser);
              setIsAuthenticated(true);

              // Refresh user data to get the latest verification status
              console.log('Refreshing user data to get latest status');
              const updatedUser = await refreshUser();
              
              // If user is verified and is an admin, redirect to admin dashboard
              if (updatedUser && updatedUser.role === USER_ROLES.ADMIN && !location.pathname.startsWith('/admin')) {
                console.log('Admin user detected, redirecting to admin dashboard');
                navigate(REDIRECT_PATHS.ADMIN);
              }
            } else {
              console.log('Token expired, logging out');
              handleLogout();
            }
          } catch (error) {
            console.error('Error decoding token:', error);
            handleLogout();
          }
        } else {
          console.log('No stored authentication found');
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        handleLogout();
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();
  }, [navigate, handleLogout, location.pathname, refreshUser]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const storedToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
      if (storedToken) {
        try {
          const decodedToken = jwtDecode(storedToken);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp && decodedToken.exp < currentTime) {
            console.log('Token expired during session, logging out');
            handleLogout();
          }
        } catch (error) {
          console.error('Error checking token expiration:', error);
          handleLogout();
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(interval);
  }, [handleLogout]);

  const value = {
    isAuthenticated,
    user,
    token,
    login: handleLogin,
    logout: handleLogout,
    showAuthModal,
    setShowAuthModal,
    refreshUser,
    isLoading,
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};