import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useToastContext } from '../../context/ToastContext';
import { SUBSCRIPTION_PLANS } from '../../utils/subscription';
import { initializeRazorpay } from '../../utils/payment';
import PlanCard from './PlanCard';
import { SubscriptionPlan } from '../../types/subscription';

const SubscriptionPlans: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, user, setShowAuthModal } = useAuth();
  const { showToast } = useToastContext();

  const handleSubscribe = async (plan: SubscriptionPlan) => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }

    if (isLoading) return;

    try {
      setIsLoading(true);
      showToast('info', 'Initializing payment...');

      await initializeRazorpay({
        planId: plan.id,
        planType: plan.tier,  // Pass the plan tier as planType
        amount: plan.price * 1, // Convert to paise
        currency: 'INR',
        name: `${plan.name} Subscription`,
        email: user?.email || '',
      });
    } catch (error: any) {
      showToast('error', error.message || 'Failed to process payment');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-20 bg-gradient-to-r from-blue-100/10 to-purple-100/10">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Choose Your <span className="text-yellow-300">Plan</span>
          </h2>
          <p className="text-gray-200 max-w-2xl mx-auto">
            Select the plan that best fits your trading needs and take your trading to the next level.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          {SUBSCRIPTION_PLANS.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              isLoading={isLoading}
              onSubscribe={handleSubscribe}
              isCurrentPlan={user?.subscriptionTier === plan.tier}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubscriptionPlans;