import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import Predictions from './pages/Predictions';
import News from './pages/News';
import About from './pages/About';
import Contact from './pages/Contact';
import AuthModal from './components/auth/AuthModal';
import SubscriptionProvider from './context/SubscriptionContext';
import AdminDashboard from './pages/admin/AdminDashboard';
import CreatePrediction from './pages/admin/CreatePrediction';
import ManagePredictions from './pages/admin/ManagePredictions';
import EditPrediction from './pages/admin/EditPrediction';
import Subscription from './pages/Subscription';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsConditions from './pages/legal/TermsConditions';
import Refund from './pages/legal/Refund';
import ResetPassword from './pages/auth/ResetPassword';
import CheckEmail from './pages/auth/CheckEmail';
import Verification from './pages/Verification';
import SubscriptionStatus from './pages/SubscriptionStatus';
import { ROUTES } from './utils/constants';
import CookieConsent from './components/ui/CookieConsent';
import sitemap from '/public/sitemap.xml';
import ForgotPasswordCheckEmail from './pages/auth/ForgotPasswordCheckEmail';

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authView, setAuthView] = useState<'login' | 'signup' | 'forgotPassword'>('login');

  useEffect(() => {
    if (location.pathname === '/auth/login' || location.pathname === '/auth/signup' || location.pathname === '/auth/forgot-password') {
      setIsAuthModalOpen(true);
      if (location.pathname === '/auth/signup') setAuthView('signup');
      if (location.pathname === '/auth/forgot-password') setAuthView('forgotPassword');
      else setAuthView('login');
    } else {
      setIsAuthModalOpen(false);
    }
  }, [location.pathname]);

  const handleCloseAuthModal = () => {
    setIsAuthModalOpen(false);
    navigate('/');
  };

  const isAdminRoute = location.pathname.startsWith('/admin');
  const isAuthRoute = location.pathname === '/check-email' || location.pathname === '/verify-email';

  return (
    <ToastProvider>
      <AuthProvider>
        <SubscriptionProvider>
          <div className="min-h-screen bg-black text-white">
            {!isAdminRoute && !isAuthRoute && <Header />}
            <main className={isAdminRoute || isAuthRoute ? '' : 'pt-20'}>
              <Routes>
                {/* Public Routes */}
                <Route path={ROUTES.HOME} element={<Home />} />
                <Route path={ROUTES.ABOUT} element={<About />} />
                <Route path={ROUTES.CONTACT} element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsConditions />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/check-email" element={<CheckEmail />} />
                <Route path="/verify-email" element={<Verification />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/subscription/status" element={<SubscriptionStatus />} />
                <Route path="/sitemap.xml" element={<img src={sitemap} />} />
                <Route path="/forgot-password" element={<ForgotPasswordCheckEmail />} />

                {/* Protected Routes */}
                <Route 
                  path={ROUTES.PREDICTIONS} 
                  element={
                    <ProtectedRoute>
                      <Predictions />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path={ROUTES.NEWS} 
                  element={
                    <ProtectedRoute>
                      <News />
                    </ProtectedRoute>
                  } 
                />

                {/* Admin Routes */}
                <Route 
                  path={ROUTES.ADMIN.DASHBOARD} 
                  element={
                    <ProtectedRoute adminOnly>
                      <AdminDashboard />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path={ROUTES.ADMIN.CREATE_PREDICTION} 
                  element={
                    <ProtectedRoute adminOnly>
                      <CreatePrediction />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path={ROUTES.ADMIN.MANAGE_PREDICTIONS} 
                  element={
                    <ProtectedRoute adminOnly>
                      <ManagePredictions />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/admin/edit-prediction/:id" 
                  element={
                    <ProtectedRoute adminOnly>
                      <EditPrediction />
                    </ProtectedRoute>
                  } 
                />
              </Routes>
            </main>
            {!isAdminRoute && !isAuthRoute && <Footer />}
          </div>

          <AuthModal 
            isOpen={isAuthModalOpen} 
            onClose={handleCloseAuthModal} 
            view={authView}
          />
          
          {/* Cookie Consent Banner */}
          <CookieConsent />
        </SubscriptionProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default App;