import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Crown, Calendar, Clock, AlertCircle, Star, Shield, Zap, ChevronRight, RefreshCw
} from 'lucide-react';
import { useSubscription } from '../../context/SubscriptionContext';

export const SubscriptionStatus: React.FC = () => {
  const navigate = useNavigate();
  const {
    subscriptionStatus,
    subscriptionType,
    subscriptionStartDate,
    subscriptionEndDate,
    isLoading
  } = useSubscription();

  const getDaysRemaining = () => {
    if (!subscriptionEndDate) return 0;
    const now = new Date();
    const end = new Date(subscriptionEndDate);
    const diff = end.getTime() - now.getTime();
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  };

  const getTierIcon = () => {
    if (!subscriptionType) return <Crown className="h-5 w-5 text-yellow-500" />;
    
    switch (subscriptionType) {
      case 'gold':
        return <Crown className="h-5 w-5 text-yellow-500" />;
      case 'premium':
        return <Star className="h-5 w-5 text-purple-500" />;
      case 'pro':
        return <Shield className="h-5 w-5 text-blue-500" />;
      case 'regular':
        return <Zap className="h-5 w-5 text-green-500" />;
      default:
        return <Crown className="h-5 w-5 text-yellow-500" />;
    }
  };

  const getTierColor = () => {
    if (!subscriptionType) return 'text-yellow-500';
    
    switch (subscriptionType) {
      case 'gold':
        return 'text-yellow-500';
      case 'premium':
        return 'text-purple-500';
      case 'pro':
        return 'text-blue-500';
      case 'regular':
        return 'text-green-500';
      default:
        return 'text-gray-400';
    }
  };

  const getTierGradient = () => {
    if (!subscriptionType) return 'from-yellow-500/10 to-yellow-500/5';
    
    switch (subscriptionType) {
      case 'gold':
        return 'from-yellow-500/10 to-yellow-500/5';
      case 'premium':
        return 'from-purple-500/10 to-purple-500/5';
      case 'pro':
        return 'from-blue-500/10 to-blue-500/5';
      case 'regular':
        return 'from-green-500/10 to-green-500/5';
      default:
        return 'from-gray-800 to-gray-900';
    }
  };

  const formatTierName = (tier: string | null) => {
    if (!tier) return 'Get Gold';
    return tier.charAt(0).toUpperCase() + tier.slice(1);
  };

  if (isLoading) {
    return (
      <div className="flex items-center space-x-2 bg-gray-900 px-4 py-2 rounded-lg">
        <div className="animate-spin rounded-full h-4 w-4 border-2 border-yellow-500"></div>
        <span className="text-gray-400">Loading...</span>
      </div>
    );
  }

  if (subscriptionStatus !== 'active' || !subscriptionType) {
    return (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => navigate('/subscription')}
        className="flex items-center space-x-2 bg-gradient-to-r from-yellow-500/20 to-yellow-500/10 px-4 py-2 rounded-lg hover:from-yellow-500/30 hover:to-yellow-500/20 transition-all duration-300"
      >
        <Crown className="h-5 w-5 text-yellow-500" />
        <span className="text-yellow-500 font-medium">Get Gold</span>
      </motion.button>
    );
  }

  return (
    <div className="relative group">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`flex items-center space-x-2 bg-gradient-to-r ${getTierGradient()} px-4 py-2 rounded-lg transition-all duration-300`}
      >
        {getTierIcon()}
        <div>
          <p className={`font-medium ${getTierColor()}`}>
            {formatTierName(subscriptionType)} Member
          </p>
          {subscriptionEndDate && (
            <div className="flex items-center text-xs space-x-1">
              <Clock className="h-3 w-3 text-gray-400" />
              <span className="text-gray-400">
                {getDaysRemaining()} days remaining
              </span>
            </div>
          )}
        </div>
      </motion.button>

      {/* Hover Details */}
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="absolute right-0 mt-2 w-72 bg-gray-900 rounded-lg shadow-xl border border-gray-800 p-4 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50"
      >
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <span className="text-gray-400">Status</span>
            <span className={subscriptionStatus === 'active' ? 'text-green-500' : 'text-red-500'}>
              {subscriptionStatus.toUpperCase()}
            </span>
          </div>
          
          <div className="flex items-center justify-between">
            <span className="text-gray-400">Plan</span>
            <span className={getTierColor()}>
              {formatTierName(subscriptionType)}
            </span>
          </div>

          {subscriptionStartDate && (
            <div className="flex items-center justify-between">
              <span className="text-gray-400">Started</span>
              <span className="text-white">
                {new Date(subscriptionStartDate).toLocaleDateString()}
              </span>
            </div>
          )}

          {subscriptionEndDate && (
            <div className="flex items-center justify-between">
              <span className="text-gray-400">Expires</span>
              <span className="text-white">
                {new Date(subscriptionEndDate).toLocaleDateString()}
              </span>
            </div>
          )}

          {getDaysRemaining() <= 3 && subscriptionStatus === 'active' && (
            <div className="flex items-center space-x-2 text-yellow-500 text-sm mt-2 bg-yellow-500/10 p-2 rounded-lg">
              <AlertCircle className="h-4 w-4" />
              <span>Subscription expiring soon</span>
            </div>
          )}

          <button
            onClick={() => navigate('/subscription/status')}
            className={`w-full mt-2 text-sm ${getTierColor()} hover:underline text-center`}
          >
            View Subscription Details
          </button>
        </div>
      </motion.div>
    </div>
  );
};