import React from 'react';
import { Crown } from 'lucide-react';
import SubscriptionPlans from '../components/subscription/SubscriptionPlans';

const Subscription = () => {
  return (
    <div className="min-h-screen bg-black pt-20">
      <div className="container mx-auto px-4 py-12">
        <div className="text-center mb-16">
          <div className="inline-flex items-center space-x-2 bg-yellow-500/10 px-4 py-2 rounded-full text-yellow-500 mb-6">
            <Crown size={20} />
            <span>Premium Trading Features</span>
          </div>
          
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Upgrade Your Trading Experience
          </h1>
          
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Get access to advanced features, institutional insights, and premium
            support to take your trading to the next level.
          </p>
        </div>

        <SubscriptionPlans />
      </div>
    </div>
  );
};
export default Subscription;