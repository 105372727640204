import React, { createContext, useContext } from 'react';
import { ToastContainer } from '../components/ui/ToastContainer';
import { useToast } from '../hooks/useToast';

interface ToastContextType {
  showToast: (type: 'success' | 'error' | 'warning' | 'info', message: string) => void;
  clearAllToasts: () => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { toasts, addToast, removeToast, clearAllToasts } = useToast();

  return (
    <ToastContext.Provider value={{ showToast: addToast, clearAllToasts }}>
      {children}
      <ToastContainer toasts={toasts} onDismiss={removeToast} />
    </ToastContext.Provider>
  );
};

export const useToastContext = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }
  return context;
};

export default ToastProvider;