import React from 'react';
import { useNews } from '../hooks/useNews';
import NewsCard from '../components/news/NewsCard';
import { Newspaper, AlertTriangle } from 'lucide-react';
import { Helmet } from 'react-helmet';

const News = () => {
  const { news, loading, error } = useNews();

  return (
    <div className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black pt-20">
      <Helmet>
        <title>Financial Market News & Analysis | Forex, Stocks, Crypto & Gold | ChartTalks</title>
        <meta name="description" content="Stay updated with the latest financial market news affecting forex, stocks, cryptocurrencies, and gold. Get expert analysis and insights to inform your trading decisions." />
        <meta name="keywords" content="financial market news, forex news, stock market updates, cryptocurrency news, gold market analysis, trading news, market analysis, economic indicators" />
      </Helmet>
      
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="flex items-center space-x-2 mb-2">
          <Newspaper className="h-6 w-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500" />
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            Market News
          </h1>
        </div>

        {/* Description */}
        <p className="text-gray-400 mb-8">
          Stay updated with the latest market news and analysis to inform your trading decisions
        </p>

        {/* News Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {loading ? (
            <div className="col-span-full text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
              <p className="text-gray-400 mt-4">Loading news...</p>
            </div>
          ) : error ? (
            <div className="col-span-full text-center py-12">
              <AlertTriangle className="h-12 w-12 text-red-500 mx-auto" />
              <p className="text-red-400 mt-4">{error}</p>
            </div>
          ) : (
            news.map((item) => <NewsCard key={item.id} news={item} />)
          )}
        </div>
        
        {/* SEO Content */}
        <div className="mt-16 bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
          <h2 className="text-2xl font-bold text-white mb-4">
            Financial Market News & Analysis
          </h2>
          
          <div className="text-gray-300 space-y-4">
            <p>
              Our comprehensive financial market news coverage provides you with the latest updates, analysis, and insights across forex, stocks, cryptocurrencies, and gold markets. Stay informed about key economic indicators, central bank decisions, market trends, and geopolitical events that impact your trading decisions.
            </p>
            <p>
              Our team of expert analysts filters through the noise to deliver the most relevant and actionable news that directly affects market movements. From Federal Reserve policy announcements to corporate earnings reports, we cover all the essential information you need to stay ahead in the markets.
            </p>
            <p>
              Each news article includes expert analysis on potential market impact, helping you understand how specific events might affect your trading positions across different asset classes. Our sentiment analysis provides additional context on how the market is likely to react to breaking news.
            </p>
          </div>
          
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-900/50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-2">Forex Market News</h3>
              <p className="text-gray-300">
                Stay updated on central bank decisions, economic data releases, and geopolitical events that impact major, minor, and exotic currency pairs. Our forex news coverage helps you anticipate market movements and identify trading opportunities in the currency markets.
              </p>
            </div>
            
            <div className="bg-gray-900/50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-2">Stock Market Updates</h3>
              <p className="text-gray-300">
                Get the latest updates on corporate earnings, economic indicators, sector trends, and market sentiment affecting global stock markets. Our stock market news helps you stay informed about factors influencing equity prices and market indices.
              </p>
            </div>
            
            <div className="bg-gray-900/50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-2">Cryptocurrency News</h3>
              <p className="text-gray-300">
                Follow the latest developments in the crypto space, including regulatory updates, technological advancements, institutional adoption, and market trends. Our cryptocurrency news coverage helps you navigate the volatile digital asset markets.
              </p>
            </div>
            
            <div className="bg-gray-900/50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-2">Gold Market Analysis</h3>
              <p className="text-gray-300">
                Track gold price movements, central bank gold reserves, inflation data, and geopolitical tensions affecting the precious metals market. Our gold market analysis provides insights into factors driving XAU/USD price action.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;