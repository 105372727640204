import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeft } from 'lucide-react';
import { useToastContext } from '../../context/ToastContext';
import { predictionSchema } from '../../utils/validation';
import { ROUTES } from '../../utils/constants';
import axios from 'axios';

const CreatePrediction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(predictionSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/admin/predictions`, data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      
      showToast('success', 'Prediction created successfully');
      navigate(ROUTES.ADMIN.MANAGE_PREDICTIONS);
    } catch (error: any) {
      showToast('error', error.response?.data?.message || 'Failed to create prediction');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Admin Header */}
      <header className="bg-gray-900 border-b border-gray-800 px-6 py-4">
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigate(ROUTES.ADMIN.DASHBOARD)}
            className="flex items-center text-gray-400 hover:text-white"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Dashboard
          </button>
          <h1 className="text-2xl font-bold text-white">Create Prediction</h1>
          <div className="w-24"></div> {/* Spacer for alignment */}
        </div>
      </header>

      <div className="container mx-auto px-6 py-8">
        <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl mx-auto space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Symbol
            </label>
            <input
              {...register('symbol')}
              className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              placeholder="e.g., BTC/USD"
            />
            {errors.symbol && (
              <p className="mt-1 text-sm text-red-500">{errors.symbol.message as string}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Category
            </label>
            <select
              {...register('category')}
              className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            >
              <option value="forex">Forex</option>
              <option value="stocks">Stocks</option>
              <option value="commodities">Commodities</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Subscription Tier
            </label>
            <select
              {...register('subscriptionTier')}
              className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            >
              <option value="free">Free</option>
              <option value="regular">Regular</option>
              <option value="pro">Pro</option>
              <option value="premium">Premium</option>
              <option value="gold">Gold</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Type
            </label>
            <select
              {...register('type')}
              className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            >
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Entry Price
              </label>
              <input
                {...register('entryPrice', { valueAsNumber: true })}
                type="number"
                step="0.0001"
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Stop Loss
              </label>
              <input
                {...register('stopLoss', { valueAsNumber: true })}
                type="number"
                step="0.0001"
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Take Profit
              </label>
              <input
                {...register('takeProfit', { valueAsNumber: true })}
                type="number"
                step="0.0001"
                className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Confidence (%)
            </label>
            <input
              {...register('confidence', { valueAsNumber: true })}
              type="number"
              min="0"
              max="100"
              className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Analysis
            </label>
            <textarea
              {...register('analysis')}
              rows={4}
              className="w-full bg-gray-900 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              placeholder="Enter your market analysis..."
            />
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-yellow-500 text-black py-3 rounded-lg font-semibold hover:bg-yellow-400 transition-colors disabled:opacity-50"
          >
            {isLoading ? 'Creating...' : 'Create Prediction'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreatePrediction;