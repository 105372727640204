import React, { useState } from 'react';
import MarketSelector from '../components/predictions/MarketSelector';
import PredictionsList from '../components/predictions/PredictionsList';
import { usePredictions } from '../hooks/usePredictions';
import { LineChart, Info } from 'lucide-react';
import { MARKET_TYPES } from '../utils/constants';
import { useAuth } from '../context/AuthContext';

const Predictions = () => {
  const [selectedMarket, setSelectedMarket] = useState(MARKET_TYPES.STOCKS);
  const { predictions, loading, error } = usePredictions(selectedMarket);
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black pt-20">
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="flex items-center space-x-2 mb-2">
          <LineChart className="h-6 w-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500" />
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            Market Predictions
          </h1>
        </div>

        {/* Description */}
        <p className="text-gray-400 mb-8">
          Get real-time trading signals powered by advanced AI analysis
        </p>

        {/* Subscription Info */}
        <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg p-4 mb-6 flex items-center">
          <Info className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0" />
          <p className="text-sm text-gray-300">
            You're currently on the <span className={`font-semibold ${
              user?.subscriptionType === 'gold' ? 'text-yellow-400' :
              user?.subscriptionType === 'premium' ? 'text-purple-400' :
              user?.subscriptionType === 'pro' ? 'text-blue-400' :
              user?.subscriptionType === 'regular' ? 'text-green-400' :
              'text-gray-400'
            }`}>
              {user?.subscriptionType?.charAt(0).toUpperCase() + user?.subscriptionType?.slice(1) || 'Free'} Plan
            </span>. 
            {user?.subscriptionType !== 'gold' && ' Upgrade to access more premium predictions.'}
          </p>
        </div>

        {/* Market Selector */}
        <MarketSelector
          selectedMarket={selectedMarket}
          onMarketChange={setSelectedMarket}
        />

        {/* Predictions List */}
        <div className="mt-8">
          <PredictionsList 
            predictions={predictions} 
            loading={loading} 
            error={error} 
          />
        </div>
      </div>
    </div>
  );
};

export default Predictions;