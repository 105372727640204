import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../context/AuthContext";
import { useToastContext } from "../../context/ToastContext";
import { authApi } from "../../services/api";
import { Github, Circle, Loader2 } from "lucide-react"; // Import Lucide icons

export const SocialLoginButtons: React.FC = () => {
  const { login } = useAuth();
  const { showToast } = useToastContext();
  const [isLoading, setIsLoading] = React.useState({
    google: false,
    github: false,
  });

  const handleGoogleSuccess = async (tokenResponse: any) => {
    try {
      setIsLoading({ ...isLoading, google: true });
      const response = await authApi.googleLogin(tokenResponse.access_token);
      login(response.token, response.user);
      showToast("success", "Successfully logged in with Google!");
    } catch (error: any) {
      showToast("error", error.response?.data?.message || "Google login failed");
    } finally {
      setIsLoading({ ...isLoading, google: false });
    }
  };

  const handleGithubLogin = async () => {
    try {
      setIsLoading({ ...isLoading, github: true });
      window.location.href = `${import.meta.env.VITE_API_URL}/auth/github`;
    } catch (error: any) {
      showToast("error", "GitHub login failed");
      setIsLoading({ ...isLoading, github: false });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: () => showToast("error", "Google login failed"),
  });

  return (
    <div className="grid grid-cols-2 gap-4">
      <button
        onClick={() => googleLogin()}
        disabled={isLoading.google}
        className="flex items-center justify-center space-x-2 bg-white text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-100 transition-colors disabled:opacity-50"
      >
        {isLoading.google ? <Loader2 className="w-5 h-5 animate-spin" /> : <Circle className="w-5 h-5 text-blue-500" />}
        <span>{isLoading.google ? "Connecting..." : "Google"}</span>
      </button>

      <button
        onClick={handleGithubLogin}
        disabled={isLoading.github}
        className="flex items-center justify-center space-x-2 bg-gray-800 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition-colors disabled:opacity-50"
      >
        {isLoading.github ? <Loader2 className="w-5 h-5 animate-spin text-white" /> : <Github className="w-5 h-5 text-white" />}
        <span>{isLoading.github ? "Connecting..." : "GitHub"}</span>
      </button>
    </div>
  );
};
