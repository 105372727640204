import React from 'react';
import { Brain, LineChart, TrendingUp, Target } from 'lucide-react';
import StepCard from './StepCard';

const steps = [
  {
    icon: <Brain className="h-12 w-12" />,
    title: 'AI Analysis',
    description: 'Our advanced AI models analyze market patterns and institutional behavior',
  },
  {
    icon: <LineChart className="h-12 w-12" />,
    title: 'Pattern Recognition',
    description: 'Identify key market patterns and potential trading opportunities',
  },
  {
    icon: <TrendingUp className="h-12 w-12" />,
    title: 'Smart Money Tracking',
    description: 'Follow institutional trading patterns and market makers',
  },
  {
    icon: <Target className="h-12 w-12" />,
    title: 'Precise Predictions',
    description: 'Get accurate entry, stop-loss, and take-profit levels',
  },
];

const HowItWorks: React.FC = () => {
  return (
    <section className="relative py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black" id="how-it-works">
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            How Our{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              Predictions
            </span>{' '}
            Work
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Our AI-powered platform combines institutional trading concepts with advanced
            technology to provide accurate market predictions.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <StepCard
              key={index}
              {...step}
              isLast={index === steps.length - 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;