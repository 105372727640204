import React from 'react';
import PredictionCard from './PredictionCard';
import { PredictionData } from '../../types';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import GoldButton from '../subscription/GoldButton';

interface PredictionsListProps {
  predictions: PredictionData[];
  loading: boolean;
  error: string | null;
}

const PredictionsList: React.FC<PredictionsListProps> = ({ predictions, loading, error }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!predictions.length) {
    return (
      <div className="text-center py-12 bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-xl p-8 border border-blue-500/10">
        <h3 className="text-xl font-semibold text-white mb-4">No predictions available for this category</h3>
        <p className="text-gray-400 mb-6">We're constantly analyzing the markets and will add new predictions soon.</p>
        <button
          onClick={() => navigate('/subscription')}
          className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors"
        >
          Upgrade for More Predictions
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Subscription Upgrade Banner */}
      {user?.subscriptionType !== 'gold' && (
        <div className="bg-gradient-to-r from-yellow-500/20 to-yellow-500/10 rounded-xl p-6 mb-8 flex flex-col md:flex-row items-center justify-between">
          <div>
            <h3 className="text-xl font-semibold text-white mb-2">Unlock All Premium Predictions</h3>
            <p className="text-gray-300">
              Upgrade to our Gold plan to access all premium predictions and maximize your trading potential.
            </p>
          </div>
          <GoldButton className="mt-4 md:mt-0" />
        </div>
      )}

      {/* Predictions Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {predictions.map((prediction) => (
          <PredictionCard key={prediction.id} prediction={prediction} />
        ))}
      </div>
    </div>
  );
};

export default PredictionsList;