import React from 'react';
import { motion } from 'framer-motion';
import { Users, TrendingUp, Globe, Award } from 'lucide-react';

const stats = [
  {
    icon: <Users className="w-8 h-8" />,
    value: '10,000+',
    label: 'Active Traders',
    description: 'Trusted by traders worldwide',
  },
  {
    icon: <TrendingUp className="w-8 h-8" />,
    value: '87%',
    label: 'Success Rate',
    description: 'Accurate trading predictions',
  },
  {
    icon: <Globe className="w-8 h-8" />,
    value: '24/7',
    label: 'Market Coverage',
    description: 'Real-time market analysis',
  },
  {
    icon: <Award className="w-8 h-8" />,
    value: '5★',
    label: 'User Rating',
    description: 'Highest rated trading platform',
  },
];

const StatsSection = () => {
  return (
    <section className="py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black relative">
      {/* Animated background gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-blue-500/5 via-transparent to-transparent" />

      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-xl p-8 text-center border border-blue-500/10"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <motion.div
                className="inline-block mb-4"
                animate={{ rotate: [0, 360] }}
                transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
              >
                <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full text-white">
                  {stat.icon}
                </div>
              </motion.div>
              <motion.h3
                className="text-3xl font-bold text-white mb-2"
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.2 + 0.3 }}
              >
                {stat.value}
              </motion.h3>
              <h4 className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 font-semibold mb-2">
                {stat.label}
              </h4>
              <p className="text-gray-400 text-sm">{stat.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;