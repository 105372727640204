import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email('Invalid email address'),
  requestType: z.enum(['export', 'delete']),
  reason: z.string().min(10, 'Please provide more details'),
});

type FormData = z.infer<typeof schema>;

const DataRequestForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      // Implement form submission
      console.log('Form data:', data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="bg-gray-900 rounded-xl p-6">
      <h2 className="text-2xl font-semibold text-white mb-6">
        Data Request Form
      </h2>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Email Address
          </label>
          <input
            {...register('email')}
            type="email"
            className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Request Type
          </label>
          <select
            {...register('requestType')}
            className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          >
            <option value="export">Export My Data</option>
            <option value="delete">Delete My Data</option>
          </select>
          {errors.requestType && (
            <p className="mt-1 text-sm text-red-500">{errors.requestType.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Reason for Request
          </label>
          <textarea
            {...register('reason')}
            rows={4}
            className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          />
          {errors.reason && (
            <p className="mt-1 text-sm text-red-500">{errors.reason.message}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold hover:bg-yellow-400 transition-colors disabled:opacity-50"
        >
          {isSubmitting ? 'Submitting...' : 'Submit Request'}
        </button>
      </form>
    </div>
  );
};

export default DataRequestForm;