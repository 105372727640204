import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Network, Cpu, Zap } from 'lucide-react';

const TradingInnovation = () => {
  return (
    <section className="relative py-24 overflow-hidden">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-blue-900/20 to-black" />
      
      {/* Animated Lines */}
      <div className="absolute inset-0">
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent"
            style={{
              top: `${20 * i}%`,
              left: 0,
              right: 0,
            }}
            animate={{
              x: ['-100%', '100%'],
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 8,
              delay: i * 0.5,
              repeat: Infinity,
              ease: 'linear',
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-8">
              Trading Innovation with{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
                Advanced AI
              </span>
            </h2>

            <div className="space-y-6">
              {[
                {
                  icon: <Brain className="h-6 w-6" />,
                  title: 'Neural Networks',
                  description: 'Deep learning models analyze market patterns and institutional behavior.',
                },
                {
                  icon: <Network className="h-6 w-6" />,
                  title: 'Pattern Recognition',
                  description: 'Advanced algorithms identify high-probability trading setups.',
                },
                {
                  icon: <Cpu className="h-6 w-6" />,
                  title: 'Real-time Processing',
                  description: 'Lightning-fast analysis of market data and price action.',
                },
                {
                  icon: <Zap className="h-6 w-6" />,
                  title: 'Smart Execution',
                  description: 'Optimal entry and exit points based on institutional analysis.',
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="flex items-start space-x-4 bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-4 rounded-xl"
                >
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg text-white">
                    {item.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{item.title}</h3>
                    <p className="text-gray-400">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* SVG Animation */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="relative"
          >
            <div className="aspect-square bg-gradient-to-br from-blue-900/20 via-purple-900/10 to-black rounded-3xl p-8 relative overflow-hidden">
              {/* Neural Network Animation */}
              <svg
                viewBox="0 0 200 200"
                className="w-full h-full"
                style={{ filter: 'drop-shadow(0 0 20px rgba(59, 130, 246, 0.5))' }}
              >
                <motion.g
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }}
                >
                  {/* Neural Network Nodes */}
                  {[...Array(20)].map((_, i) => (
                    <motion.circle
                      key={i}
                      cx={100 + Math.cos(i * Math.PI / 10) * 60}
                      cy={100 + Math.sin(i * Math.PI / 10) * 60}
                      r="4"
                      fill="#3B82F6"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: [0, 1, 0] }}
                      transition={{
                        duration: 2,
                        delay: i * 0.1,
                        repeat: Infinity,
                      }}
                    />
                  ))}
                  
                  {/* Connection Lines */}
                  {[...Array(40)].map((_, i) => (
                    <motion.line
                      key={`line-${i}`}
                      x1={100 + Math.cos(i * Math.PI / 20) * 60}
                      y1={100 + Math.sin(i * Math.PI / 20) * 60}
                      x2={100 + Math.cos((i + 1) * Math.PI / 20) * 60}
                      y2={100 + Math.sin((i + 1) * Math.PI / 20) * 60}
                      stroke="#3B82F6"
                      strokeWidth="0.5"
                      strokeOpacity="0.3"
                      initial={{ pathLength: 0 }}
                      animate={{ pathLength: 1 }}
                      transition={{
                        duration: 2,
                        delay: i * 0.05,
                        repeat: Infinity,
                      }}
                    />
                  ))}
                </motion.g>
              </svg>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default TradingInnovation;