import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  orderId: z.string().min(1, 'Order ID is required'),
  email: z.string().email('Invalid email address'),
  reason: z.string().min(10, 'Please provide more details about your refund request'),
  bankDetails: z.string().min(1, 'Bank details are required for refund processing'),
});

type FormData = z.infer<typeof schema>;

const RefundForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      // Implement form submission
      console.log('Form data:', data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Order ID
        </label>
        <input
          {...register('orderId')}
          type="text"
          className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          placeholder="Enter your order ID"
        />
        {errors.orderId && (
          <p className="mt-1 text-sm text-red-500">{errors.orderId.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Email Address
        </label>
        <input
          {...register('email')}
          type="email"
          className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          placeholder="Enter your email"
        />
        {errors.email && (
          <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Reason for Refund
        </label>
        <textarea
          {...register('reason')}
          rows={4}
          className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          placeholder="Please explain why you're requesting a refund"
        />
        {errors.reason && (
          <p className="mt-1 text-sm text-red-500">{errors.reason.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          Bank Details
        </label>
        <textarea
          {...register('bankDetails')}
          rows={3}
          className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          placeholder="Enter your bank details for refund processing"
        />
        {errors.bankDetails && (
          <p className="mt-1 text-sm text-red-500">{errors.bankDetails.message}</p>
        )}
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold hover:bg-yellow-400 transition-colors disabled:opacity-50"
      >
        {isSubmitting ? 'Submitting...' : 'Submit Refund Request'}
      </button>
    </form>
  );
};

export default RefundForm;