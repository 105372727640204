import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail, Lock, Eye, EyeOff } from 'lucide-react';
import { motion } from 'framer-motion';
import { loginSchema } from '../../utils/validation';
import { LoginFormData } from '../../types/auth';

interface LoginFormProps {
  onSubmit: (data: LoginFormData) => Promise<void>;
  onForgotPassword: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, onForgotPassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmitForm = async (data: LoginFormData) => {
    try {
      setIsLoading(true);
      setLoginError(null);
      await onSubmit(data);
    } catch (error: any) {
      console.error('Login form error:', error);
      setLoginError(error.response?.data?.message || 'Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const inputVariants = {
    focus: { scale: 1.02, boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.5)" },
    blur: { scale: 1, boxShadow: "none" },
  };

  const buttonVariants = {
    hover: { scale: 1.03, boxShadow: "0 0 15px rgba(59, 130, 246, 0.5)" },
    tap: { scale: 0.97 },
    disabled: { opacity: 0.7 }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-5">
      {/* Email Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1.5">
          Email
        </label>
        <motion.div 
          className="relative"
          initial="blur"
          whileFocus="focus"
          animate="blur"
          variants={inputVariants}
        >
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('email')}
            type="email"
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-3 px-10 text-black focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10 transition-all"
            placeholder="Enter your email"
          />
        </motion.div>
        {errors.email && (
          <motion.p 
            className="mt-1.5 text-sm text-red-500"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            {errors.email.message}
          </motion.p>
        )}
      </div>

      {/* Password Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1.5">
          Password
        </label>
        <motion.div 
          className="relative"
          initial="blur"
          whileFocus="focus"
          animate="blur"
          variants={inputVariants}
        >
          <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('password')}
            type={showPassword ? 'text' : 'password'}
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-3 px-10 text-white focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10 transition-all"
            placeholder="Enter your password"
          />
          <motion.button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white p-1 rounded-full"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </motion.button>
        </motion.div>
        {errors.password && (
          <motion.p 
            className="mt-1.5 text-sm text-red-500"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            {errors.password.message}
          </motion.p>
        )}
      </div>

      {/* Login Error Message */}
      {loginError && (
        <motion.div 
          className="bg-red-500/10 border border-red-500/30 rounded-lg p-3"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <p className="text-sm text-red-500">{loginError}</p>
        </motion.div>
      )}

      {/* Forgot Password Link */}
      <div className="flex justify-end">
        <motion.button
          type="button"
          onClick={onForgotPassword}
          className="text-sm text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Forgot password?
        </motion.button>
      </div>

      {/* Submit Button */}
      <motion.button
        type="submit"
        disabled={isLoading}
        className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        animate={isLoading ? "disabled" : ""}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Signing in...
          </span>
        ) : (
          'Sign In'
        )}
      </motion.button>
    </form>
  );
};

export default LoginForm;