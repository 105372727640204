import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Edit, Trash2, Search, Filter, AlertCircle, Check, X } from 'lucide-react';
import { useToastContext } from '../../context/ToastContext';
import { predictionsService } from '../../services/predictions.service';
import { PredictionData } from '../../types';
import { ROUTES } from '../../utils/constants';

const ManagePredictions = () => {
  const [predictions, setPredictions] = useState<PredictionData[]>([]);
  const [filteredPredictions, setFilteredPredictions] = useState<PredictionData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [tierFilter, setTierFilter] = useState('all');
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  // Fetch predictions
  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const allPredictions = await predictionsService.getAllPredictions();
        
        // Log predictions to help debug
        console.log('Fetched predictions:', allPredictions);
        
        setPredictions(allPredictions);
        setFilteredPredictions(allPredictions);
      } catch (err: any) {
        console.error('Error fetching predictions:', err);
        setError(err.response?.data?.message || 'Failed to fetch predictions');
        showToast('error', 'Failed to fetch predictions');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPredictions();
  }, [showToast]);

  // Filter predictions based on search term and filters
  useEffect(() => {
    let result = [...predictions];
    
    // Apply search filter
    if (searchTerm) {
      result = result.filter(
        (prediction) =>
          prediction.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
          prediction.analysis.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Apply category filter
    if (categoryFilter !== 'all') {
      result = result.filter((prediction) => prediction.category === categoryFilter);
    }
    
    // Apply tier filter
    if (tierFilter !== 'all') {
      result = result.filter((prediction) => prediction.subscriptionTier === tierFilter);
    }
    
    setFilteredPredictions(result);
  }, [searchTerm, categoryFilter, tierFilter, predictions]);

  const handleEdit = (id: string) => {
    if (!id) {
      showToast('error', 'Cannot edit: Missing prediction ID');
      return;
    }
    console.log('Editing prediction with ID:', id);
    navigate(`/admin/edit-prediction/${id}`);
  };

  const handleDeleteConfirm = (id: string) => {
    if (!id) {
      showToast('error', 'Cannot delete: Missing prediction ID');
      return;
    }
    setDeleteConfirmation(id);
  };

  const handleDelete = async (id: string) => {
    if (!id) {
      showToast('error', 'Cannot delete: Missing prediction ID');
      setDeleteConfirmation(null);
      return;
    }
    
    try {
      console.log('Deleting prediction with ID:', id);
      await predictionsService.deletePrediction(id);
      setPredictions(predictions.filter(prediction => prediction.id !== id));
      showToast('success', 'Prediction deleted successfully');
    } catch (err: any) {
      console.error('Error deleting prediction:', err);
      showToast('error', err.response?.data?.message || 'Failed to delete prediction');
    } finally {
      setDeleteConfirmation(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation(null);
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Admin Header */}
      <header className="bg-gray-900 border-b border-gray-800 px-6 py-4">
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigate(ROUTES.ADMIN.DASHBOARD)}
            className="flex items-center text-gray-400 hover:text-white"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Dashboard
          </button>
          <h1 className="text-2xl font-bold text-white">Manage Predictions</h1>
          <button
            onClick={() => navigate(ROUTES.ADMIN.CREATE_PREDICTION)}
            className="bg-yellow-500 text-black px-4 py-2 rounded-lg font-semibold hover:bg-yellow-400 transition-colors"
          >
            Create New
          </button>
        </div>
      </header>

      <div className="container mx-auto px-6 py-8">
        <div className="bg-gray-900 rounded-xl p-6">
          {/* Search and Filters */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-6">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search predictions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-gray-800 rounded-lg py-2 pl-10 pr-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              />
            </div>
            
            <div className="flex space-x-4 w-full md:w-auto">
              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                className="bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                <option value="all">All Categories</option>
                <option value="forex">Forex</option>
                <option value="stocks">Stocks</option>
                <option value="commodities">Commodities</option>
              </select>
              
              <select
                value={tierFilter}
                onChange={(e) => setTierFilter(e.target.value)}
                className="bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                <option value="all">All Tiers</option>
                <option value="free">Free</option>
                <option value="regular">Regular</option>
                <option value="pro">Pro</option>
                <option value="premium">Premium</option>
                <option value="gold">Gold</option>
              </select>
            </div>
          </div>

          {/* Error State */}
          {error && (
            <div className="bg-red-500/10 border border-red-500 rounded-lg p-4 mb-6 flex items-center">
              <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
              <p className="text-red-500">{error}</p>
            </div>
          )}

          {/* Loading State */}
          {isLoading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500 mx-auto"></div>
              <p className="text-gray-400 mt-4">Loading predictions...</p>
            </div>
          ) : (
            <>
              {/* Results Count */}
              <div className="mb-4 text-gray-400">
                Showing {filteredPredictions.length} of {predictions.length} predictions
              </div>
              
              {/* Predictions Table */}
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="text-left text-gray-400 border-b border-gray-800">
                      <th className="pb-4 pl-4">Symbol</th>
                      <th className="pb-4">Category</th>
                      <th className="pb-4">Tier</th>
                      <th className="pb-4">Type</th>
                      <th className="pb-4">Entry Price</th>
                      <th className="pb-4">Created</th>
                      <th className="pb-4">Status</th>
                      <th className="pb-4 text-right pr-4">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-white">
                    {filteredPredictions.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="text-center py-8 text-gray-400">
                          No predictions found
                        </td>
                      </tr>
                    ) : (
                      filteredPredictions.map((prediction) => {
                        // Use either id or _id, ensuring we have a valid identifier
                        const predictionId = prediction.id || prediction._id;
                        
                        if (!predictionId) {
                          console.error('Prediction missing ID:', prediction);
                          return null; // Skip rendering this prediction
                        }
                        
                        return (
                          <tr 
                            key={predictionId} 
                            className="border-b border-gray-800 hover:bg-gray-800/50 transition-colors"
                          >
                            <td className="py-4 pl-4 font-medium">{prediction.symbol}</td>
                            <td className="py-4 capitalize">{prediction.category}</td>
                            <td className="py-4">
                              <span className={`px-2 py-1 rounded-full text-xs ${
                                prediction.subscriptionTier === 'free' ? 'bg-gray-700 text-gray-300' :
                                prediction.subscriptionTier === 'regular' ? 'bg-green-500/20 text-green-400' :
                                prediction.subscriptionTier === 'pro' ? 'bg-blue-500/20 text-blue-400' :
                                prediction.subscriptionTier === 'premium' ? 'bg-purple-500/20 text-purple-400' :
                                'bg-yellow-500/20 text-yellow-400'
                              }`}>
                                {prediction.subscriptionTier.charAt(0).toUpperCase() + prediction.subscriptionTier.slice(1)}
                              </span>
                            </td>
                            <td className="py-4">
                              <span className={`px-2 py-1 rounded-full text-xs ${
                                prediction.type === 'buy' ? 'bg-green-500/20 text-green-400' : 'bg-red-500/20 text-red-400'
                              }`}>
                                {prediction.type.toUpperCase()}
                              </span>
                            </td>
                            <td className="py-4">${prediction.entryPrice.toFixed(2)}</td>
                            <td className="py-4">{new Date(prediction.createdAt).toLocaleDateString()}</td>
                            <td className="py-4">
                              <span className={`px-2 py-1 rounded-full text-xs ${
                                prediction.status === 'active' ? 'bg-green-500/20 text-green-400' :
                                prediction.status === 'completed' ? 'bg-blue-500/20 text-blue-400' :
                                'bg-red-500/20 text-red-400'
                              }`}>
                                {prediction.status.toUpperCase()}
                              </span>
                            </td>
                            <td className="py-4 text-right pr-4">
                              {deleteConfirmation === predictionId ? (
                                <div className="flex items-center justify-end space-x-2">
                                  <span className="text-sm text-gray-400 mr-2">Confirm delete?</span>
                                  <button
                                    onClick={() => handleDelete(predictionId)}
                                    className="p-1 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30"
                                  >
                                    <Check size={18} />
                                  </button>
                                  <button
                                    onClick={handleCancelDelete}
                                    className="p-1 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600"
                                  >
                                    <X size={18} />
                                  </button>
                                </div>
                              ) : (
                                <div className="flex items-center justify-end space-x-2">
                                  <button
                                    onClick={() => handleEdit(predictionId)}
                                    className="p-2 bg-blue-500/20 text-blue-400 rounded-lg hover:bg-blue-500/30"
                                    title="Edit prediction"
                                  >
                                    <Edit size={16} />
                                  </button>
                                  <button
                                    onClick={() => handleDeleteConfirm(predictionId)}
                                    className="p-2 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30"
                                    title="Delete prediction"
                                  >
                                    <Trash2 size={16} />
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePredictions;