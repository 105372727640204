import React from 'react';
import { Link } from 'react-router-dom';
import { Twitter, Facebook, Linkedin, Instagram, Mail, Phone, MapPin, ArrowRight } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-gradient-to-t from-black to-gray-900 text-gray-400">
      {/* Main Footer */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <Link to="/" className="flex items-center space-x-2 mb-6">
              <img
                src="/charttalks.svg"
                alt="Icon"
                className="w-10 h-10"
              />
              <img
                src="/mylogo.webp"
                alt="ChartTalks Logo"
                className="w-32 h-auto"
              />
            </Link>
            <p className="text-gray-400 mb-6">
              Advanced AI-powered trading predictions and market analysis platform. 
              Leverage institutional insights to make data-driven trading decisions for forex, stocks, cryptocurrencies, and gold markets.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
                <Twitter size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
                <Facebook size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
                <Linkedin size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
                <Instagram size={20} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-6 text-lg">Trading Resources</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/predictions/forex" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Forex Trading Predictions
                </Link>
              </li>
              <li>
                <Link to="/predictions/stocks" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Stock Market Analysis
                </Link>
              </li>
              <li>
                <Link to="/predictions/crypto" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Cryptocurrency Signals
                </Link>
              </li>
              <li>
                <Link to="/predictions/gold" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Gold Trading Forecast
                </Link>
              </li>
              <li>
                <Link to="/news" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Financial News
                </Link>
              </li>
              <li>
                <Link to="/subscription" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Subscription Plans
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-white font-semibold mb-6 text-lg">Company</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/refund" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="/sitemap.xml" className="text-gray-400 hover:text-blue-500 transition-colors flex items-center">
                  <ArrowRight className="h-4 w-4 mr-2" />
                  Sitemap
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-white font-semibold mb-6 text-lg">Contact Us</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <MapPin className="h-5 w-5 text-blue-500 mr-3 mt-1 flex-shrink-0" />
                <span>Stock Exchange Building, Market Street, New Delhi, INDIA</span>
              </li>
              <li className="flex items-center">
                <Phone className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0" />
                <span>+91 07660 34568</span>
              </li>
              <li className="flex items-center">
                <Mail className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0" />
                <span>support@charttalks.com</span>
              </li>
            </ul>
            
            {/* Newsletter Signup */}
            <div className="mt-6">
              <h4 className="text-white font-medium mb-3">Subscribe to our newsletter</h4>
              <div className="flex">
                <input 
                  type="email" 
                  placeholder="Your email" 
                  className="bg-gray-800 text-white px-4 py-2 rounded-l-lg focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
                  aria-label="Email for newsletter"
                />
                <button className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 transition-colors">
                  <ArrowRight size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Bottom Footer */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p>© {currentYear} ChartTalks. All rights reserved.</p>
            <div className="mt-4 md:mt-0">
              <p className="text-sm">
                Trading involves risk. Only risk capital you're prepared to lose.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;