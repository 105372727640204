import React from 'react';
import { ArrowUpCircle, ArrowDownCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const mockPredictions = [
  {
    id: 'pred-1',
    symbol: 'BTC/USD',
    type: 'buy',
    entry: 42500,
    stopLoss: 42000,
    takeProfit: 43500,
    accuracy: 87,
    timestamp: new Date().toISOString(),
  },
  {
    id: 'pred-2',
    symbol: 'ETH/USD',
    type: 'sell',
    entry: 2250,
    stopLoss: 2300,
    takeProfit: 2150,
    accuracy: 82,
    timestamp: new Date().toISOString(),
  },
];

const RecentPredictions = () => {
  return (
    <motion.section
      className="py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black"
      initial={{ opacity: 0, x: -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8 }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Recent{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            Predictions
          </span>
        </h2>
        <div className="flex space-x-6 overflow-x-auto pb-6">
          {mockPredictions.map((prediction, index) => (
            <motion.div
              key={prediction.id}
              className="flex-none w-80 bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-xl p-6 border border-blue-500/10"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-bold text-white">{prediction.symbol}</h3>
                  <p className="text-gray-400 text-sm">
                    {new Date(prediction.timestamp).toLocaleString()}
                  </p>
                </div>
                {prediction.type === 'buy' ? (
                  <ArrowUpCircle className="text-green-500 h-6 w-6" />
                ) : (
                  <ArrowDownCircle className="text-red-500 h-6 w-6" />
                )}
              </div>

              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-400">Entry</span>
                  <span className="text-white font-medium">${prediction.entry}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-400">Stop Loss</span>
                  <span className="text-red-500 font-medium">${prediction.stopLoss}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-400">Take Profit</span>
                  <span className="text-green-500 font-medium">${prediction.takeProfit}</span>
                </div>
              </div>

              <div className="mt-4 pt-4 border-t border-gray-800">
                <div className="flex justify-between items-center">
                  <span className="text-gray-400">Accuracy</span>
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 font-bold">
                    {prediction.accuracy}%
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default RecentPredictions;