import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import { SocialLoginButtons } from './SocialLoginButtons';
import ForgotPasswordForm from './ForgotPasswordForm';
import AuthAnimation from './AuthAnimation';
import { useAuthHandlers } from '../../hooks/useAuth';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type AuthView = 'login' | 'signup' | 'forgotPassword';

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const [view, setView] = useState<AuthView>('login');
  const [mounted, setMounted] = useState(false);
  const { handleLogin, handleSignup, handleForgotPassword } = useAuthHandlers(onClose);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      const timer = setTimeout(() => setMounted(false), 200);
      return () => clearTimeout(timer);
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleForgotPasswordSubmit = async (email: string) => {
    await handleForgotPassword(email);
    setView('login');
  };

  if (!isOpen && !mounted) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        mounted ? 'opacity-100' : 'opacity-0'
      } transition-opacity duration-300`}
    >
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black/70 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Container */}
      <div className="relative w-full max-w-4xl mx-4 bg-gradient-to-br from-gray-900 to-black rounded-2xl shadow-2xl transform scale-100 p-8 my-auto max-h-[90vh] overflow-auto">
        {/* Rainbow Border */}
        <div className="absolute inset-0 rounded-2xl overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute top-0 right-0 w-[3px] h-[30%] bg-gradient-to-b from-red-500 via-orange-500 to-yellow-500 animate-border-top" />
            <div className="absolute top-0 right-0 w-[30%] h-[3px] bg-gradient-to-l from-yellow-500 via-green-500 to-blue-500 animate-border-right" />
            <div className="absolute bottom-0 left-0 w-[3px] h-[30%] bg-gradient-to-t from-blue-500 via-indigo-500 to-purple-500 animate-border-bottom" />
            <div className="absolute bottom-0 left-0 w-[30%] h-[3px] bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 animate-border-left" />
          </div>
        </div>

        {/* Modal Content */}
        <div className="relative bg-gradient-to-br from-gray-900 to-black rounded-2xl shadow-2xl p-8">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 z-10 text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>

          <div className="flex flex-col md:flex-row gap-8">
            {/* Left side - Animation */}
            <div className="hidden md:flex w-full md:w-1/2 bg-gradient-to-br from-yellow-500/10 via-purple-500/10 to-blue-500/10">
              <AuthAnimation view={view} />
            </div>

            {/* Right side - Forms */}
            <div className="w-full md:w-1/2">
              {view === 'login' && (
                <>
                  <h2 className="text-2xl font-bold text-white mb-6">Welcome Back</h2>
                  <LoginForm onSubmit={handleLogin} onForgotPassword={() => setView('forgotPassword')} />
                  <div className="relative my-6">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-700" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-gray-900 text-gray-400">
                        Or continue with
                      </span>
                    </div>
                  </div>
                  <SocialLoginButtons />
                  <p className="text-center text-gray-400 mt-6">
                    Don't have an account?{' '}
                    <button
                      onClick={() => setView('signup')}
                      className="text-yellow-500 hover:text-yellow-400"
                    >
                      Sign up
                    </button>
                  </p>
                </>
              )}

              {view === 'signup' && (
                <>
                  <h2 className="text-2xl font-bold text-white mb-1">Create Account</h2>
                  <SignupForm onSubmit={handleSignup} />
                  <p className="text-center text-gray-400 mt-6">
                    Already have an account?{' '}
                    <button
                      onClick={() => setView('login')}
                      className="text-yellow-500 hover:text-yellow-400"
                    >
                      Sign in
                    </button>
                  </p>
                </>
              )}

{view === 'forgotPassword' && (
  <>
    <h2 className="text-xl font-bold text-white mb-1">Reset Password</h2>
    <ForgotPasswordForm onSubmit={handleForgotPasswordSubmit} />
    <p className="text-center text-gray-400 mt-4 text-sm">
      Remember your password?{' '}
      <button
        onClick={() => setView('login')}
        className="text-yellow-500 hover:text-yellow-400"
      >
        Sign in
      </button>
    </p>
  </>
)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;