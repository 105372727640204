export const APP_NAME = 'ChartTalks';
export const APP_DESCRIPTION = 'Advanced market predictions powered by Smart Money Concepts and Institutional Trading Concepts.';

export const ROUTES = {
  HOME: '/',
  PREDICTIONS: '/predictions',
  NEWS: '/news',
  ABOUT: '/about',
  CONTACT: '/contact',
  ADMIN: {
    DASHBOARD: '/admin',
    CREATE_PREDICTION: '/admin/create-prediction',
    MANAGE_PREDICTIONS: '/admin/manage-predictions',
    EDIT_PREDICTION: '/admin/edit-prediction/:id',
    SETTINGS: '/admin/settings'
  },
} as const;

export const MARKET_TYPES = {
  STOCKS: 'stocks',
  FOREX: 'forex',
  COMMODITIES: 'commodities',
} as const;

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
} as const;

export const STORAGE_KEYS = {
  TOKEN: 'auth_token',
  USER: 'auth_user',
} as const;

export const REDIRECT_PATHS = {
  DEFAULT: '/',
  AFTER_LOGIN: '/predictions',
  AFTER_LOGOUT: '/',
  ADMIN: '/admin',
} as const;