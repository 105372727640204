import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import LineChart from '../ui/charts/LineChart';
import CandlestickChart from '../ui/charts/CandlestickChart';
import AreaChart from '../ui/charts/AreaChart';
import BarChart from '../ui/charts/BarChart';

const MarketDashboard: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <section className="py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            Real-Time Market{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              Predictions
            </span>
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            AI-powered analysis across forex, stocks, crypto, and gold markets with institutional-grade accuracy
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <div className="col-span-full">
            <h3 className="text-xl font-semibold text-white mb-4">Forex Markets</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <LineChart 
                  symbol="EUR/USD" 
                  initialValue={1.0876} 
                  volatility={0.0005} 
                  type="forex"
                  trendBias={0.55}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <CandlestickChart 
                  symbol="GBP/JPY" 
                  initialValue={186.42} 
                  volatility={0.02} 
                  type="forex"
                  trendBias={0.45}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <AreaChart 
                  symbol="GBP/USD" 
                  initialValue={1.2654} 
                  volatility={0.0008} 
                  type="forex"
                  trendBias={0.6}
                  gradientColors={{
                    start: '#3B82F6',
                    end: '#1E3A8A'
                  }}
                />
              </motion.div>
            </div>
          </div>

          <div className="col-span-full">
            <h3 className="text-xl font-semibold text-white mb-4">Stock Markets</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <BarChart 
                  symbol="NIFTY 50" 
                  initialValue={22450} 
                  volatility={15} 
                  type="stock"
                  trendBias={0.6}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <LineChart 
                  symbol="BANKNIFTY" 
                  initialValue={48250} 
                  volatility={25} 
                  type="stock"
                  trendBias={0.55}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <CandlestickChart 
                  symbol="NASDAQ" 
                  initialValue={17680} 
                  volatility={12} 
                  type="stock"
                  trendBias={0.65}
                />
              </motion.div>
            </div>
          </div>

          <div className="col-span-full">
            <h3 className="text-xl font-semibold text-white mb-4">Crypto & Commodities</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <AreaChart 
                  symbol="BTC/USD" 
                  initialValue={84220} 
                  volatility={150} 
                  type="crypto"
                  trendBias={0.6}
                  gradientColors={{
                    start: '#F7931A',
                    end: '#D4A017'
                  }}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                className="h-[250px]"
              >
                <CandlestickChart 
                  symbol="GOLD" 
                  initialValue={2324.50} 
                  volatility={3} 
                  type="commodity"
                  trendBias={0.7}
                />
              </motion.div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-full font-semibold hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
            onClick={() => navigate('/predictions')} // Add navigation to /prediction
          >
            View All Market Predictions
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default MarketDashboard;