import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { ArrowRight, TrendingUp } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AnimatedPredictionGraph from '../ui/AnimatedPredictionGraph';

const HeroSection = () => {
  const { isAuthenticated, setShowAuthModal } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleGetStarted = () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      
    }
  };

  const handleViewLivePredictions = () => {
    navigate('/predictions'); // Navigate to /predictions
  };
  


  return (
    <motion.div
      className="relative min-h-screen flex items-center justify-center overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Gradient Background */}
        <div className="absolute inset-0 bg-gradient-to-br from-black via-blue-900/10 to-black" />
        
        {/* Animated Particles */}
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={`particle-${i}`}
            className="absolute w-1 h-1 rounded-full bg-blue-500/30"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, Math.random() * 30 - 15],
              opacity: [0, 0.7, 0],
              scale: [0, 1, 0],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              ease: "easeInOut",
              delay: Math.random() * 5,
            }}
          />
        ))}
        
        {/* Animated Grid Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(10)].map((_, i) => (
            <motion.div
              key={`h-line-${i}`}
              className="absolute left-0 right-0 h-px bg-blue-500/30"
              style={{ top: `${i * 10}%` }}
              animate={{
                opacity: [0.3, 0.6, 0.3],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                delay: i * 0.2,
              }}
            />
          ))}
          {[...Array(10)].map((_, i) => (
            <motion.div
              key={`v-line-${i}`}
              className="absolute top-0 bottom-0 w-px bg-blue-500/30"
              style={{ left: `${i * 10}%` }}
              animate={{
                opacity: [0.3, 0.6, 0.3],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                delay: i * 0.2,
              }}
            />
          ))}
        </div>
      </div>

      {/* Content Container */}
      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <div className="max-w-3xl">
            {/* Tagline */}
            <motion.div
              className="inline-flex items-center space-x-2 bg-gradient-to-r from-blue-500/10 to-purple-500/10 px-4 py-2 rounded-full text-white mb-6 border border-blue-500/10"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              <TrendingUp size={20} className="shrink-0" />
              <span className="text-sm font-medium">AI-Powered Trading Predictions</span>
            </motion.div>

            {/* Main Heading */}
            <motion.h1
              className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-6 leading-tight font-poppins"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 1 }}
            >
              Expert Trading Signals for <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
                Forex, Stocks, Crypto & Gold
              </span>
            </motion.h1>

            {/* Description */}
            <motion.p
              className="text-xl text-gray-300 mb-8 font-inter"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              Leverage institutional-grade analysis powered by advanced AI to make informed trading decisions across global markets. Get precise entry points, stop-loss levels, and take-profit targets with 87% accuracy.
            </motion.p>

            {/* Buttons */}
            <motion.div
              className="flex flex-col sm:flex-row gap-4 justify-center sm:justify-start"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.2, duration: 1 }}
            >
              <motion.button
                onClick={handleGetStarted}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-4 rounded-full font-semibold hover:from-blue-600 hover:to-purple-600 transition-all duration-300 flex items-center justify-center group"
                whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(59, 130, 246, 0.5)" }}
                whileTap={{ scale: 0.95 }}
              >
                Start Trading Now
                <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </motion.button>

              <motion.button 
                onClick={handleViewLivePredictions} // Add onClick handler
                className="border border-blue-500 text-blue-500 px-8 py-4 rounded-full font-semibold hover:bg-blue-500/10 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                View Live Predictions
              </motion.button>
            </motion.div>
            
            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 flex flex-wrap gap-6 justify-center sm:justify-start"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.5, duration: 1 }}
            >
              <motion.div 
                className="flex items-center space-x-2"
                whileHover={{ scale: 1.05 }}
              >
                <div className="bg-green-500/20 text-green-500 px-3 py-1 rounded-full text-sm font-medium">
                  87% Accuracy
                </div>
              </motion.div>
              <motion.div 
                className="flex items-center space-x-2"
                whileHover={{ scale: 1.05 }}
              >
                <div className="bg-blue-500/20 text-blue-500 px-3 py-1 rounded-full text-sm font-medium">
                  10,000+ Active Traders
                </div>
              </motion.div>
              <motion.div 
                className="flex items-center space-x-2"
                whileHover={{ scale: 1.05 }}
              >
                <div className="bg-purple-500/20 text-purple-500 px-3 py-1 rounded-full text-sm font-medium">
                  24/7 Market Coverage
                </div>
              </motion.div>
            </motion.div>
          </div>

          {/* Right Column - Enhanced Animated Prediction Graph */}
          <motion.div
            className="w-full h-[500px] lg:h-[600px]"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.2, duration: 1 }}
          >
            <AnimatedPredictionGraph />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeroSection;