import { useState, useCallback } from 'react';
import { LoginFormData, SignupFormData } from '../types/auth';
import { authApi } from '../services/api';
import { useAuth } from '../context/AuthContext';
import { useToastContext } from '../context/ToastContext';
import { useNavigate } from 'react-router-dom';

export const useAuthHandlers = (onSuccess?: () => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const { showToast } = useToastContext();
  const navigate = useNavigate();

  const handleLogin = useCallback(async (data: LoginFormData) => {
    try {
      setIsLoading(true);
      console.log('Attempting login for:', data.email);
      const response = await authApi.login(data);
      console.log('Login response:', response);
      
      // Check if user is verified from the response
      if (response.user && response.user.isVerified === false) {
        console.log('User not verified, redirecting to check email page');
        showToast('warning', 'Please verify your email to access all features');
        navigate('/check-email', { state: { email: data.email } });
        return;
      }
      
      // User is verified, proceed with login
      console.log('User verified, proceeding with login');
      login(response.token, response.user);
      showToast('success', 'Successfully logged in!');
      onSuccess?.();
    } catch (error: any) {
      console.error('Login error:', error);
      const errorMessage = error.response?.data?.message || 'Login failed';
      showToast('error', errorMessage);
      
      // If the error message indicates verification is needed, redirect to check email
      if (errorMessage.toLowerCase().includes('verify') || errorMessage.toLowerCase().includes('verification')) {
        navigate('/check-email', { state: { email: data.email } });
      }
      
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [login, showToast, navigate, onSuccess]);

  const handleSignup = useCallback(async (data: SignupFormData) => {
    try {
      setIsLoading(true);
      console.log('Attempting signup for:', data.email);
      const response = await authApi.signup(data);
      console.log('Signup response:', response);
      
      login(response.token, response.user);
      showToast('success', 'Account created successfully! Please verify your email.');
      navigate('/check-email', { state: { email: data.email } });
    } catch (error: any) {
      console.error('Signup error:', error);
      showToast('error', error.response?.data?.message || 'Signup failed');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [login, showToast, navigate]);

  const handleForgotPassword = useCallback(async (email: string) => {
    try {
      setIsLoading(true);
      console.log('Requesting password reset for:', email);
      await authApi.forgotPassword(email);
      showToast('success', 'Password reset instructions sent to your email');
      navigate('/forgot-password', { state: { email } });
      return true;
    } catch (error: any) {
      console.error('Forgot password error:', error);
      showToast('error', error.response?.data?.message || 'Failed to send reset email');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [showToast, navigate]);

  return {
    isLoading,
    handleLogin,
    handleSignup,
    handleForgotPassword,
  };
};