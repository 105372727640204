import { useState, useEffect } from 'react';
import { MarketNews } from '../types';

const mockNews: MarketNews[] = [
  {
    id: '1',
    title: 'Federal Reserve Signals Potential Rate Cuts',
    description: 'The Federal Reserve indicated it might begin cutting interest rates in 2024 as inflation shows signs of cooling.',
    source: 'Financial Times',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'positive',
  },
  {
    id: '2',
    title: 'Tech Stocks Rally on AI Developments',
    description: 'Major technology companies see significant gains as artificial intelligence innovations drive market optimism.',
    source: 'Reuters',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'positive',
  },
  {
    id: '3',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '4',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '5',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '6',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '7',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '8',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '9',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '10',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
  {
    id: '11',
    title: 'Oil Prices Volatile Amid Global Tensions',
    description: 'Crude oil prices experience volatility as geopolitical tensions in key producing regions escalate.',
    source: 'Bloomberg',
    url: '#',
    publishedAt: new Date().toISOString(),
    sentiment: 'neutral',
  },
];

export const useNews = () => {
  const [news, setNews] = useState<MarketNews[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        setNews(mockNews);
        setError(null);
      } catch (err) {
        setError('Failed to fetch news');
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  return { news, loading, error };
};