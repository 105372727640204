import axios from 'axios';
import { LoginFormData, SignupFormData, AuthResponse } from '../types/auth';
import { oauthService } from './oauth';

const API_URL = import.meta.env.VITE_API_URL;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add response interceptor for debugging
api.interceptors.response.use(
  (response) => {
    console.log(`API Response [${response.config.url}]:`, response.data);
    return response;
  },
  (error) => {
    console.error(`API Error [${error.config?.url}]:`, error.response?.data || error.message);
    return Promise.reject(error);
  }
);

export const authApi = {
  login: async (data: LoginFormData): Promise<AuthResponse> => {
    console.log('Login request:', data.email);
    const response = await api.post('/auth/login', data);
    return response.data;
  },

  signup: async (data: SignupFormData): Promise<AuthResponse> => {
    console.log('Signup request:', data.email);
    const response = await api.post('/auth/signup', data);
    return response.data;
  },

  forgotPassword: async (email: string): Promise<{ message: string }> => {
    console.log('Forgot password request:', email);
    const response = await api.post('/auth/forgot-password', { email });
    return response.data;
  },

  resetPassword: async (token: string, password: string): Promise<{ message: string }> => {
    console.log('Reset password request with token');
    const response = await api.post('/auth/reset-password', { token, password });
    return response.data;
  },

  verifyEmail: async (token: string): Promise<{ message: string }> => {
    console.log('Verify email request with token');
    const response = await api.post('/auth/verify-email', { token });
    return response.data;
  },

  getCurrentUser: async (): Promise<AuthResponse> => {
    try {
      console.log('Getting current user data');
      const response = await api.get('/auth/me');
      console.log('Current user data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching current user:', error);
      throw error;
    }
  },

  logout: async (): Promise<void> => {
    console.log('Logout request');
    await api.post('/auth/logout');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_user');
  },

  // OAuth methods
  googleLogin: (accessToken: string) => oauthService.googleLogin(accessToken),
  githubCallback: (code: string) => oauthService.githubCallback(code),
};