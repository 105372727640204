import axios from 'axios';
import { AuthResponse } from '../types/auth';

const API_URL = import.meta.env.VITE_API_URL;

export const oauthService = {
  googleLogin: async (accessToken: string): Promise<AuthResponse> => {
    const response = await axios.post(`${API_URL}/auth/google`, {
      access_token: accessToken,
    });
    return response.data;
  },

  githubCallback: async (code: string): Promise<AuthResponse> => {
    const response = await axios.post(`${API_URL}/auth/github/callback`, { code });
    return response.data;
  },
};