import React from 'react';
import { Globe, Briefcase, CoinsIcon, Bitcoin } from 'lucide-react';

interface MarketSelectorProps {
  selectedMarket: string;
  onMarketChange: (market: string) => void;
}

const markets = [
  { id: 'stocks', name: 'Stocks', icon: Briefcase },
  { id: 'forex', name: 'Forex', icon: Globe },
  { id: 'crypto', name: 'Crypto', icon: Bitcoin },
  { id: 'commodities', name: 'Gold', icon: CoinsIcon },
];

const MarketSelector: React.FC<MarketSelectorProps> = ({
  selectedMarket,
  onMarketChange,
}) => {
  return (
    <div className="flex flex-wrap gap-4">
      {markets.map(({ id, name, icon: Icon }) => (
        <button
          key={id}
          onClick={() => onMarketChange(id)}
          className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all ${
            selectedMarket === id
              ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
              : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
          }`}
          aria-label={`Select ${name} market`}
        >
          <Icon size={20} />
          <span>{name}</span>
        </button>
      ))}
    </div>
  );
};

export default MarketSelector;