import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useToastContext } from '../context/ToastContext';
import { Mail, Check, Loader, X, RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';
import { authApi } from '../services/api';

const Verification = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const { refreshUser } = useAuth();
  const { showToast } = useToastContext();

  const [verificationState, setVerificationState] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [countdown, setCountdown] = useState<number>(5);

  useEffect(() => {
    const verifyEmailToken = async () => {
      if (!token) {
        setVerificationState('error');
        setErrorMessage('No verification token found');
        showToast('error', 'No verification token found');
        return;
      }

      try {
        console.log('Verifying token:', token);
        
        // Verify the email token
        const response = await authApi.verifyEmail(token);
        console.log('Verification response:', response);
        
        setVerificationState('success');
        showToast('success', 'Email verified successfully! You can now login.');
        
        // Refresh user data to update verification status
        const updatedUser = await refreshUser();
        console.log('Updated user after verification:', updatedUser);
        
        // Start countdown for redirect
        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              navigate('/');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        
        return () => clearInterval(timer);
      } catch (error: any) {
        console.error('Email verification failed:', error);
        
        // If the email is already verified, treat it as a success
        if (error.response?.data?.message?.includes('already verified')) {
          setVerificationState('success');
          showToast('success', 'Email already verified! You can now login.');
          
          // Refresh user data to update verification status
          const updatedUser = await refreshUser();
          console.log('Updated user (already verified):', updatedUser);
          
          // Start countdown for redirect
          const timer = setInterval(() => {
            setCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(timer);
                navigate('/');
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
          
          return () => clearInterval(timer);
        } else {
          // Show error for other errors
          setVerificationState('error');
          setErrorMessage(error.response?.data?.message || 'Email verification failed');
          showToast('error', error.response?.data?.message || 'Email verification failed');
        }
      }
    };

    verifyEmailToken();
  }, [token, navigate, refreshUser, showToast]);

  return (
    <div className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black flex items-center justify-center px-4">
      <motion.div 
        className="max-w-md w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-8 rounded-xl border border-blue-500/10 shadow-gold"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Icon based on verification state */}
        <div className="flex justify-center mb-6">
          <div className={`p-4 rounded-full ${
            verificationState === 'verifying' ? 'bg-blue-500/50' :
            verificationState === 'success' ? 'bg-gradient-to-br from-green-500 to-blue-500' :
            'bg-gradient-to-br from-red-500 to-purple-500'
          }`}>
            {verificationState === 'verifying' && <Loader className="h-10 w-10 text-white animate-spin" />}
            {verificationState === 'success' && <Check className="h-10 w-10 text-white" />}
            {verificationState === 'error' && <X className="h-10 w-10 text-white" />}
          </div>
        </div>

        {/* Title and Description */}
        <h2 className="text-2xl font-bold text-white text-center mb-4">
          {verificationState === 'verifying' && 'Verifying Your Email'}
          {verificationState === 'success' && 'Email Verified Successfully!'}
          {verificationState === 'error' && 'Verification Failed'}
        </h2>
        
        <p className="text-gray-300 text-center mb-8">
          {verificationState === 'verifying' && 'Please wait while we verify your email address...'}
          {verificationState === 'success' && `Your email has been verified. You'll be redirected to the login page in ${countdown} seconds.`}
          {verificationState === 'error' && errorMessage}
        </p>

        {/* Action Buttons */}
        <div className="space-y-3">
          {verificationState === 'success' && (
            <button
              onClick={() => navigate('/')}
              className="w-full flex items-center justify-center space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors"
            >
              <span>Continue to Login</span>
            </button>
          )}

          {verificationState === 'error' && (
            <>
              <button
                onClick={() => navigate('/auth/signup')}
                className="w-full flex items-center justify-center space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors"
              >
                <RefreshCw className="h-5 w-5" />
                <span>Try Again</span>
              </button>
              
              <button
                onClick={() => navigate('/')}
                className="w-full flex items-center justify-center space-x-2 bg-transparent border border-blue-500 text-blue-500 py-3 rounded-lg font-semibold hover:bg-blue-500/10 transition-colors"
              >
                <span>Back to Home</span>
              </button>
            </>
          )}
        </div>

        {/* Help Text */}
        {verificationState === 'error' && (
          <p className="text-gray-400 text-sm text-center mt-6">
            If you continue to experience issues, please contact our support team at{' '}
            <a href="mailto:support@charttalks.com" className="text-blue-400 hover:text-blue-300">
              support@charttalks.com
            </a>
          </p>
        )}
      </motion.div>
    </div>
  );
};

export default Verification;