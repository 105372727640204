import { useState, useCallback, useEffect } from 'react';
import { ToastMessage } from '../components/ui/ToastContainer';

export const useToast = () => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);
  const [errorShown, setErrorShown] = useState<Record<string, boolean>>({});

  // Clean up toasts when component unmounts
  useEffect(() => {
    return () => {
      setToasts([]);
      setErrorShown({});
    };
  }, []);

  // Limit the number of toasts to 3
  useEffect(() => {
    if (toasts.length > 3) {
      // Remove the oldest toast
      setToasts((prev) => prev.slice(1));
    }
  }, [toasts]);

  const addToast = useCallback((type: ToastMessage['type'], message: string) => {
    const id = Math.random().toString(36).substring(2, 9);
    
    // For error messages, check if we've already shown this exact error
    if (type === 'error') {
      // If we've already shown this error, don't show it again
      if (errorShown[message]) {
        console.log('Skipping duplicate error toast:', message);
        return;
      }
      
      // Mark this error as shown
      setErrorShown(prev => ({ ...prev, [message]: true }));
      
      // Remove any existing error toasts
      setToasts(prev => {
        const filteredToasts = prev.filter(toast => toast.type !== 'error');
        return [...filteredToasts, { id, type, message }];
      });
      
      // Reset the error shown status after 5 seconds
      setTimeout(() => {
        setErrorShown(prev => ({ ...prev, [message]: false }));
      }, 5000);
      
      return;
    }
    
    // For non-error toasts, check for duplicates
    const isDuplicate = toasts.some(
      (toast) => toast.message === message && toast.type === type
    );
    
    if (!isDuplicate) {
      setToasts((prev) => [...prev, { id, type, message }]);
    }
  }, [toasts, errorShown]);

  const removeToast = useCallback((id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }, []);

  const clearAllToasts = useCallback(() => {
    setToasts([]);
    setErrorShown({});
  }, []);

  return {
    toasts,
    addToast,
    removeToast,
    clearAllToasts,
  };
};