import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Mail, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

const forgotPasswordSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
});

type ForgotPasswordFormData = z.infer<typeof forgotPasswordSchema>;

interface ForgotPasswordFormProps {
  onSubmit: (email: string) => Promise<void>;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const handleFormSubmit = async (data: ForgotPasswordFormData) => {
    try {
      setIsLoading(true);
      setError(null);
      await onSubmit(data.email);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to process request');
    } finally {
      setIsLoading(false);
    }
  };

  const inputVariants = {
    focus: { scale: 1.02, boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.5)" },
    blur: { scale: 1, boxShadow: "none" },
  };

  const buttonVariants = {
    hover: { scale: 1.03, boxShadow: "0 0 15px rgba(59, 130, 246, 0.5)" },
    tap: { scale: 0.97 },
    disabled: { opacity: 0.7 }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-5">
      <p className="text-gray-400 mb-6">
        Enter your email address and we'll send you instructions to reset your password.
      </p>
      
      {/* Email Input */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1.5">
          Email Address
        </label>
        <motion.div 
          className="relative"
          initial="blur"
          whileFocus="focus"
          animate="blur"
          variants={inputVariants}
        >
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            {...register('email')}
            type="email"
            className="w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-lg py-3 px-10 text-white focus:ring-2 focus:ring-blue-500 focus:outline-none border border-blue-500/10 transition-all"
            placeholder="Enter your email"
          />
        </motion.div>
        {errors.email && (
          <motion.p 
            className="mt-1.5 text-sm text-red-500"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            {errors.email.message}
          </motion.p>
        )}
      </div>

      {/* Error Message */}
      {error && (
        <motion.div 
          className="bg-red-500/10 border border-red-500/30 rounded-lg p-3"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <p className="text-sm text-red-500">{error}</p>
        </motion.div>
      )}

      {/* Submit Button */}
      <motion.button
        type="submit"
        disabled={isLoading}
        className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        animate={isLoading ? "disabled" : ""}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Sending...
          </span>
        ) : (
          <>
            Send Reset Instructions
            <ArrowRight className="ml-2 h-5 w-5" />
          </>
        )}
      </motion.button>
    </form>
  );
};

export default ForgotPasswordForm;