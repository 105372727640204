import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { USER_ROLES, REDIRECT_PATHS } from '../../utils/constants';

interface ProtectedRouteProps {
  children: React.ReactNode;
  adminOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, adminOnly = false }) => {
  const { isAuthenticated, user, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={REDIRECT_PATHS.DEFAULT} state={{ from: location }} replace />;
  }

  if (adminOnly && (!user || user.role !== USER_ROLES.ADMIN)) {
    return <Navigate to={REDIRECT_PATHS.DEFAULT} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;