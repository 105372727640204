import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, List, Settings, TrendingUp, LogOut } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { ROUTES } from '../../utils/constants';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const menuItems = [
    {
      title: 'Create Prediction',
      icon: <Plus className="h-6 w-6" />,
      href: ROUTES.ADMIN.CREATE_PREDICTION,
      description: 'Create new market predictions'
    },
    {
      title: 'Manage Predictions',
      icon: <List className="h-6 w-6" />,
      href: ROUTES.ADMIN.MANAGE_PREDICTIONS,
      description: 'View and manage existing predictions'
    },
    {
      title: 'Settings',
      icon: <Settings className="h-6 w-6" />,
      href: '/admin/settings',
      description: 'Configure admin preferences'
    }
  ];

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Admin Header */}
      <header className="bg-gray-900 border-b border-gray-800 px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <TrendingUp className="h-8 w-8 text-yellow-500" />
            <h1 className="text-2xl font-bold text-white">Admin Dashboard</h1>
          </div>
          <button
            onClick={handleLogout}
            className="flex items-center space-x-2 bg-red-500/10 text-red-500 px-4 py-2 rounded-lg hover:bg-red-500/20 transition-colors"
          >
            <LogOut className="h-5 w-5" />
            <span>Logout</span>
          </button>
        </div>
      </header>

      <div className="container mx-auto px-6 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {menuItems.map((item) => (
            <button
              key={item.title}
              onClick={() => navigate(item.href)}
              className="bg-gray-900 p-6 rounded-xl hover:bg-gray-800 transition-colors text-left"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-yellow-500/10 rounded-lg text-yellow-500">
                  {item.icon}
                </div>
                <h2 className="text-xl font-semibold text-white">{item.title}</h2>
              </div>
              <p className="text-gray-400">{item.description}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;