import React from 'react';

interface TimelineStep {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface RefundTimelineProps {
  steps: TimelineStep[];
}

const RefundTimeline: React.FC<RefundTimelineProps> = ({ steps }) => {
  return (
    <div className="relative">
      {steps.map((step, index) => (
        <div key={index} className="flex items-start mb-8 last:mb-0">
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-500 flex items-center justify-center">
            {step.icon}
          </div>
          
          <div className="ml-4 flex-grow">
            <h3 className="text-lg font-semibold text-white mb-1">
              {step.title}
            </h3>
            <p className="text-gray-400">{step.description}</p>
          </div>

          {index < steps.length - 1 && (
            <div className="absolute left-5 ml-[1px] w-[2px] h-16 bg-gray-700" style={{ top: `${index * 96 + 40}px` }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default RefundTimeline;