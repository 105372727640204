import { useState, useEffect } from 'react';
import { PredictionData } from '../types';
import { predictionsService } from '../services/predictions.service';
import { useToastContext } from '../context/ToastContext';

export const usePredictions = (category: string) => {
  const [predictions, setPredictions] = useState<PredictionData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showToast } = useToastContext();

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await predictionsService.getPredictions(category);
        setPredictions(data);
      } catch (err: any) {
        const errorMessage = err.response?.data?.message || 'Failed to fetch predictions';
        setError(errorMessage);
        showToast('error', errorMessage);
        setPredictions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPredictions();
  }, [category, showToast]);

  return { predictions, loading, error };
};