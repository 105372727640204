import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Crown, Calendar, Clock, AlertCircle, Star, Shield, Zap, ChevronRight, RefreshCw,
  CheckCircle
} from 'lucide-react';
import { useSubscription } from '../context/SubscriptionContext';
import { SUBSCRIPTION_PLANS } from '../utils/subscription';

const SubscriptionStatus = () => {
  const navigate = useNavigate();
  const {
    subscriptionStatus,
    subscriptionType,
    subscriptionStartDate,
    subscriptionEndDate,
    isLoading
  } = useSubscription();

  const currentPlan = SUBSCRIPTION_PLANS.find(plan => plan.tier === subscriptionType);

  const getDaysRemaining = () => {
    if (!subscriptionEndDate) return 0;
    const now = new Date();
    const end = new Date(subscriptionEndDate);
    const diff = end.getTime() - now.getTime();
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  };

  const getTierIcon = () => {
    switch (subscriptionType) {
      case 'gold':
        return <Crown className="h-8 w-8 text-yellow-500" />;
      case 'premium':
        return <Star className="h-8 w-8 text-purple-500" />;
      case 'pro':
        return <Shield className="h-8 w-8 text-blue-500" />;
      case 'regular':
        return <Zap className="h-8 w-8 text-green-500" />;
      default:
        return <Crown className="h-8 w-8 text-yellow-500" />;
    }
  };

  const getTierColor = () => {
    switch (subscriptionType) {
      case 'gold':
        return 'text-yellow-500';
      case 'premium':
        return 'text-purple-500';
      case 'pro':
        return 'text-blue-500';
      case 'regular':
        return 'text-green-500';
      default:
        return 'text-gray-400';
    }
  };

  const getTierGradient = () => {
    switch (subscriptionType) {
      case 'gold':
        return 'from-yellow-500/10 to-yellow-500/5';
      case 'premium':
        return 'from-purple-500/10 to-purple-500/5';
      case 'pro':
        return 'from-blue-500/10 to-blue-500/5';
      case 'regular':
        return 'from-green-500/10 to-green-500/5';
      default:
        return 'from-gray-800 to-gray-900';
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black pt-20">
        <div className="container mx-auto px-4 py-8">
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black pt-20">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`bg-gradient-to-br ${getTierGradient()} rounded-xl p-8 shadow-xl`}
          >
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center space-x-4">
                {getTierIcon()}
                <h1 className="text-3xl font-bold text-white">Subscription Status</h1>
              </div>
              {subscriptionStatus !== 'active' ? (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate('/subscription')}
                  className="flex items-center space-x-2 bg-yellow-500 text-black px-6 py-3 rounded-lg hover:bg-yellow-400 transition-colors"
                >
                  <span className="font-semibold">Upgrade Now</span>
                  <ChevronRight className="h-5 w-5" />
                </motion.button>
              ) : getDaysRemaining() <= 7 && (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate('/subscription')}
                  className="flex items-center space-x-2 bg-yellow-500/20 text-yellow-500 px-6 py-3 rounded-lg hover:bg-yellow-500/30 transition-colors"
                >
                  <RefreshCw className="h-5 w-5" />
                  <span className="font-semibold">Renew Subscription</span>
                </motion.button>
              )}
            </div>

            <div className="space-y-6">
              {/* Status */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="flex items-center justify-between p-6 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-700"
              >
                <div className="flex items-center space-x-4">
                  <CheckCircle className={`h-6 w-6 ${
                    subscriptionStatus === 'active' ? 'text-green-500' : 'text-red-500'
                  }`} />
                  <div>
                    <p className="text-white font-semibold">Status</p>
                    <p className={`${
                      subscriptionStatus === 'active' ? 'text-green-500' : 'text-red-500'
                    }`}>
                      {subscriptionStatus.toUpperCase()}
                    </p>
                  </div>
                </div>
                {subscriptionStatus === 'active' && getDaysRemaining() <= 3 && (
                  <div className="flex items-center space-x-2 text-yellow-500 bg-yellow-500/10 px-4 py-2 rounded-lg">
                    <AlertCircle className="h-5 w-5" />
                    <span>Expiring Soon</span>
                  </div>
                )}
              </motion.div>

              {/* Plan Details */}
              {currentPlan && (
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-700"
                >
                  <h2 className="text-xl font-semibold text-white mb-6">Current Plan Benefits</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                    <div>
                      <p className="text-gray-400">Plan Type</p>
                      <p className={`text-xl font-semibold ${getTierColor()}`}>{currentPlan.name}</p>
                    </div>
                    <div>
                      <p className="text-gray-400">Monthly Price</p>
                      <p className="text-xl font-semibold text-white">₹{currentPlan.price}</p>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <p className="text-gray-400 font-medium">Plan Features:</p>
                    <div className="grid gap-3">
                      {currentPlan.features.map((feature, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="flex items-center space-x-3 bg-gray-900/50 p-3 rounded-lg"
                        >
                          <CheckCircle className={`h-5 w-5 ${getTierColor()}`} />
                          <span className="text-white">{feature}</span>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              )}

              {/* Subscription Dates */}
              {subscriptionStatus === 'active' && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="grid grid-cols-1 md:grid-cols-2 gap-4"
                >
                  <div className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-700">
                    <div className="flex items-center space-x-3 mb-3">
                      <Calendar className={`h-5 w-5 ${getTierColor()}`} />
                      <p className="text-white font-semibold">Start Date</p>
                    </div>
                    <p className="text-gray-300">
                      {subscriptionStartDate?.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </p>
                  </div>

                  <div className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-700">
                    <div className="flex items-center space-x-3 mb-3">
                      <Clock className={`h-5 w-5 ${getTierColor()}`} />
                      <p className="text-white font-semibold">Expiry Date</p>
                    </div>
                    <p className="text-gray-300">
                      {subscriptionEndDate?.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                      {getDaysRemaining() > 0 && (
                        <span className="ml-2 text-sm text-gray-400">
                          ({getDaysRemaining()} days remaining)
                        </span>
                      )}
                    </p>
                  </div>
                </motion.div>
              )}

              {/* Upgrade/Renew Section */}
              {(subscriptionStatus !== 'active' || getDaysRemaining() <= 7) && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-8 p-6 bg-gradient-to-r from-yellow-500/20 to-yellow-500/10 rounded-lg text-center"
                >
                  <h3 className="text-xl font-semibold text-white mb-3">
                    {subscriptionStatus === 'active' ? 'Renew Your Subscription' : 'Upgrade Your Plan'}
                  </h3>
                  <p className="text-gray-300 mb-4">
                    {subscriptionStatus === 'active'
                      ? "Don't miss out on continuous access to premium features"
                      : 'Get access to premium features and take your trading to the next level'}
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => navigate('/subscription')}
                    className="flex items-center justify-center space-x-2 bg-yellow-500 text-black px-8 py-3 rounded-lg hover:bg-yellow-400 transition-colors mx-auto"
                  >
                    <span className="font-semibold">View Plans</span>
                    <ChevronRight className="h-5 w-5" />
                  </motion.button>
                </motion.div>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;