import React from 'react';
import { ArrowUpCircle, ArrowDownCircle, Target, Ban, Lock } from 'lucide-react';
import { PredictionData } from '../../types';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface PredictionCardProps {
  prediction: PredictionData;
}

const PredictionCard: React.FC<PredictionCardProps> = ({ prediction }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const isBuy = prediction.type === 'buy';
  
  // Format the date
  const formattedDate = new Date(prediction.createdAt).toLocaleString('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short'
  });

  // Check if user has access to this prediction
  const subscriptionTiers = {
    free: ['free'],
    regular: ['free', 'regular'],
    pro: ['free', 'regular', 'pro'],
    premium: ['free', 'regular', 'pro', 'premium'],
    gold: ['free', 'regular', 'pro', 'premium', 'gold']
  };

  const userTier = user?.subscriptionType || 'free';
  const allowedTiers = subscriptionTiers[userTier as keyof typeof subscriptionTiers] || ['free'];
  const hasAccess = allowedTiers.includes(prediction.subscriptionTier);

  const handleUpgradeClick = () => {
    navigate('/subscription');
  };

  // If user doesn't have access, show locked card
  if (!hasAccess) {
    return (
      <div className="bg-gradient-to-r from-gray-800/50 to-gray-900/50 rounded-xl p-6 border border-gray-700/50 relative overflow-hidden">
        <div className="absolute inset-0 backdrop-blur-sm bg-black/30 flex flex-col items-center justify-center z-10">
          <Lock className="h-12 w-12 text-yellow-500 mb-4" />
          <h3 className="text-xl font-bold text-white mb-2">Premium Prediction</h3>
          <p className="text-gray-300 text-center mb-4">
            Upgrade your subscription to access this {prediction.subscriptionTier} prediction
          </p>
          <button
            onClick={handleUpgradeClick}
            className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-black px-6 py-2 rounded-lg font-semibold hover:from-yellow-400 hover:to-yellow-500 transition-colors"
          >
            Upgrade Now
          </button>
        </div>

        {/* Blurred content */}
        <div className="filter blur-sm">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-xl font-bold text-white">{prediction.symbol}</h3>
              <p className="text-gray-200 text-sm">{formattedDate}</p>
            </div>
            {isBuy ? (
              <ArrowUpCircle className="h-8 w-8 text-green-300" />
            ) : (
              <ArrowDownCircle className="h-8 w-8 text-red-300" />
            )}
          </div>

          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="bg-gray-800/30 p-3 rounded-lg">
              <p className="text-sm text-gray-200 mb-1">Entry</p>
              <p className="text-white font-semibold">$XX.XX</p>
            </div>
            <div className="bg-gray-800/30 p-3 rounded-lg">
              <p className="text-sm text-gray-200 mb-1">Stop Loss</p>
              <p className="text-red-300 font-semibold">$XX.XX</p>
            </div>
            <div className="bg-gray-800/30 p-3 rounded-lg">
              <p className="text-sm text-gray-200 mb-1">Take Profit</p>
              <p className="text-green-300 font-semibold">$XX.XX</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 rounded-xl p-6 hover:transform hover:-translate-y-1 transition-all">
      {/* Header with Symbol and Type */}
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-xl font-bold text-white">{prediction.symbol}</h3>
          <p className="text-gray-200 text-sm">{formattedDate}</p>
        </div>
        {isBuy ? (
          <ArrowUpCircle className="h-8 w-8 text-green-300" />
        ) : (
          <ArrowDownCircle className="h-8 w-8 text-red-300" />
        )}
      </div>

      {/* Price Information */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="bg-gray-800/30 p-3 rounded-lg">
          <p className="text-sm text-gray-200 mb-1">Entry</p>
          <p className="text-white font-semibold">${prediction.entryPrice.toFixed(2)}</p>
        </div>
        <div className="bg-gray-800/30 p-3 rounded-lg">
          <p className="text-sm text-gray-200 mb-1">Stop Loss</p>
          <p className="text-red-300 font-semibold">${prediction.stopLoss.toFixed(2)}</p>
        </div>
        <div className="bg-gray-800/30 p-3 rounded-lg">
          <p className="text-sm text-gray-200 mb-1">Take Profit</p>
          <p className="text-green-300 font-semibold">${prediction.takeProfit.toFixed(2)}</p>
        </div>
      </div>

      {/* Analysis */}
      <div className="mb-4">
        <h4 className="text-white font-semibold mb-2">Analysis</h4>
        <p className="text-gray-200 text-sm line-clamp-3">{prediction.analysis}</p>
      </div>

      {/* Footer Information */}
      <div className="flex justify-between items-center pt-4 border-t border-gray-800/30">
        <div className="flex items-center space-x-2">
          <span className={`px-2 py-1 rounded-full text-xs font-medium ${
            prediction.subscriptionTier === 'free' ? 'bg-gray-700/50 text-gray-200' :
            prediction.subscriptionTier === 'regular' ? 'bg-green-500/20 text-green-200' :
            prediction.subscriptionTier === 'pro' ? 'bg-blue-500/20 text-blue-200' :
            prediction.subscriptionTier === 'premium' ? 'bg-purple-500/20 text-purple-200' :
            'bg-yellow-500/20 text-yellow-200'
          }`}>
            {prediction.subscriptionTier.charAt(0).toUpperCase() + prediction.subscriptionTier.slice(1)}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-gray-200">Confidence:</span>
          <span className="text-yellow-300 font-semibold">{prediction.confidence}%</span>
        </div>
      </div>
    </div>
  );
};

export default PredictionCard;