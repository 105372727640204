import React from 'react';
import { Mail, MessageSquare, MapPin, Phone } from 'lucide-react';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setResult("Sending....");

    const formData = new FormData(event.currentTarget);
    formData.append("access_key", "038a343d-2bed-4fb0-a868-176fad7167d4"); // Replace with your Web3Forms access key

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.currentTarget.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black pt-20">
      <Helmet>
        <title>Contact ChartTalks | Trading Predictions & Market Analysis</title>
        <meta name="description" content="Contact our team of trading experts for questions about our forex, stocks, crypto, and gold predictions. Get support for your trading journey with ChartTalks." />
        <meta name="keywords" content="contact trading experts, forex trading support, stock market help, crypto trading assistance, gold trading contact, trading platform support" />
      </Helmet>
      
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <h1 className="text-4xl font-bold text-white text-center mb-4">
            Get in Touch
          </h1>
          <p className="text-gray-400 text-center mb-12">
            Have questions about our forex, stocks, crypto, or gold trading predictions? We're here to help and provide you with the support
            you need.
          </p>

          {/* Contact Details and Form */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Contact Details */}
            <div className="space-y-6">
              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full">
                    <Mail className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Email Us</h3>
                </div>
                <p className="text-gray-400">support@charttalks.com</p>
              </div>

              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full">
                    <Phone className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Call Us</h3>
                </div>
                <p className="text-gray-400">+91 07660 34568</p>
              </div>

              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full">
                    <MapPin className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Visit Us</h3>
                </div>
                <p className="text-gray-400">
                   Stock Exchange Building, Market Street
                  <br />
                  New Delhi, INDIA
                </p>
              </div>
              
              {/* Trading Hours */}
              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-4">Trading Support Hours</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-400">Monday - Friday:</span>
                    <span className="text-white">9:00 AM - 6:00 PM IST</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">Saturday:</span>
                    <span className="text-white">10:00 AM - 2:00 PM IST</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">Sunday:</span>
                    <span className="text-white">Closed</span>
                  </div>
                </div>
                <p className="mt-4 text-sm text-gray-400">
                  Our trading experts are available during market hours to assist with your forex, stocks, crypto, and gold trading inquiries.
                </p>
              </div>
            </div>

            {/* Contact Form */}
            <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
              <div className="flex items-center space-x-4 mb-6">
                <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full">
                  <MessageSquare className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-white">
                  Send us a Message
                </h3>
              </div>

              <form onSubmit={onSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    placeholder="Your name"
                    aria-label="Your name"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    placeholder="Your email"
                    aria-label="Your email"
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">
                    Subject
                  </label>
                  <select
                    name="subject"
                    className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    aria-label="Message subject"
                    required
                  >
                    <option value="">Select a subject</option>
                    <option value="forex">Forex Trading Support</option>
                    <option value="stocks">Stock Market Inquiry</option>
                    <option value="crypto">Cryptocurrency Trading</option>
                    <option value="gold">Gold Trading Question</option>
                    <option value="subscription">Subscription Support</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">
                    Message
                  </label>
                  <textarea
                    name="message"
                    rows={4}
                    className="w-full bg-gray-800 rounded-lg py-2 px-4 text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    placeholder="Your message"
                    aria-label="Your message"
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white py-2 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors"
                >
                  Send Message
                </button>
              </form>
              <span className="text-white text-center block mt-4">{result}</span>
            </div>
          </div>
          
          {/* FAQ Section */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold text-white mb-6 text-center">Frequently Asked Questions</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-lg font-semibold text-white mb-2">How quickly will I receive a response?</h3>
                <p className="text-gray-400">
                  We aim to respond to all inquiries within 24 hours during business days. For urgent trading-related questions, premium subscribers receive priority support.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-lg font-semibold text-white mb-2">Do you offer personalized trading advice?</h3>
                <p className="text-gray-400">
                  While we provide institutional-grade market analysis and predictions, we don't offer personalized financial advice. Our platform is designed to help you make informed trading decisions across forex, stocks, crypto, and gold markets.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-lg font-semibold text-white mb-2">How can I upgrade my subscription?</h3>
                <p className="text-gray-400">
                  You can upgrade your subscription by visiting the Subscription page in your account dashboard. If you need assistance with the upgrade process, please contact our support team.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-lg font-semibold text-white mb-2">Do you offer refunds?</h3>
                <p className="text-gray-400">
                  Yes, we offer a 7-day money-back guarantee for all new subscriptions. Please refer to our Refund Policy for more details or contact our support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;