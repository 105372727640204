import axios from 'axios';
import { PredictionData } from '../types';

const API_URL = import.meta.env.VITE_API_URL;

export const predictionsService = {
  getPredictions: async (category: string): Promise<PredictionData[]> => {
    try {
      const response = await axios.get(`${API_URL}/predictions/${category}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      
      // Sort predictions by creation date (newest first)
      const predictions = response.data.sort((a: PredictionData, b: PredictionData) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      
      return predictions;
    } catch (error) {
      console.error('Error fetching predictions:', error);
      throw error;
    }
  },

  getAllPredictions: async (): Promise<PredictionData[]> => {
    try {
      const response = await axios.get(`${API_URL}/admin/predictions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      
      // Ensure each prediction has an id property (use _id if id is not present)
      const predictions = response.data.predictions.map((prediction: any) => ({
        ...prediction,
        id: prediction.id || prediction._id // Ensure id is available
      }));
      
      // Sort predictions by creation date (newest first)
      return predictions.sort((a: PredictionData, b: PredictionData) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } catch (error) {
      console.error('Error fetching all predictions:', error);
      throw error;
    }
  },

  getPredictionDetails: async (id: string): Promise<PredictionData> => {
    try {
      if (!id) {
        throw new Error('Invalid prediction ID: ID is missing');
      }
      
      const response = await axios.get(`${API_URL}/admin/predictions/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      
      // Ensure the prediction has an id property
      const prediction = response.data.prediction;
      return {
        ...prediction,
        id: prediction.id || prediction._id // Ensure id is available
      };
    } catch (error) {
      console.error('Error fetching prediction details:', error);
      throw error;
    }
  },

  createPrediction: async (data: Partial<PredictionData>): Promise<PredictionData> => {
    try {
      const response = await axios.post(`${API_URL}/admin/predictions`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      
      const prediction = response.data.prediction;
      return {
        ...prediction,
        id: prediction.id || prediction._id // Ensure id is available
      };
    } catch (error) {
      console.error('Error creating prediction:', error);
      throw error;
    }
  },

  updatePrediction: async (id: string, data: Partial<PredictionData>): Promise<PredictionData> => {
    try {
      if (!id) {
        throw new Error('Invalid prediction ID: ID is missing');
      }
      
      const response = await axios.put(`${API_URL}/admin/predictions/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      
      const prediction = response.data.prediction;
      return {
        ...prediction,
        id: prediction.id || prediction._id // Ensure id is available
      };
    } catch (error) {
      console.error('Error updating prediction:', error);
      throw error;
    }
  },

  deletePrediction: async (id: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error('Invalid prediction ID: ID is missing');
      }
      
      await axios.delete(`${API_URL}/admin/predictions/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
    } catch (error) {
      console.error('Error deleting prediction:', error);
      throw error;
    }
  },
};