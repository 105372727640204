import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useToastContext } from '../../context/ToastContext';
import { SUBSCRIPTION_PLANS } from '../../utils/subscription';
import { initializeRazorpay } from '../../utils/payment';
import PlanCard from '../subscription/PlanCard';
import { SubscriptionPlan } from '../../types/subscription';
import { motion } from 'framer-motion';

const SubscriptionPlans: React.FC = () => {
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null); // Track which plan is loading
  const { isAuthenticated, user, setShowAuthModal } = useAuth();
  const { showToast } = useToastContext();

  const handleSubscribe = async (plan: SubscriptionPlan) => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }

    if (loadingPlanId) return; // Prevent multiple clicks

    try {
      setLoadingPlanId(plan.id); // Set loading state for the clicked plan
      showToast('info', 'Initializing payment...');

      await initializeRazorpay({
        planId: plan.id,
        planType: plan.tier, // Pass the plan tier as planType
        amount: plan.price * 1, // Convert to paise
        currency: 'INR',
        name: `${plan.name} Subscription`,
        email: user?.email || '',
      });
    } catch (error: any) {
      showToast('error', error.message || 'Failed to process payment');
    } finally {
      setLoadingPlanId(null); // Reset loading state
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // Delay between child animations
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20, rotateY: -45 }, // Initial 3D rotation
    visible: {
      opacity: 1,
      y: 0,
      rotateY: 0, // End with no rotation
      transition: { duration: 0.8, ease: 'easeOut' },
    },
    hover: { scale: 1.05, rotateY: 10 }, // 3D hover effect
  };

  return (
    <motion.section
      className="py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }} // Trigger animation once when 20% of the section is visible
      variants={containerVariants}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Choose Your{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              Plan
            </span>
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Select the plan that best fits your trading needs and take your trading to the next level.
          </p>
        </div>

        <motion.div
          className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto"
          variants={containerVariants}
        >
          {SUBSCRIPTION_PLANS.map((plan) => (
            <motion.div
              key={plan.id}
              variants={childVariants}
              whileHover="hover" // Add hover animation
            >
              <PlanCard
                plan={plan}
                isLoading={loadingPlanId === plan.id} // Only show loading for the clicked plan
                onSubscribe={() => handleSubscribe(plan)}
                isCurrentPlan={user?.subscriptionTier === plan.tier}
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
};

export default SubscriptionPlans;