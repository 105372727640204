import { SubscriptionPlan } from '../types/subscription';

export const SUBSCRIPTION_PLANS: SubscriptionPlan[] = [
  {
    id: 'regular',
    name: 'Regular',
    tier: 'regular',
    price: 399,
    features: [
      'Basic market predictions',
      'Daily market updates',
      'Basic technical analysis',
      'Email support',
      'Access to regular trading signals',
      'Basic market insights',
      'Standard market reports',
    ],
  },
  {
    id: 'pro',
    name: 'Pro',
    tier: 'pro',
    price: 499,
    features: [
      'Advanced market predictions',
      'Real-time market updates',
      'Advanced technical analysis',
      'Priority email support',
      'Pro trading signals',
      'Advanced market insights',
      'Detailed market reports',
      'Trading strategy guides',
      'Risk management tools',
    ],
  },
  {
    id: 'premium',
    name: 'Premium',
    tier: 'premium',
    price: 599,
    recommended: true,
    features: [
      'Expert market predictions',
      'Real-time market updates',
      'Advanced technical analysis',
      '24/7 priority support',
      'Premium trading signals',
      'Portfolio tracking',
      'Risk analysis',
      'Expert market insights',
      'Comprehensive market reports',
      'Advanced trading strategies',
      'Risk management suite',
      'Trading performance analytics',
    ],
  },
  {
    id: 'gold',
    name: 'Gold+Premium',
    tier: 'gold',
    price: 999,
    features: [
      'All Premium features',
      'One-on-one consulting',
      'Custom trading strategies',
      'Institutional insights',
      'API access',
      'White-glove support',
      'Exclusive market reports',
      'VIP trading signals',
      'Advanced portfolio management',
      'Custom risk analysis',
      'Priority feature access',
      'Dedicated account manager',
      'Monthly strategy review',
      'Custom market alerts',
    ],
  },
];