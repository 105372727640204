import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface Prediction {
  id: string;
  price: number;
  confidence: number;
  trend: 'bullish' | 'bearish';
  analysis: string;
  points: { x: number; y: number }[];
}

const AnimatedPredictionGraph: React.FC = () => {
  // State for predictions queue
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [currentPredictionIndex, setCurrentPredictionIndex] = useState(0);
  const [countdown, setCountdown] = useState<number>(15);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showNewPredictionFlash, setShowNewPredictionFlash] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  
  // Refs to prevent infinite update loops
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const predictionQueueRef = useRef<Prediction[]>([]);

  // Handle mouse movement for parallax effect
  const handleMouseMove = (e: React.MouseEvent) => {
    const { clientX, clientY } = e;
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = (clientX - left) / width - 0.5;
    const y = (clientY - top) / height - 0.5;
    setCursorPosition({ x, y });
  };

  // Generate a random prediction
  const generatePrediction = (): Prediction => {
    const id = Math.random().toString(36).substring(2, 9);
    const trend = Math.random() > 0.4 ? 'bullish' : 'bearish';
    const basePrice = 84000 + (Math.random() * 4000 - 2000);
    const confidence = Math.floor(Math.random() * 50) + 50; // 50-100%
    
    // Generate points for the chart
    const points: { x: number; y: number }[] = [];
    let lastY = 50;
    const volatility = 5;
    const trendStrength = trend === 'bullish' ? 0.7 : -0.7;
    
    for (let i = 0; i < 100; i++) {
      const randomFactor = (Math.random() - 0.5) * 2 * volatility;
      let newY = lastY + randomFactor + trendStrength;
      newY = Math.max(10, Math.min(90, newY));
      points.push({ x: i, y: newY });
      lastY = newY;
    }
    
    // Generate analysis text
    const analyses = [
      "Strong momentum indicates continued upward movement",
      "Support level holding, expect bullish continuation",
      "Resistance broken, potential for further gains",
      "Bearish divergence suggests potential reversal",
      "Volume declining, caution advised",
      "Key level approaching, prepare for volatility"
    ];
    
    return {
      id,
      price: basePrice,
      confidence,
      trend,
      analysis: analyses[Math.floor(Math.random() * analyses.length)],
      points
    };
  };

  // Initialize predictions
  useEffect(() => {
    // Generate initial predictions
    const initialPredictions = [
      generatePrediction(),
      generatePrediction(),
      generatePrediction(),
      generatePrediction()
    ];
    
    predictionQueueRef.current = initialPredictions;
    setPredictions(initialPredictions);
    
    // Set up countdown timer
    countdownIntervalRef.current = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          // Update to next prediction when countdown reaches 0
          updateToNextPrediction();
          return 15; // Reset countdown
        }
        return prev - 1;
      });
    }, 1000);
    
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  // Update to next prediction
  const updateToNextPrediction = () => {
    setIsUpdating(true);
    setShowNewPredictionFlash(true);
    
    // Move to next prediction in queue
    setCurrentPredictionIndex(prev => {
      const nextIndex = (prev + 1) % predictions.length;
      
      // Generate a new prediction to add to the queue
      const newPrediction = generatePrediction();
      
      // Update the queue by removing the oldest and adding the new one
      const updatedPredictions = [...predictions];
      updatedPredictions[prev] = newPrediction;
      
      predictionQueueRef.current = updatedPredictions;
      setPredictions(updatedPredictions);
      
      return nextIndex;
    });
    
    // Reset updating state after animation
    setTimeout(() => setIsUpdating(false), 500);
    
    // Hide new prediction flash after a moment
    setTimeout(() => setShowNewPredictionFlash(false), 2000);
  };

  // Get current prediction
  const currentPrediction = predictions[currentPredictionIndex] || predictions[0];

  // Create SVG path from points
  const createPath = (): string => {
    if (!currentPrediction || !currentPrediction.points.length) return '';
    
    // Scale points to fit SVG
    const scaledPoints = currentPrediction.points.map((point, index) => ({
      x: (index / (currentPrediction.points.length - 1)) * 100,
      y: point.y,
    }));
    
    // Create SVG path
    let path = `M ${scaledPoints[0].x},${scaledPoints[0].y}`;
    
    for (let i = 1; i < scaledPoints.length; i++) {
      path += ` L ${scaledPoints[i].x},${scaledPoints[i].y}`;
    }
    
    return path;
  };

  // Get confidence color
  const getConfidenceColor = (confidence: number) => {
    if (confidence < 50) return { color: "#EF4444", gradient: "redGradient" }; // Low - Red
    if (confidence < 75) return { color: "#F59E0B", gradient: "orangeGradient" }; // Medium - Orange
    return { color: "#10B981", gradient: "greenGradient" }; // High - Green
  };

  // Get current confidence color
  const confidenceColor = currentPrediction ? 
    getConfidenceColor(currentPrediction.confidence) : 
    { color: "#10B981", gradient: "greenGradient" };

  return (
    <div 
      className="relative w-full h-full"
      onMouseMove={handleMouseMove}
    >
      {/* Holographic Card with Parallax Effect */}
      <motion.div 
        className="absolute inset-0 rounded-xl backdrop-blur-sm bg-black/30 border border-blue-500/20 overflow-hidden"
        style={{
          boxShadow: `0 0 20px 5px rgba(59, 130, 246, 0.2)`,
          transform: `perspective(1000px) rotateX(${cursorPosition.y * 5}deg) rotateY(${-cursorPosition.x * 5}deg)`
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Neon Glow Border */}
        <div className="absolute inset-0 border border-blue-500/30 rounded-xl" 
          style={{ 
            boxShadow: `inset 0 0 15px 2px rgba(59, 130, 246, 0.3)`,
          }} 
        />
        
        {/* Shimmer Effect */}
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-500/10 to-transparent"
          animate={{ 
            x: ['-100%', '100%'],
            opacity: [0, 0.5, 0]
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 3,
            ease: "easeInOut"
          }}
        />
        
        {/* New Prediction Flash */}
        <AnimatePresence>
          {showNewPredictionFlash && (
            <motion.div 
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.5 }}
              transition={{ duration: 0.5 }}
            >
              <div className="bg-blue-500/20 backdrop-blur-md px-6 py-3 rounded-full border border-blue-500/50">
                <span className="text-blue-300 font-bold text-lg">New Prediction</span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Content Container */}
        <div className="absolute inset-0 p-6">
          {/* Header with Prediction Info */}
          <div className="flex justify-between items-start mb-4">
            <AnimatePresence mode="wait">
              <motion.div
                key={`price-${currentPrediction?.id}`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col"
              >
                <div className="flex items-center">
                  <h3 className="text-lg font-bold text-white">BTC/USD Prediction</h3>
                  <div className="ml-2 px-2 py-1 rounded-full bg-blue-500/20 flex items-center">
                    <span className="text-xs text-blue-300 font-medium">AI-Powered</span>
                    <motion.div 
                      className="ml-1 w-2 h-2 rounded-full bg-blue-400"
                      animate={{ 
                        scale: [1, 1.5, 1],
                        opacity: [1, 0.5, 1]
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center mt-1">
                  <span className={`text-xl font-bold ${currentPrediction?.trend === 'bullish' ? 'text-green-500' : 'text-red-500'}`}>
                    ${currentPrediction?.price.toFixed(2)}
                  </span>
                  <span className={`ml-2 text-xs px-2 py-0.5 rounded-full ${
                    currentPrediction?.trend === 'bullish' ? 'bg-green-500/20 text-green-400' : 'bg-red-500/20 text-red-400'
                  }`}>
                    {currentPrediction?.trend === 'bullish' ? 'BULLISH' : 'BEARISH'}
                  </span>
                </div>
              </motion.div>
            </AnimatePresence>
            
            {/* Confidence Meter */}
            <AnimatePresence mode="wait">
              <motion.div
                key={`confidence-${currentPrediction?.id}`}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
                className="relative"
              >
                <svg width="60" height="60" viewBox="0 0 60 60">
                  <circle 
                    cx="30" 
                    cy="30" 
                    r="25" 
                    fill="none" 
                    stroke="#1E293B" 
                    strokeWidth="5" 
                  />
                  <motion.circle 
                    cx="30" 
                    cy="30" 
                    r="25" 
                    fill="none" 
                    stroke={confidenceColor.color} 
                    strokeWidth="5" 
                    strokeLinecap="round"
                    strokeDasharray={2 * Math.PI * 25}
                    strokeDashoffset={2 * Math.PI * 25 * (1 - (currentPrediction?.confidence || 0) / 100)}
                    initial={{ strokeDashoffset: 2 * Math.PI * 25 }}
                    animate={{ strokeDashoffset: 2 * Math.PI * 25 * (1 - (currentPrediction?.confidence || 0) / 100) }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                  />
                  <text 
                    x="30" 
                    y="30" 
                    textAnchor="middle" 
                    dominantBaseline="middle" 
                    fill="white" 
                    fontSize="14" 
                    fontWeight="bold"
                  >
                    {currentPrediction?.confidence}%
                  </text>
                </svg>
                <motion.div 
                  className="absolute inset-0 rounded-full"
                  animate={{ 
                    boxShadow: [
                      `0 0 5px 1px ${confidenceColor.color}33`,
                      `0 0 10px 2px ${confidenceColor.color}66`,
                      `0 0 5px 1px ${confidenceColor.color}33`
                    ]
                  }}
                  transition={{ 
                    duration: 2, 
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
              </motion.div>
            </AnimatePresence>
          </div>
          
          {/* Analysis Text */}
          <AnimatePresence mode="wait">
            <motion.div
              key={`analysis-${currentPrediction?.id}`}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              className="mb-6 bg-gray-900/30 backdrop-blur-sm p-3 rounded-lg border border-gray-700/30"
            >
              <p className="text-sm text-gray-300">{currentPrediction?.analysis}</p>
            </motion.div>
          </AnimatePresence>
          
          {/* SVG Graph */}
          <div className="relative h-[180px]">
            <svg 
              viewBox="0 0 100 100" 
              preserveAspectRatio="none" 
              className="w-full h-full"
            >
              {/* Grid lines */}
              {[...Array(5)].map((_, i) => (
                <line 
                  key={`grid-${i}`}
                  x1="0" 
                  y1={20 * i + 10} 
                  x2="100" 
                  y2={20 * i + 10} 
                  stroke="rgba(255,255,255,0.1)" 
                  strokeDasharray="1,1"
                />
              ))}
              
              {/* Prediction line */}
              <motion.path
                key={`path-${currentPrediction?.id}`}
                d={createPath()}
                fill="none"
                stroke={confidenceColor.color}
                strokeWidth="1.5"
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{ 
                  pathLength: 1, 
                  opacity: 1,
                }}
                exit={{ pathLength: 0, opacity: 0 }}
                transition={{ 
                  duration: 1.5, 
                  ease: "easeInOut"
                }}
              />
              
              {/* Area under the line */}
              <motion.path
                key={`area-${currentPrediction?.id}`}
                d={`${createPath()} L 100,100 L 0,100 Z`}
                fill={`url(#${confidenceColor.gradient})`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.2 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              />
              
              {/* Gradients */}
              <defs>
                <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#10B981" stopOpacity="0.8"/>
                  <stop offset="100%" stopColor="#10B981" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="orangeGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#F59E0B" stopOpacity="0.8"/>
                  <stop offset="100%" stopColor="#F59E0B" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#EF4444" stopOpacity="0.8"/>
                  <stop offset="100%" stopColor="#EF4444" stopOpacity="0"/>
                </linearGradient>
              </defs>
            </svg>
            
            {/* Pulse Effect on Latest Point */}
            <motion.div
              className="absolute right-0 bottom-[50%] w-4 h-4 rounded-full"
              style={{ 
                backgroundColor: confidenceColor.color,
                transform: 'translate(50%, 50%)'
              }}
              animate={{ 
                boxShadow: [
                  `0 0 0px ${confidenceColor.color}`,
                  `0 0 10px ${confidenceColor.color}`,
                  `0 0 0px ${confidenceColor.color}`
                ],
                scale: [1, 1.2, 1]
              }}
              transition={{ 
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            
            {/* Update flash animation */}
            <AnimatePresence>
              {isUpdating && (
                <motion.div 
                  className="absolute inset-0 bg-white"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.3 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                />
              )}
            </AnimatePresence>
          </div>
          
          {/* Price targets */}
          <div className="mt-4 flex justify-between">
            <AnimatePresence mode="wait">
              <motion.div
                key={`targets-${currentPrediction?.id}`}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.5 }}
                className="flex space-x-4"
              >
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-green-500 mr-2"></div>
                  <span className="text-xs text-gray-300">Target: </span>
                  <span className="text-xs text-green-500 ml-1 font-medium">
                    ${(currentPrediction?.price * 1.05).toFixed(2)}
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                  <span className="text-xs text-gray-300">Stop: </span>
                  <span className="text-xs text-red-500 ml-1 font-medium">
                    ${(currentPrediction?.price * 0.97).toFixed(2)}
                  </span>
                </div>
              </motion.div>
            </AnimatePresence>
            
            {/* Countdown Timer */}
            <div className="flex items-center space-x-1 bg-gray-800/70 px-3 py-1 rounded-full">
              <svg className="w-4 h-4 text-gray-400 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <AnimatePresence mode="wait">
                <motion.span 
                  key={countdown}
                  className="text-sm text-white font-medium"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  Next in: <span className="text-blue-300">{countdown}s</span>
                </motion.span>
              </AnimatePresence>
            </div>
          </div>
          
          {/* Prediction Queue Indicators */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {predictions.map((_, index) => (
              <div 
                key={`indicator-${index}`}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${
                  index === currentPredictionIndex ? 'bg-blue-500 w-4' : 'bg-gray-500'
                }`}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AnimatedPredictionGraph;