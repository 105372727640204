import React from 'react';
import { ChevronUp, DollarSign, Clock, CheckCircle } from 'lucide-react';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import Breadcrumb from '../../components/ui/Breadcrumb';
import RefundForm from '../../components/legal/RefundForm';
import RefundTimeline from '../../components/legal/RefundTimeline';

const Refund = () => {
  const { showScrollTop, scrollToTop } = useScrollToTop();

  const steps = [
    {
      icon: <DollarSign className="w-6 h-6" />,
      title: 'Request Submission',
      description: 'Fill out the refund request form with your order details'
    },
    {
      icon: <Clock className="w-6 h-6" />,
      title: 'Review Process',
      description: 'Our team reviews your request within 24-48 hours'
    },
    {
      icon: <CheckCircle className="w-6 h-6" />,
      title: 'Refund Processing',
      description: 'Approved refunds are processed within 5-7 business days'
    }
  ];

  return (
    <div className="min-h-screen bg-black pt-20">
      <div className="container mx-auto px-4 py-8">
        <Breadcrumb 
          items={[
            { label: 'Home', href: '/' },
            { label: 'Refund Policy', href: '/refund' }
          ]} 
        />

        <h1 className="text-4xl font-bold text-white mb-8">Refund Policy</h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <div className="bg-gray-900 rounded-xl p-6 mb-8">
              <h2 className="text-2xl font-semibold text-white mb-4">
                Refund Process
              </h2>
              <p className="text-gray-400 mb-6">
                We offer a 7-day money-back guarantee for all our subscription plans.
                If you're not satisfied with our service, you can request a refund
                within 7 days of your purchase.
              </p>
              <RefundTimeline steps={steps} />
            </div>

            <div className="bg-gray-900 rounded-xl p-6">
              <h2 className="text-2xl font-semibold text-white mb-4">
                Eligibility Criteria
              </h2>
              <ul className="list-disc list-inside text-gray-400 space-y-2">
                <li>Request made within 7 days of purchase</li>
                <li>Account in good standing</li>
                <li>No previous refunds issued</li>
                <li>Limited usage of premium features</li>
              </ul>
            </div>
          </div>

          <div className="bg-gray-900 rounded-xl p-6">
            <h2 className="text-2xl font-semibold text-white mb-6">
              Request a Refund
            </h2>
            <RefundForm />
          </div>
        </div>

        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 bg-yellow-500 p-3 rounded-full shadow-lg transition-all hover:bg-yellow-400"
            aria-label="Scroll to top"
          >
            <ChevronUp className="w-6 h-6 text-black" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Refund;