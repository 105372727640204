import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TrendingUp, TrendingDown, Clock } from 'lucide-react';

interface DataPoint {
  value: number;
  timestamp: number;
}

interface AreaChartProps {
  symbol: string;
  initialValue?: number;
  volatility?: number;
  updateInterval?: number;
  trendBias?: number; // 0-1, higher means more likely to trend up
  type?: 'forex' | 'stock' | 'crypto' | 'commodity';
  gradientColors?: {
    start: string;
    end: string;
  };
}

const AreaChart: React.FC<AreaChartProps> = ({
  symbol,
  initialValue = 100,
  volatility = 1,
  updateInterval = 15,
  trendBias = 0.5,
  type = 'forex',
  gradientColors = {
    start: '#3B82F6',
    end: '#8B5CF6'
  }
}) => {
  const [data, setData] = useState<DataPoint[]>([]);
  const [isUptrend, setIsUptrend] = useState<boolean>(Math.random() > 0.5);
  const [countdown, setCountdown] = useState<number>(updateInterval);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [predictionConfidence, setPredictionConfidence] = useState<number>(
    Math.floor(Math.random() * 16) + 80
  );
  const [currentValue, setCurrentValue] = useState<number>(initialValue);
  
  // Use refs to prevent infinite update loops
  const currentValueRef = useRef<number>(initialValue);
  const isUptrendRef = useRef<boolean>(Math.random() > 0.5);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Generate random data points that follow a trend
  const generateDataPoints = (count: number, isUptrend: boolean, startValue: number): DataPoint[] => {
    const points: DataPoint[] = [];
    let baseValue = startValue;
    const now = Date.now();
    
    for (let i = 0; i < count; i++) {
      // Create some volatility but follow the general trend
      const randomFactor = Math.random() * 2 - 1; // -1 to 1
      const volatilityFactor = randomFactor * volatility;
      const trendFactor = isUptrend ? volatility * 0.2 : -volatility * 0.2;
      baseValue += volatilityFactor + trendFactor;
      
      // Ensure we don't go below zero
      if (baseValue < 1) baseValue = 1 + Math.random() * 5;
      
      points.push({
        value: baseValue,
        timestamp: now - (count - i) * 1000
      });
    }
    
    return points;
  };

  // Initialize data
  useEffect(() => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    // Initialize with data
    const initialData = generateDataPoints(30, isUptrendRef.current, initialValue);
    setData(initialData);
    currentValueRef.current = initialData[initialData.length - 1].value;
    setCurrentValue(currentValueRef.current);
    
    // Set up interval for countdown
    intervalRef.current = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          // Trigger update animation
          setIsUpdating(true);
          
          // Generate new data with possibly new trend
          const newIsUptrend = Math.random() > (1 - trendBias);
          isUptrendRef.current = newIsUptrend;
          setIsUptrend(newIsUptrend);
          
          const newData = generateDataPoints(30, newIsUptrend, currentValueRef.current);
          setData(newData);
          
          currentValueRef.current = newData[newData.length - 1].value;
          setCurrentValue(currentValueRef.current);
          
          // Generate new confidence level (80-95%)
          setPredictionConfidence(Math.floor(Math.random() * 16) + 80);
          
          // Reset updating state after animation
          setTimeout(() => setIsUpdating(false), 1000);
          
          return updateInterval; // Reset countdown
        }
        return prev - 1;
      });
    }, 1000);
    
    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [initialValue, volatility, trendBias, updateInterval]); // Remove dependencies that change frequently
  
  // Calculate SVG path for the area chart
  const getPath = (): string => {
    if (!data.length) return '';
    
    const width = 300;
    const height = 150;
    const padding = 10;
    
    // Find min and max values for scaling
    const minValue = Math.min(...data.map(d => d.value));
    const maxValue = Math.max(...data.map(d => d.value));
    const valueRange = maxValue - minValue || 1; // Avoid division by zero
    
    // Scale points to fit in our SVG
    const points = data.map((point, index) => {
      const x = padding + (index / (data.length - 1)) * (width - padding * 2);
      const y = height - padding - ((point.value - minValue) / valueRange) * (height - padding * 2);
      return `${x},${y}`;
    });
    
    // Create path that goes down to bottom right, then bottom left, then back to start
    return `M ${points.join(' L ')} L ${width - padding},${height - padding} L ${padding},${height - padding} Z`;
  };

  // Format price based on asset type
  const formatPrice = (value: number): string => {
    switch (type) {
      case 'forex':
        return value.toFixed(4);
      case 'stock':
        return value.toFixed(2);
      case 'crypto':
        return value.toFixed(2);
      case 'commodity':
        return value.toFixed(2);
      default:
        return value.toFixed(2);
    }
  };

  // Get currency/unit symbol
  const getCurrencySymbol = (): string => {
    switch (type) {
      case 'forex':
        return '';
      case 'stock':
        return '$';
      case 'crypto':
        return '$';
      case 'commodity':
        return '$';
      default:
        return '$';
    }
  };
  
  return (
    <div className="relative w-full h-full">
      {/* Glassmorphism Card */}
      <motion.div 
        className="absolute inset-0 rounded-xl backdrop-blur-sm bg-black/30 border border-blue-500/20 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Shimmer Effect */}
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-500/10 to-transparent"
          animate={{ 
            x: ['-100%', '100%'],
            opacity: [0, 0.5, 0]
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 3,
            ease: "easeInOut"
          }}
        />
        
        {/* Graph Title */}
        <div className="absolute top-4 left-4 flex items-center space-x-2">
          <div className={`p-1 rounded-full ${isUptrend ? 'bg-green-500/20' : 'bg-red-500/20'}`}>
            {isUptrend ? 
              <TrendingUp className="h-4 w-4 text-green-500" /> : 
              <TrendingDown className="h-4 w-4 text-red-500" />
            }
          </div>
          <div>
            <h3 className="text-sm font-semibold text-white">AI Prediction</h3>
            <p className="text-xs text-gray-400">{symbol}</p>
          </div>
        </div>
        
        {/* Confidence Level */}
        <div className="absolute top-4 right-4 bg-blue-500/20 px-2 py-1 rounded-full">
          <p className="text-xs text-blue-400 font-medium">{predictionConfidence}% Confidence</p>
        </div>
        
        {/* Countdown Timer */}
        <div className="absolute bottom-4 right-4 flex items-center space-x-1 bg-gray-900/50 px-2 py-1 rounded-full">
          <Clock className="h-3 w-3 text-gray-400" />
          <AnimatePresence mode="wait">
            <motion.p 
              key={countdown}
              className="text-xs text-gray-300"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              Next update in: <span className="font-medium text-white">{countdown}s</span>
            </motion.p>
          </AnimatePresence>
        </div>
        
        {/* Price Value */}
        <div className="absolute bottom-4 left-4">
          <AnimatePresence mode="wait">
            <motion.p 
              key={data.length ? data[data.length - 1].value.toFixed(4) : '0'}
              className={`text-lg font-bold ${isUptrend ? 'text-green-500' : 'text-red-500'}`}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              {getCurrencySymbol()}{data.length ? formatPrice(data[data.length - 1].value) : 0}
            </motion.p>
          </AnimatePresence>
          <p className="text-xs text-gray-400">Current Price</p>
        </div>
        
        {/* Graph SVG */}
        <div className="absolute inset-0 flex items-center justify-center">
          <svg 
            width="300" 
            height="150" 
            viewBox="0 0 300 150" 
            className="overflow-visible"
          >
            {/* Grid Lines */}
            {[...Array(5)].map((_, i) => (
              <line 
                key={`grid-${i}`}
                x1="0" 
                y1={30 * i + 15} 
                x2="300" 
                y2={30 * i + 15} 
                stroke="rgba(255,255,255,0.1)" 
                strokeDasharray="2,2"
              />
            ))}
            
            {/* Gradient Area */}
            <defs>
              <linearGradient 
                id={`areaGradient-${symbol.replace('/', '-')}`} 
                x1="0" 
                y1="0" 
                x2="0" 
                y2="1"
              >
                <stop offset="0%" stopColor={gradientColors.start} stopOpacity="0.5"/>
                <stop offset="100%" stopColor={gradientColors.end} stopOpacity="0.1"/>
              </linearGradient>
            </defs>
            
            {/* Animated Area */}
            <motion.path
              d={getPath()}
              fill={`url(#areaGradient-${symbol.replace('/', '-')})`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            />
            
            {/* Line on top of area */}
            <motion.path
              d={getPath().split(' Z')[0]} // Remove the Z part to get just the top line
              fill="none"
              stroke="rgba(255,255,255,0.5)"
              strokeWidth="1.5"
              initial={{ pathLength: 0, opacity: 0 }}
              animate={{ pathLength: 1, opacity: 1 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
            />
            
            {/* Data Points */}
            {data.map((point, index) => {
              // Only show every 5th point for cleaner look
              if (index % 5 !== 0 && index !== data.length - 1) return null;
              
              // Calculate position
              const width = 300;
              const height = 150;
              const padding = 10;
              const minValue = Math.min(...data.map(d => d.value));
              const maxValue = Math.max(...data.map(d => d.value));
              const valueRange = maxValue - minValue || 1; // Avoid division by zero
              
              const x = padding + (index / (data.length - 1)) * (width - padding * 2);
              const y = height - padding - ((point.value - minValue) / valueRange) * (height - padding * 2);
              
              return (
                <motion.circle
                  key={`point-${index}`}
                  cx={x}
                  cy={y}
                  r={index === data.length - 1 ? 4 : 2}
                  fill="white"
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: index * 0.02, duration: 0.5 }}
                />
              );
            })}
            
            {/* Pulse Effect on Latest Point */}
            {data.length > 0 && (
              <motion.circle
                cx={300 - 10}
                cy={(() => {
                  const height = 150;
                  const padding = 10;
                  const minValue = Math.min(...data.map(d => d.value));
                  const maxValue = Math.max(...data.map(d => d.value));
                  const valueRange = maxValue - minValue || 1; // Avoid division by zero
                  const lastPoint = data[data.length - 1];
                  return height - padding - ((lastPoint.value - minValue) / valueRange) * (height - padding * 2);
                })()}
                r={12}
                fill="rgba(255, 255, 255, 0.2)"
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: [0, 1.5, 0], opacity: [0, 0.5, 0] }}
                transition={{ 
                  repeat: Infinity,
                  duration: 2,
                  ease: "easeInOut"
                }}
              />
            )}
          </svg>
          
          {/* Update Flash Animation */}
          <AnimatePresence>
            {isUpdating && (
              <motion.div 
                className="absolute inset-0 bg-white"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.3 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default AreaChart;