import React from 'react';
import { ExternalLink } from 'lucide-react';
import { MarketNews } from '../../types';

interface NewsCardProps {
  news: MarketNews;
}

const NewsCard: React.FC<NewsCardProps> = ({ news }) => {
  const sentimentColor = {
    positive: 'text-green-500',
    negative: 'text-red-500',
    neutral: 'text-gray-400',
  }[news.sentiment];

  return (
    <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 rounded-xl p-6 hover:transform hover:-translate-y-1 transition-all">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-bold text-white">{news.title}</h3>
        <span className={`text-sm font-medium ${sentimentColor}`}>
          {news.sentiment.charAt(0).toUpperCase() + news.sentiment.slice(1)}
        </span>
      </div>
      
      <p className="text-gray-200 mb-4 line-clamp-3">{news.description}</p>
      
      <div className="flex justify-between items-center text-sm">
        <span className="text-gray-300">
          {new Date(news.publishedAt).toLocaleDateString()}
        </span>
        <a
          href={news.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-yellow-300 hover:text-yellow-200"
        >
          Read More
          <ExternalLink size={16} className="ml-1" />
        </a>
      </div>
    </div>
  );
};

export default NewsCard;