/**
 * Dynamically loads a script into the HTML document.
 * @param src - The source URL of the script to load.
 * @returns A promise that resolves when the script is loaded or rejects on failure.
 */
export const loadScript = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
  
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
  
      document.body.appendChild(script);
    });
  };
  