import React from 'react';
import { Shield, Target, Users, Award } from 'lucide-react';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black pt-20">
      <Helmet>
        <title>About ChartTalks | AI-Powered Trading Predictions Platform</title>
        <meta name="description" content="Learn about ChartTalks, the leading AI-powered trading platform providing institutional-grade predictions for forex, stocks, cryptocurrencies, and gold markets." />
        <meta name="keywords" content="trading platform, AI trading, institutional trading, smart money concepts, forex trading platform, stock market analysis, crypto predictions, gold trading" />
      </Helmet>
      
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">
            About{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              ChartTalks
            </span>
          </h1>
          <p className="text-gray-400">
            Empowering traders with institutional-grade market analysis and
            predictions powered by advanced AI technology across forex, stocks, cryptocurrencies, and gold markets.
          </p>
        </div>

        {/* Mission and Vision */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-8 rounded-xl border border-blue-500/10">
            <h2 className="text-2xl font-bold text-white mb-4">Our Mission</h2>
            <p className="text-gray-400">
              To democratize access to institutional-grade trading insights and
              empower individual traders with advanced market analysis tools that
              were previously available only to large financial institutions across forex, stocks, cryptocurrencies, and gold markets.
            </p>
          </div>

          <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-8 rounded-xl border border-blue-500/10">
            <h2 className="text-2xl font-bold text-white mb-4">Our Vision</h2>
            <p className="text-gray-400">
              To become the leading platform for AI-powered market predictions,
              helping traders make informed decisions across global financial
              markets using cutting-edge technology and institutional trading
              concepts for forex, stocks, cryptocurrencies, and gold.
            </p>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {[
            {
              icon: <Shield className="h-8 w-8 text-white" />,
              title: 'Trusted Platform',
              description: 'Security and reliability at the core of our service for forex, stocks, crypto, and gold traders',
            },
            {
              icon: <Target className="h-8 w-8 text-white" />,
              title: 'Precision',
              description: 'Accurate predictions backed by advanced algorithms across all financial markets',
            },
            {
              icon: <Users className="h-8 w-8 text-white" />,
              title: 'Community',
              description: 'Growing community of successful traders across forex, stocks, crypto, and gold markets',
            },
            {
              icon: <Award className="h-8 w-8 text-white" />,
              title: 'Excellence',
              description: 'Commitment to continuous improvement in our trading predictions and analysis',
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl text-center border border-blue-500/10"
            >
              <div className="flex justify-center mb-4">
                <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full">
                  {item.icon}
                </div>
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                {item.title}
              </h3>
              <p className="text-gray-400">{item.description}</p>
            </div>
          ))}
        </div>

        {/* Our Technology */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white mb-8 text-center">Our Technology</h2>
          <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-8 rounded-xl border border-blue-500/10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold text-white mb-4">AI-Powered Analysis</h3>
                <p className="text-gray-400 mb-4">
                  Our proprietary AI algorithms analyze vast amounts of market data, institutional order flow, and price action patterns to identify high-probability trading opportunities across forex, stocks, cryptocurrencies, and gold markets.
                </p>
                <p className="text-gray-400">
                  By combining machine learning with institutional trading concepts, we provide retail traders with insights previously available only to market makers and large financial institutions.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-white mb-4">Smart Money Concepts</h3>
                <p className="text-gray-400 mb-4">
                  We incorporate Smart Money Concepts (SMC) and Institutional Trading Concepts (ITC) into our analysis, tracking the movements of large market participants who control significant capital in forex, stocks, crypto, and gold markets.
                </p>
                <p className="text-gray-400">
                  Our platform identifies key market structures, order blocks, liquidity zones, and institutional price levels to help you align your trading with smart money rather than against it.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Markets We Cover */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white mb-8 text-center">Markets We Cover</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
              <h3 className="text-xl font-semibold text-white mb-4">Forex</h3>
              <p className="text-gray-400">
                Comprehensive analysis of major, minor, and exotic currency pairs including EUR/USD, GBP/USD, USD/JPY, and more. Our forex predictions identify optimal entry and exit points based on institutional order flow.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
              <h3 className="text-xl font-semibold text-white mb-4">Stocks</h3>
              <p className="text-gray-400">
                In-depth analysis of major indices (S&P 500, NASDAQ, Dow Jones) and individual stocks across various sectors. Our stock market predictions help you identify high-probability trading opportunities.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
              <h3 className="text-xl font-semibold text-white mb-4">Cryptocurrencies</h3>
              <p className="text-gray-400">
                Detailed analysis of Bitcoin, Ethereum, and major altcoins. Our crypto predictions incorporate on-chain metrics, exchange flows, and institutional adoption patterns to identify optimal trading opportunities.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 p-6 rounded-xl border border-blue-500/10">
              <h3 className="text-xl font-semibold text-white mb-4">Gold</h3>
              <p className="text-gray-400">
                Precise analysis of XAU/USD price movements based on macroeconomic factors, central bank policies, and technical patterns. Our gold trading forecasts help you navigate the precious metals market with confidence.
              </p>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-gradient-to-r from-blue-1000 to-purple-1000 rounded-xl p-8 text-center border border-blue-500/10">
          <h2 className="text-2xl font-bold text-white mb-4">
            Join Our Trading Community
          </h2>
          <p className="text-gray-400 mb-6">
            Start making data-driven trading decisions with institutional-grade
            insights for forex, stocks, cryptocurrencies, and gold markets today.
          </p>
          <button className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-full font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors">
            Get Started Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;