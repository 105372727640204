import React from 'react';
import { Check, Crown } from 'lucide-react';
import { SubscriptionPlan } from '../../types/subscription';

interface PlanCardProps {
  plan: SubscriptionPlan;
  isLoading: boolean;
  onSubscribe: (plan: SubscriptionPlan) => void;
  isCurrentPlan?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  isLoading,
  onSubscribe,
  isCurrentPlan,
}) => {
  return (
    <div
      className={`relative bg-gradient-to-r from-blue-1000 to-purple-1000 rounded-xl p-6 hover:transform hover:-translate-y-1 hover:shadow-lg transition-all ${
        plan.recommended ? 'border-2 border-yellow-500' : ''
      }`}
    >
      {plan.recommended && (
        <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-yellow-500 text-black px-4 py-1 rounded-full text-sm font-semibold">
          Recommended
        </div>
      )}

      <div className="text-center mb-6">
        <h3 className="text-xl font-bold text-white flex items-center justify-center gap-2">
          {plan.name}
          {plan.tier === 'gold' && <Crown className="h-5 w-5 text-yellow-300" />}
        </h3>
        <div className="mt-2">
          <span className="text-3xl font-bold text-white">₹{plan.price}</span>
          <span className="text-gray-200">/month</span>
        </div>
      </div>

      <ul className="space-y-3 mb-6">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <Check className="h-5 w-5 text-green-300 flex-shrink-0" />
            <span className="text-gray-200">{feature}</span>
          </li>
        ))}
      </ul>

      <button
        onClick={() => onSubscribe(plan)}
        disabled={isLoading || isCurrentPlan}
        className={`w-full py-2 rounded-lg font-semibold transition-all ${
          isCurrentPlan
            ? 'bg-green-500 text-white cursor-default'
            : plan.recommended
            ? 'bg-yellow-500 text-black hover:bg-yellow-400'
            : 'border border-yellow-300 text-yellow-300 hover:bg-yellow-500/10'
        }`}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            Processing...
          </span>
        ) : isCurrentPlan ? (
          'Current Plan'
        ) : (
          'Subscribe Now'
        )}
      </button>
    </div>
  );
};

export default PlanCard;