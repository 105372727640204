import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Mail, ArrowLeft, RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../context/AuthContext';
import { useToastContext } from '../../context/ToastContext';

const CheckEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshUser } = useAuth();
  const { showToast } = useToastContext();
  const [isChecking, setIsChecking] = useState(false);
  const email = location.state?.email || 'your email';

  const handleVerificationCheck = async () => {
    try {
      setIsChecking(true);
      showToast('info', 'Checking verification status...');
      
      // Refresh user data to check if verification status has changed
      const updatedUser = await refreshUser();
      
      if (updatedUser && updatedUser.isVerified) {
        // User is verified, show success message and redirect
        showToast('success', 'Email verified successfully! Redirecting...');
        setTimeout(() => navigate('/'), 1500);
      } else {
        // User is still not verified
        showToast('warning', 'Your email is still not verified. Please check your inbox and click the verification link.');
      }
    } catch (error) {
      console.error('Failed to check verification status:', error);
      showToast('error', 'Failed to check verification status. Please try again.');
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black flex items-center justify-center px-4">
      <motion.div 
        className="max-w-md w-full bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-8 rounded-xl border border-blue-500/10 shadow-gold"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Email Icon */}
        <div className="flex justify-center mb-6">
          <div className="p-4 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full">
            <Mail className="h-10 w-10 text-white" />
          </div>
        </div>

        {/* Title and Description */}
        <h2 className="text-2xl font-bold text-white text-center mb-4">Check Your Email</h2>
        <p className="text-gray-300 text-center mb-8">
          We've sent a verification link to <span className="text-blue-400 font-medium">{email}</span>. 
          Please check your inbox and click the link to verify your account.
        </p>

        {/* Email Verification Steps */}
        <div className="bg-black/30 rounded-lg p-4 mb-6">
          <h3 className="text-lg font-semibold text-white mb-3">Next Steps:</h3>
          <ol className="space-y-3 text-gray-300">
            <li className="flex items-start">
              <span className="bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs mr-2 mt-0.5">1</span>
              <span>Check your email inbox (and spam folder)</span>
            </li>
            <li className="flex items-start">
              <span className="bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs mr-2 mt-0.5">2</span>
              <span>Click the verification link in the email</span>
            </li>
            <li className="flex items-start">
              <span className="bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs mr-2 mt-0.5">3</span>
              <span>Once verified, you'll be automatically redirected to the platform</span>
            </li>
          </ol>
        </div>

        {/* Action Buttons */}
        <div className="space-y-3">
          <button
            onClick={handleVerificationCheck}
            disabled={isChecking}
            className="w-full flex items-center justify-center space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 rounded-lg font-semibold hover:from-blue-600 hover:to-purple-600 transition-colors disabled:opacity-70"
          >
            {isChecking ? (
              <>
                <RefreshCw className="h-5 w-5 animate-spin" />
                <span>Checking...</span>
              </>
            ) : (
              <>
                <RefreshCw className="h-5 w-5" />
                <span>I've Verified My Email</span>
              </>
            )}
          </button>

          <button
            onClick={() => navigate('/')}
            className="w-full flex items-center justify-center space-x-2 bg-transparent border border-blue-500 text-blue-500 py-3 rounded-lg font-semibold hover:bg-blue-500/10 transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back to Home</span>
          </button>
        </div>

        {/* Help Text */}
        <p className="text-gray-400 text-sm text-center mt-6">
          Didn't receive the email? Check your spam folder or{' '}
          <button 
            className="text-blue-400 hover:text-blue-300 underline"
            onClick={() => navigate('/auth/signup')}
          >
            try again
          </button>
        </p>
      </motion.div>
    </div>
  );
};

export default CheckEmail;