import { z } from 'zod';

export const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
});

export const signupSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  phone: z.string()
    .min(7, 'Phone number must be at least 7 digits') // Adjust based on your requirements
    .max(15, 'Phone number cannot exceed 15 digits') // Adjust based on your requirements
    .regex(/^\d+$/, 'Phone number must contain only digits'), // Ensure only digits are allowed
  password: z.string()
    .min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number')
    .regex(/[^A-Za-z0-9]/, 'Password must contain at least one special character'),
  confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

export const predictionSchema = z.object({
  symbol: z.string().min(1, 'Symbol is required'),
  category: z.enum(['forex', 'stocks', 'commodities'], {
    errorMap: () => ({ message: 'Invalid category' }),
  }),
  subscriptionTier: z.enum(['free', 'regular', 'pro', 'premium', 'gold'], {
    errorMap: () => ({ message: 'Invalid subscription tier' }),
  }),
  type: z.enum(['buy', 'sell'], {
    errorMap: () => ({ message: 'Invalid type' }),
  }),
  entryPrice: z.number().positive('Entry price must be positive'),
  stopLoss: z.number().positive('Stop loss must be positive'),
  takeProfit: z.number().positive('Take profit must be positive'),
  confidence: z.number().min(0).max(100, 'Confidence must be between 0 and 100'),
  analysis: z.string().min(10, 'Analysis must be at least 10 characters'),
});