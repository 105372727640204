import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, TrendingUp, BarChart2, Activity } from 'lucide-react';

const InstitutionalInsights = () => {
  return (
    <section className="relative py-24 overflow-hidden">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-tl from-black via-blue-900/20 to-black" />
      
      {/* Animated Background Pattern */}
      <div className="absolute inset-0">
        {[...Array(10)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-40 h-40 border border-blue-500/10 rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.1, 0.2, 0.1],
            }}
            transition={{
              duration: 4,
              delay: i * 0.2,
              repeat: Infinity,
              ease: 'easeInOut',
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* SVG Animation */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="relative order-2 lg:order-1"
          >
            <div className="aspect-square bg-gradient-to-br from-blue-900/20 via-purple-900/10 to-black rounded-3xl p-8 relative overflow-hidden">
              {/* Market Chart Animation */}
              <svg
                viewBox="0 0 200 100"
                className="w-full h-full"
                style={{ filter: 'drop-shadow(0 0 20px rgba(59, 130, 246, 0.5))' }}
              >
                <motion.path
                  d="M0,50 C20,40 40,80 60,60 S100,20 120,30 S160,70 180,50 S220,40 240,50"
                  fill="none"
                  stroke="#3B82F6"
                  strokeWidth="2"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: 'loop',
                    ease: 'linear',
                  }}
                />
                
                {/* Price Points */}
                {[...Array(8)].map((_, i) => (
                  <motion.circle
                    key={i}
                    cx={i * 30}
                    cy={50 + Math.sin(i) * 20}
                    r="4"
                    fill="#3B82F6"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: i * 0.1,
                    }}
                  />
                ))}
              </svg>
            </div>
          </motion.div>

          {/* Content */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="order-1 lg:order-2"
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-8">
              Institutional-Grade{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
                Market Analysis
              </span>
            </h2>

            <div className="space-y-6">
              {[
                {
                  icon: <LineChart className="h-6 w-6" />,
                  title: 'Smart Money Tracking',
                  description: 'Follow institutional order flow and market maker movements.',
                },
                {
                  icon: <TrendingUp className="h-6 w-6" />,
                  title: 'Trend Analysis',
                  description: 'Advanced algorithms identify major market trends and reversals.',
                },
                {
                  icon: <BarChart2 className="h-6 w-6" />,
                  title: 'Volume Analysis',
                  description: 'Track institutional volume and liquidity patterns.',
                },
                {
                  icon: <Activity className="h-6 w-6" />,
                  title: 'Market Structure',
                  description: 'Identify key support, resistance, and order blocks.',
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="flex items-start space-x-4 bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-4 rounded-xl"
                >
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg text-white">
                    {item.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{item.title}</h3>
                    <p className="text-gray-400">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default InstitutionalInsights;