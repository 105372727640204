import React from 'react';
import { ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';

const mockNews = [
  {
    id: '1',
    title: 'Federal Reserve Signals Potential Rate Cuts in 2024',
    source: 'Financial Times',
    url: '#',
    timestamp: new Date().toISOString(),
    sentiment: 'positive',
  },
  {
    id: '2',
    title: 'Bitcoin Surges Past $50,000 Amid Institutional Adoption',
    source: 'CoinDesk',
    url: '#',
    timestamp: new Date().toISOString(),
    sentiment: 'positive',
  },
];

const NewsSection = () => {
  return (
    <motion.section
      className="py-20 bg-gradient-to-tl from-black via-blue-900/20 to-black"
      initial={{ opacity: 0, rotateY: -90 }}
      whileInView={{ opacity: 1, rotateY: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Market{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            News
          </span>
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {mockNews.map((item, index) => (
            <motion.a
              key={item.id}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block bg-gradient-to-r from-blue-900/10 to-purple-900/10 rounded-xl p-6 border border-blue-500/10"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-lg font-semibold text-white">{item.title}</h3>
                <ExternalLink className="h-5 w-5 text-primary flex-shrink-0 ml-4" />
              </div>

              <div className="flex justify-between items-center text-sm">
                <span className="text-gray-400">{item.source}</span>
                <span
                  className={`px-2 py-1 rounded-full text-xs font-medium ${
                    item.sentiment === 'positive'
                      ? 'bg-green-500/20 text-green-500'
                      : item.sentiment === 'negative'
                      ? 'bg-red-500/20 text-red-500'
                      : 'bg-gray-500/20 text-gray-400'
                  }`}
                >
                  {item.sentiment.charAt(0).toUpperCase() + item.sentiment.slice(1)}
                </span>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default NewsSection;