import React, { useState } from 'react';
import { ChevronUp, Download, History } from 'lucide-react';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import Breadcrumb from '../../components/ui/Breadcrumb';

const TermsConditions = () => {
  const { showScrollTop, scrollToTop } = useScrollToTop();
  const [selectedVersion, setSelectedVersion] = useState('current');

  const versions = [
    { id: 'current', date: 'March 15, 2024', label: 'Current Version' },
    { id: 'v2', date: 'January 1, 2024', label: 'Version 2.0' },
    { id: 'v1', date: 'October 1, 2023', label: 'Version 1.0' }
  ];

  const sections = [
    {
      id: 'acceptance',
      title: '1. Acceptance of Terms',
      content: `By accessing and using our services, you agree to be bound by these Terms and Conditions.`
    },
    {
      id: 'services',
      title: '2. Services',
      content: `We provide AI-powered trading predictions and analysis tools. Our services are for informational purposes only.`
    },
    {
      id: 'liability',
      title: '3. Limitation of Liability',
      content: `We are not responsible for any financial losses incurred while using our services.`
    }
  ];

  const handleExportPDF = () => {
    // Implement PDF export functionality
    console.log('Exporting PDF...');
  };

  return (
    <div className="min-h-screen bg-black pt-20">
      <div className="container mx-auto px-4 py-8">
        <Breadcrumb 
          items={[
            { label: 'Home', href: '/' },
            { label: 'Terms & Conditions', href: '/terms' }
          ]} 
        />

        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold text-white">Terms & Conditions</h1>
          
          <div className="flex items-center space-x-4">
            <select
              value={selectedVersion}
              onChange={(e) => setSelectedVersion(e.target.value)}
              className="bg-gray-800 text-white rounded-lg px-4 py-2 focus:ring-2 focus:ring-yellow-500"
            >
              {versions.map((version) => (
                <option key={version.id} value={version.id}>
                  {version.label} ({version.date})
                </option>
              ))}
            </select>

            <button
              onClick={handleExportPDF}
              className="flex items-center space-x-2 bg-yellow-500 text-black px-4 py-2 rounded-lg hover:bg-yellow-400 transition-colors"
            >
              <Download className="w-5 h-5" />
              <span>Export PDF</span>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Table of Contents */}
          <div className="md:col-span-1">
            <div className="bg-gray-900 rounded-xl p-6 sticky top-24">
              <h2 className="text-xl font-semibold text-white mb-4">Contents</h2>
              <nav>
                <ul className="space-y-2">
                  {sections.map((section) => (
                    <li key={section.id}>
                      <a
                        href={`#${section.id}`}
                        className="text-gray-400 hover:text-yellow-500 transition-colors"
                      >
                        {section.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="md:col-span-3 space-y-8">
            {sections.map((section) => (
              <div
                key={section.id}
                id={section.id}
                className="bg-gray-900 rounded-xl p-6"
              >
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {section.title}
                </h2>
                <p className="text-gray-400">{section.content}</p>
              </div>
            ))}
          </div>
        </div>

        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 bg-yellow-500 p-3 rounded-full shadow-lg transition-all hover:bg-yellow-400"
            aria-label="Scroll to top"
          >
            <ChevronUp className="w-6 h-6 text-black" />
          </button>
        )}
      </div>
    </div>
  );
};

export default TermsConditions;