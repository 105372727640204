import React from 'react';
import Lottie from 'react-lottie-player';
import { motion } from 'framer-motion';

interface AuthAnimationProps {
  view: 'login' | 'signup' | 'forgotPassword';
}

const AuthAnimation: React.FC<AuthAnimationProps> = ({ view }) => {
  const [animationData, setAnimationData] = React.useState<any>(null);

  React.useEffect(() => {
    const loadAnimation = async () => {
      try {
        let data;
        switch (view) {
          case 'login':
            data = {
              v: "5.7.1",
              fr: 29.97,
              ip: 0,
              op: 180,
              w: 500,
              h: 500,
              nm: "Login Animation",
              ddd: 0,
              assets: [],
              layers: [{
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Character",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100 },
                  r: { a: 0, k: 0 },
                  p: { a: 0, k: [250, 250, 0] },
                  a: { a: 0, k: [0, 0, 0] },
                  s: { 
                    a: 1,
                    k: [
                      { t: 0, s: [0, 0, 100], e: [100, 100, 100] },
                      { t: 30, s: [100, 100, 100] }
                    ]
                  }
                },
                shapes: [{
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [100, 100] },
                      p: { a: 0, k: [0, 0] },
                      r: { a: 0, k: 20 },
                      nm: "Body"
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [1, 0.7, 0.2] },
                      o: { a: 0, k: 100 },
                      nm: "Fill"
                    }
                  ],
                  nm: "Character"
                }]
              }]
            };
            break;
          case 'signup':
            data = {
              v: "5.7.1",
              fr: 29.97,
              ip: 0,
              op: 180,
              w: 500,
              h: 500,
              nm: "Signup Animation",
              ddd: 0,
              assets: [],
              layers: [{
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Character",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100 },
                  r: { a: 0, k: 0 },
                  p: { a: 0, k: [250, 250, 0] },
                  a: { a: 0, k: [0, 0, 0] },
                  s: { 
                    a: 1,
                    k: [
                      { t: 0, s: [0, 0, 100], e: [100, 100, 100] },
                      { t: 30, s: [100, 100, 100] }
                    ]
                  }
                },
                shapes: [{
                  ty: "gr",
                  it: [
                    {
                      ty: "el",
                      d: 1,
                      s: { a: 0, k: [100, 100] },
                      p: { a: 0, k: [0, 0] },
                      nm: "Circle"
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2, 0.5, 1] },
                      o: { a: 0, k: 100 },
                      nm: "Fill"
                    }
                  ],
                  nm: "Character"
                }]
              }]
            };
            break;
          case 'forgotPassword':
            data = {
              v: "5.7.1",
              fr: 29.97,
              ip: 0,
              op: 180,
              w: 500,
              h: 500,
              nm: "Forgot Password Animation",
              ddd: 0,
              assets: [],
              layers: [{
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Character",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100 },
                  r: { 
                    a: 1,
                    k: [
                      { t: 0, s: [0], e: [360] },
                      { t: 180, s: [360] }
                    ]
                  },
                  p: { a: 0, k: [250, 250, 0] },
                  a: { a: 0, k: [0, 0, 0] },
                  s: { a: 0, k: [100, 100, 100] }
                },
                shapes: [{
                  ty: "gr",
                  it: [
                    {
                      ty: "sr",
                      d: 1,
                      s: { a: 0, k: [100, 100] },
                      p: { a: 0, k: [0, 0] },
                      r: { a: 0, k: 0 },
                      pt: { a: 0, k: 5 },
                      ir: { a: 0, k: 50 },
                      is: { a: 0, k: 0 },
                      or: { a: 0, k: 100 },
                      os: { a: 0, k: 0 },
                      nm: "Star"
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [1, 0.5, 0.2] },
                      o: { a: 0, k: 100 },
                      nm: "Fill"
                    }
                  ],
                  nm: "Character"
                }]
              }]
            };
            break;
        }
        setAnimationData(data);
      } catch (error) {
        console.error('Error loading animation:', error);
      }
    };

    loadAnimation();
  }, [view]);

  if (!animationData) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full flex items-center justify-center overflow-hidden bg-black">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-black opacity-90" />
      <div className="absolute inset-0 bg-gradient-to-tr from-yellow-900/10 via-transparent to-green-900/10" />

      {/* Stars */}
      {Array.from({ length: 50 }).map((_, index) => (
        <motion.div
          key={index}
          className="absolute w-1 h-1 bg-white rounded-full"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{
            duration: Math.random() * 2 + 1,
            repeat: Number.POSITIVE_INFINITY,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
          whileHover={{
            scale: 2,
            transition: { duration: 0.1 },
          }}
        />
      ))}

      {/* Sun */}
      <motion.div
        className="absolute w-32 h-32 bg-gradient-to-br from-yellow-500 to-orange-500 rounded-full shadow-2xl shadow-yellow-500/50"
        style={{
          top: '40%',
          left: '40%',
          transform: 'translate(-50%, -50%)',
        }}
        initial={{ scale: 0.8, opacity: 0.8 }}
        animate={{ scale: [0.8, 1, 0.8], opacity: [0.8, 1, 0.8] }}
        transition={{
          duration: 5,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: "reverse",
          ease: "easeInOut",
        }}
      />

      {/* Planets */}
      {[
        { name: 'Earth', color: 'bg-blue-500', size: 'w-12 h-12', distance: 200, duration: 10, angleOffset: 0 },
        { name: 'Venus', color: 'bg-green-600', size: 'w-10 h-10', distance: 150, duration: 8, angleOffset: 90 },
        { name: 'Mars', color: 'bg-red-600', size: 'w-10 h-10', distance: 250, duration: 12, angleOffset: 180 },
        { name: 'Saturn', color: 'bg-orange-400', size: 'w-14 h-14', distance: 300, duration: 15, angleOffset: 270 },
      ].map((planet, index) => (
        <motion.div
          key={planet.name}
          className={`absolute ${planet.color} ${planet.size} rounded-full shadow-lg`}
          style={{
            top: '50%',
            left: '50%',
            originX: 0.5,
            originY: 0.5,
          }}
          animate={{
            rotate: 360,
            x: Math.cos((planet.angleOffset * Math.PI) / 180) * planet.distance,
            y: Math.sin((planet.angleOffset * Math.PI) / 180) * planet.distance,
            scale: [0.8, 1, 0.8],
            opacity: [0.8, 1, 0.8],
          }}
          transition={{
            duration: planet.duration,
            repeat: Number.POSITIVE_INFINITY,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
          whileHover={{ scale: 1.2 }}
        >
          <motion.div
            className="absolute w-full h-full rounded-full shadow-inner"
            style={{
              boxShadow: 'inset 0 0 10px rgb(0, 0, 0)',
            }}
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{
              duration: 3,
              repeat: Number.POSITIVE_INFINITY,
              repeatType: "reverse",
              ease: "easeInOut",
            }}
          />
        </motion.div>
      ))}

      {/* Text Animation */}
      <motion.div
        className="absolute text-6xl font-bold text-white z-10"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        style={{
          position: 'absolute',
          bottom: '70%',
          }}
        transition={{
          delay: 1.5,
          duration: 1,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: "reverse",
          ease: "easeInOut",
        }}
      >
        {view === 'login' ? 'Login' : view === 'signup' ? 'Sign Up' : 'Forgot Password'}
      </motion.div>

      {/* Lottie Animation */}
      <div className="relative z-10 w-full h-full flex items-center justify-center p-8">
        <Lottie
          loop
          animationData={animationData}
          play
          style={{ width: '100%', height: '100%' }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
        />
      </div>
    </div>
  );
};

export default AuthAnimation;