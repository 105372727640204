import React from 'react';
import { ChevronUp, Clock, Shield, Database, Download } from 'lucide-react';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import Breadcrumb from '../../components/ui/Breadcrumb';
import DataRequestForm from '../../components/legal/DataRequestForm';

const PrivacyPolicy = () => {
  const { showScrollTop, scrollToTop } = useScrollToTop();

  const sections = [
    {
      title: 'Data Collection',
      icon: <Database className="w-6 h-6 text-yellow-500" />,
      content: `We collect information that you provide directly to us, including when you create an account, 
      make a purchase, or contact us for support. This may include your name, email address, phone number, 
      and payment information.`
    },
    {
      title: 'Data Usage',
      icon: <Shield className="w-6 h-6 text-yellow-500" />,
      content: `We use the information we collect to provide, maintain, and improve our services, 
      process your transactions, and communicate with you about your account and our services.`
    },
    {
      title: 'Data Protection',
      icon: <Clock className="w-6 h-6 text-yellow-500" />,
      content: `We implement appropriate technical and organizational measures to protect your personal data 
      against unauthorized or unlawful processing, accidental loss, destruction, or damage.`
    },
    {
      title: 'Cookie Policy',
      icon: <Shield className="w-6 h-6 text-yellow-500" />,
      content: `We use cookies to enhance your browsing experience, analyze site traffic, and optimize site performance. 
      Our cookies help us remember your preferences, provide faster page loads through caching, and improve overall user experience. 
      You can manage your cookie preferences at any time through our Cookie Consent banner.`
    }
  ];

  return (
    <div className="min-h-screen bg-black pt-20">
      <div className="container mx-auto px-4 py-8">
        <Breadcrumb 
          items={[
            { label: 'Home', href: '/' },
            { label: 'Privacy Policy', href: '/privacy-policy' }
          ]} 
        />

        <h1 className="text-4xl font-bold text-white mb-8">Privacy Policy</h1>

        <div className="space-y-8">
          {sections.map((section, index) => (
            <div 
              key={index}
              className="bg-gray-900 rounded-xl p-6 transition-all hover:transform hover:scale-[1.02]"
            >
              <div className="flex items-center space-x-4 mb-4">
                {section.icon}
                <h2 className="text-2xl font-semibold text-white">{section.title}</h2>
              </div>
              <p className="text-gray-400">{section.content}</p>
            </div>
          ))}

          <div className="bg-gray-900 rounded-xl p-6">
            <div className="flex items-center space-x-4 mb-4">
              <Shield className="w-6 h-6 text-yellow-500" />
              <h2 className="text-2xl font-semibold text-white">Cookie Types</h2>
            </div>
            <div className="space-y-4">
              <div className="border-l-4 border-blue-500 pl-4">
                <h3 className="text-lg font-medium text-white mb-2">Essential Cookies</h3>
                <p className="text-gray-400">These cookies are necessary for the website to function properly and cannot be disabled.</p>
              </div>
              
              <div className="border-l-4 border-green-500 pl-4">
                <h3 className="text-lg font-medium text-white mb-2">Performance Cookies</h3>
                <p className="text-gray-400">These cookies help us optimize website performance and provide faster page loads through efficient caching and resource management.</p>
              </div>
              
              <div className="border-l-4 border-purple-500 pl-4">
                <h3 className="text-lg font-medium text-white mb-2">Analytics Cookies</h3>
                <p className="text-gray-400">These cookies help us understand how visitors interact with our website, allowing us to improve the user experience.</p>
              </div>
              
              <div className="border-l-4 border-yellow-500 pl-4">
                <h3 className="text-lg font-medium text-white mb-2">Marketing Cookies</h3>
                <p className="text-gray-400">These cookies are used to track visitors across websites to display relevant advertisements.</p>
              </div>
            </div>
          </div>

          <DataRequestForm />
        </div>

        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 bg-yellow-500 p-3 rounded-full shadow-lg transition-all hover:bg-yellow-400"
            aria-label="Scroll to top"
          >
            <ChevronUp className="w-6 h-6 text-black" />
          </button>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;