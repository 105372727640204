import React from 'react';
import { motion } from 'framer-motion';
import HeroSection from '../components/home/HeroSection';
import MarketAnalysis from '../components/home/MarketAnalysis';
import SubscriptionPlans from '../components/home/SubscriptionPlans';
import RecentPredictions from '../components/home/RecentPredictions';
import NewsSection from '../components/home/NewsSection';
import ReviewsSection from '../components/home/ReviewsSection';
import StatsSection from '../components/home/StatsSection';
import WhyPredictions from '../components/home/WhyPredictions';
import TradingInnovation from '../components/home/TradingInnovation';
import InstitutionalInsights from '../components/home/InstitutionalInsights';
import HowItWorks from '../components/home/HowItWorks';
import MarketDashboard from '../components/home/MarketDashboard';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <motion.div
      className="min-h-screen bg-gradient-to-tl from-black via-blue-900/20 to-black relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* SEO Meta Tags */}
      <Helmet>
        <title>ChartTalks - AI-Powered Trading Predictions for Forex, Stocks, Crypto & Gold</title>
        <meta name="description" content="Make data-driven trading decisions with institutional-grade analysis powered by advanced AI. Get real-time market predictions, technical analysis, and trading signals for forex, stocks, cryptocurrencies, and gold." />
        <meta name="keywords" content="forex trading predictions, stock market analysis, crypto trading signals, gold trading forecast, AI trading platform, institutional trading, smart money concepts, trading signals, market analysis" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ChartTalks - AI-Powered Trading Predictions for Forex, Stocks, Crypto & Gold" />
        <meta property="og:description" content="Make data-driven trading decisions with institutional-grade analysis powered by advanced AI. Get real-time market predictions, technical analysis, and trading signals." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ChartTalks - AI-Powered Trading Predictions for Forex, Stocks, Crypto & Gold" />
        <meta name="twitter:description" content="Make data-driven trading decisions with institutional-grade analysis powered by advanced AI. Get real-time market predictions, technical analysis, and trading signals." />
      </Helmet>
      
      {/* Main Content */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.3
            }
          }
        }}
      >
        <HeroSection />
        <StatsSection />
        <MarketDashboard />
        <TradingInnovation />
        <WhyPredictions />
        <InstitutionalInsights />
        <HowItWorks />
        <MarketAnalysis />
        <RecentPredictions />
        <ReviewsSection />
        <SubscriptionPlans />
        <NewsSection />
        
        {/* SEO-Optimized Content Sections */}
        <section className="py-16 bg-gradient-to-br from-black via-gray-900/30 to-black">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-white mb-8 text-center">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
                Advanced Trading Predictions
              </span> for Global Markets
            </h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-4">Forex Trading Predictions</h3>
                <p className="text-gray-300 mb-4">
                  Our AI-powered forex trading predictions analyze major, minor, and exotic currency pairs using institutional trading concepts. Get precise entry points, stop-loss levels, and take-profit targets for EUR/USD, GBP/USD, USD/JPY, and more. Our forex signals achieve 87% accuracy by tracking smart money movements and liquidity patterns.
                </p>
                <p className="text-gray-300">
                  Whether you're day trading or swing trading forex markets, our advanced algorithms identify key support and resistance levels, order blocks, and institutional price targets to maximize your trading success.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-4">Stock Market Analysis</h3>
                <p className="text-gray-300 mb-4">
                  Our stock market analysis covers major indices including S&P 500, NASDAQ, Dow Jones, and individual stocks across sectors. Using advanced pattern recognition and institutional order flow analysis, we provide accurate stock trading signals with precise entry and exit points.
                </p>
                <p className="text-gray-300">
                  Our AI models analyze market structure, volume profiles, and smart money concepts to identify high-probability trading opportunities in both bull and bear markets, giving you an edge in stock trading.
                </p>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-4">Cryptocurrency Trading Signals</h3>
                <p className="text-gray-300 mb-4">
                  Navigate the volatile crypto markets with our institutional-grade cryptocurrency trading signals. We analyze Bitcoin, Ethereum, Solana, and other major altcoins using advanced technical analysis and whale wallet tracking to identify optimal entry and exit points.
                </p>
                <p className="text-gray-300">
                  Our crypto predictions incorporate on-chain metrics, exchange flows, and market sentiment analysis to provide you with accurate trading signals for both spot and futures markets.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-4">Gold Trading Forecast</h3>
                <p className="text-gray-300 mb-4">
                  Our gold trading forecasts leverage institutional trading concepts to predict price movements in XAU/USD with remarkable accuracy. We analyze macroeconomic factors, central bank policies, and technical patterns to provide precise gold trading signals.
                </p>
                <p className="text-gray-300">
                  Whether you're trading gold futures, spot gold, or gold CFDs, our AI-powered analysis helps you identify key support and resistance levels, liquidity zones, and optimal entry points for profitable gold trading.
                </p>
              </div>
            </div>
          </div>
        </section>
        
        {/* FAQ Section for SEO */}
        <section className="py-16 bg-gradient-to-br from-black via-gray-900/30 to-black">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-white mb-8 text-center">
              Frequently Asked Questions About
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500"> Trading Predictions</span>
            </h2>
            
            <div className="max-w-3xl mx-auto space-y-6">
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-2">How accurate are your trading predictions?</h3>
                <p className="text-gray-300">
                  Our trading predictions achieve an average accuracy of 87% across forex, stocks, cryptocurrencies, and gold markets. This high accuracy is possible through our combination of institutional trading concepts, smart money analysis, and advanced AI algorithms that identify high-probability setups.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-2">What markets do your predictions cover?</h3>
                <p className="text-gray-300">
                  Our predictions cover forex markets (major, minor, and exotic pairs), stock markets (US, European, and Asian indices plus individual stocks), cryptocurrencies (Bitcoin, Ethereum, and major altcoins), and commodities (with special focus on gold trading). We provide comprehensive coverage across global financial markets.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-2">How do you generate your trading signals?</h3>
                <p className="text-gray-300">
                  We generate trading signals using a proprietary combination of institutional trading concepts, smart money analysis, and advanced AI algorithms. Our system analyzes market structure, order blocks, liquidity zones, and institutional order flow to identify high-probability trading opportunities with precise entry, stop-loss, and take-profit levels.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-2">What is Smart Money Concept (SMC) trading?</h3>
                <p className="text-gray-300">
                  Smart Money Concept (SMC) trading focuses on identifying and following the actions of institutional traders and market makers who control significant capital. Our platform analyzes institutional order flow, liquidity sweeps, and order blocks to help retail traders align their positions with smart money movements in forex, stocks, crypto, and gold markets.
                </p>
              </div>
              
              <div className="bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-6 rounded-xl border border-blue-500/10">
                <h3 className="text-xl font-semibold text-white mb-2">How often are new trading predictions released?</h3>
                <p className="text-gray-300">
                  We release new trading predictions daily across all markets. Premium subscribers receive real-time alerts for forex, stocks, cryptocurrencies, and gold trading opportunities as they develop, while regular subscribers receive daily consolidated reports with detailed analysis and trading setups.
                </p>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </motion.div>
  );
};

export default Home;