import React from 'react';
import { BarChart, TrendingUp, Clock, DollarSign } from 'lucide-react';

const WhyPredictions = () => {
  return (
    <section className="relative py-1 overflow-hidden bg-gray-900 px-4" id="why-predictions">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-tl from-black via-blue-900/20 to-black py-1" />
      
      <div className="container mx-auto px-4 py-1 relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-bold text-white mb-6">
              Why You Need <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Market Predictions</span>
            </h2>
            
            <div className="space-y-6">
              {[
                {
                  icon: <BarChart className="h-6 w-6" />,
                  title: 'Data-Driven Decisions',
                  description: 'Make informed trading decisions based on institutional analysis and AI insights.',
                },
                {
                  icon: <TrendingUp className="h-6 w-6" />,
                  title: 'Identify Opportunities',
                  description: 'Spot potential trading opportunities before they become obvious to the market.',
                },
                {
                  icon: <Clock className="h-6 w-6" />,
                  title: 'Save Time',
                  description: 'Let our AI analyze the markets 24/7 so you can focus on executing trades.',
                },
                {
                  icon: <DollarSign className="h-6 w-6" />,
                  title: 'Maximize Returns',
                  description: 'Optimize your trading strategy with precise entry and exit points.',
                },
              ].map((item, index) => (
                <div key={index} className="flex items-start space-x-4 bg-gradient-to-r from-blue-900/10 to-purple-900/10 p-4 rounded-xl">
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg text-white">
                    {item.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">
                      {item.title}
                    </h3>
                    <p className="text-gray-400">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="relative">
            {/* Market chart visualization */}
            <div className="aspect-square bg-black rounded-xl p-8 relative overflow-hidden mb-8">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 via-purple-900/10 to-black py-1" />
              <div className="relative z-10">
                <div className="h-full w-full flex items-center justify-center">
                  <img 
                    src="https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&q=80"
                    alt="Trading Chart"
                    className="rounded-lg object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyPredictions;