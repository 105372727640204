import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

interface BreadcrumbItem {
  label: string;
  href: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav className="flex items-center space-x-2 mb-6" aria-label="Breadcrumb">
      {items.map((item, index) => (
        <React.Fragment key={item.href}>
          <Link
            to={item.href}
            className={`text-sm ${
              index === items.length - 1
                ? 'text-yellow-500'
                : 'text-gray-400 hover:text-white'
            }`}
          >
            {item.label}
          </Link>
          {index < items.length - 1 && (
            <ChevronRight className="w-4 h-4 text-gray-600" />
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumb;