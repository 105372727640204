import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Check, Settings, Shield } from 'lucide-react';

interface CookiePreferences {
  essential: boolean;
  performance: boolean;
  analytics: boolean;
  marketing: boolean;
}

const CookieConsent: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState<CookiePreferences>({
    essential: true, // Essential cookies are always enabled
    performance: true,
    analytics: false,
    marketing: false,
  });

  // Check if user has already made a choice
  useEffect(() => {
    const consentStatus = localStorage.getItem('cookieConsent');
    if (!consentStatus) {
      // Delay showing the banner slightly for better UX
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      // If user has made a choice, load their preferences
      try {
        const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences') || '{}');
        if (Object.keys(savedPreferences).length) {
          setPreferences({
            ...preferences,
            ...savedPreferences,
          });
        }
      } catch (error) {
        console.error('Error parsing cookie preferences:', error);
      }
    }
  }, []);

  const handleAcceptAll = () => {
    const allAccepted = {
      essential: true,
      performance: true,
      analytics: true,
      marketing: true,
    };
    setPreferences(allAccepted);
    saveConsent('accepted', allAccepted);
    setShowBanner(false);
  };

  const handleDecline = () => {
    const essentialOnly = {
      essential: true,
      performance: false,
      analytics: false,
      marketing: false,
    };
    setPreferences(essentialOnly);
    saveConsent('declined', essentialOnly);
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    saveConsent('customized', preferences);
    setShowPreferences(false);
    setShowBanner(false);
  };

  const saveConsent = (status: string, prefs: CookiePreferences) => {
    // Save consent status
    localStorage.setItem('cookieConsent', status);
    localStorage.setItem('cookiePreferences', JSON.stringify(prefs));
    
    // Apply cookie settings
    applyCookieSettings(prefs);
  };

  const applyCookieSettings = (prefs: CookiePreferences) => {
    // This function would apply the actual cookie settings
    // For example, enabling/disabling Google Analytics, etc.
    console.log('Applying cookie settings:', prefs);
    
    // Example: If analytics is disabled, remove analytics cookies
    if (!prefs.analytics) {
      // Remove analytics cookies
      document.cookie = '_ga=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = '_gid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    
    // Example: If marketing is disabled, remove marketing cookies
    if (!prefs.marketing) {
      // Remove marketing cookies
      document.cookie = '_fbp=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  };

  const handleTogglePreference = (key: keyof CookiePreferences) => {
    // Don't allow toggling essential cookies
    if (key === 'essential') return;
    
    setPreferences({
      ...preferences,
      [key]: !preferences[key],
    });
  };

  if (!showBanner) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="fixed bottom-0 left-0 right-0 z-50"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <div className="bg-gray-900 border-t border-gray-800 shadow-lg">
          {showPreferences ? (
            <div className="container mx-auto p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-white flex items-center">
                  <Settings className="mr-2 h-5 w-5 text-blue-500" />
                  Cookie Preferences
                </h3>
                <button
                  onClick={() => setShowPreferences(false)}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <div className="space-y-4 mb-4">
                {/* Essential Cookies */}
                <div className="flex items-center justify-between bg-gray-800/50 p-3 rounded-lg">
                  <div>
                    <div className="flex items-center">
                      <Shield className="h-4 w-4 text-blue-500 mr-2" />
                      <span className="font-medium text-white">Essential Cookies</span>
                    </div>
                    <p className="text-sm text-gray-400 mt-1">
                      Required for the website to function properly. Cannot be disabled.
                    </p>
                  </div>
                  <div className="bg-blue-500/20 text-blue-500 px-2 py-1 rounded text-xs">
                    Required
                  </div>
                </div>
                
                {/* Performance Cookies */}
                <div 
                  className={`flex items-center justify-between p-3 rounded-lg cursor-pointer ${
                    preferences.performance ? 'bg-blue-500/10 border border-blue-500/20' : 'bg-gray-800/50'
                  }`}
                  onClick={() => handleTogglePreference('performance')}
                >
                  <div>
                    <span className="font-medium text-white">Performance Cookies</span>
                    <p className="text-sm text-gray-400 mt-1">
                      Help us improve page load times and overall site performance.
                    </p>
                  </div>
                  <div className={`w-10 h-6 rounded-full p-1 transition-colors ${
                    preferences.performance ? 'bg-blue-500' : 'bg-gray-700'
                  }`}>
                    <div className={`bg-white w-4 h-4 rounded-full transform transition-transform ${
                      preferences.performance ? 'translate-x-4' : 'translate-x-0'
                    }`} />
                  </div>
                </div>
                
                {/* Analytics Cookies */}
                <div 
                  className={`flex items-center justify-between p-3 rounded-lg cursor-pointer ${
                    preferences.analytics ? 'bg-blue-500/10 border border-blue-500/20' : 'bg-gray-800/50'
                  }`}
                  onClick={() => handleTogglePreference('analytics')}
                >
                  <div>
                    <span className="font-medium text-white">Analytics Cookies</span>
                    <p className="text-sm text-gray-400 mt-1">
                      Help us understand how visitors interact with our website.
                    </p>
                  </div>
                  <div className={`w-10 h-6 rounded-full p-1 transition-colors ${
                    preferences.analytics ? 'bg-blue-500' : 'bg-gray-700'
                  }`}>
                    <div className={`bg-white w-4 h-4 rounded-full transform transition-transform ${
                      preferences.analytics ? 'translate-x-4' : 'translate-x-0'
                    }`} />
                  </div>
                </div>
                
                {/* Marketing Cookies */}
                <div 
                  className={`flex items-center justify-between p-3 rounded-lg cursor-pointer ${
                    preferences.marketing ? 'bg-blue-500/10 border border-blue-500/20' : 'bg-gray-800/50'
                  }`}
                  onClick={() => handleTogglePreference('marketing')}
                >
                  <div>
                    <span className="font-medium text-white">Marketing Cookies</span>
                    <p className="text-sm text-gray-400 mt-1">
                      Used to track visitors across websites for advertising purposes.
                    </p>
                  </div>
                  <div className={`w-10 h-6 rounded-full p-1 transition-colors ${
                    preferences.marketing ? 'bg-blue-500' : 'bg-gray-700'
                  }`}>
                    <div className={`bg-white w-4 h-4 rounded-full transform transition-transform ${
                      preferences.marketing ? 'translate-x-4' : 'translate-x-0'
                    }`} />
                  </div>
                </div>
              </div>
              
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowPreferences(false)}
                  className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSavePreferences}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center"
                >
                  <Check className="h-4 w-4 mr-2" />
                  Save Preferences
                </button>
              </div>
            </div>
          ) : (
            <div className="container mx-auto p-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="mb-4 md:mb-0 md:mr-8 text-center md:text-left">
                  <p className="text-gray-300 text-sm md:text-base">
                    We use cookies to enhance performance, ensure fast page loads, and improve your experience. 
                    Accepting helps optimize caching, session management, and speed.
                  </p>
                </div>
                <div className="flex flex-wrap justify-center md:justify-end gap-2">
                  <button
                    onClick={() => setShowPreferences(true)}
                    className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg hover:bg-gray-700 transition-colors text-sm"
                  >
                    Manage Preferences
                  </button>
                  <button
                    onClick={handleDecline}
                    className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg hover:bg-gray-700 transition-colors text-sm"
                  >
                    Decline
                  </button>
                  <button
                    onClick={handleAcceptAll}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors text-sm"
                  >
                    Accept All
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CookieConsent;