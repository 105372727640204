import { loadScript } from './loadScript';
import { subscriptionService } from '../services/subscription.service';

interface PaymentOptions {
  planId: string;
  planType: string;  // Added planType
  amount: number;
  currency: string;
  name: string;
  email: string;
}

export const initializeRazorpay = async (options: PaymentOptions) => {
  try {
    // Load Razorpay script
    await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    // Create order using the subscription service
    const order = await subscriptionService.createOrder(
      options.planId,
      options.amount,
      options.planType  // Pass planType to createOrder
    );

    // Initialize Razorpay instance
    const razorpay = new (window as any).Razorpay({
      key: import.meta.env.VITE_RAZORPAY_KEY_ID,
      amount: order.amount,
      currency: order.currency,
      name: 'ChartTalks',
      description: `${options.planType} Subscription`,
      order_id: order.orderId,
      handler: async (response: any) => {
        try {
          // Verify payment with backend
          const result = await subscriptionService.verifyPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            planType: options.planType,  // Pass planType to verification
          });
          
          if (result.success) {
            window.location.href = '/subscription/status';
          } else {
            window.location.href = '/subscription?status=failed';
          }
        } catch (error) {
          console.error('Payment verification failed:', error);
          window.location.href = '/subscription?status=failed';
        }
      },
      prefill: {
        email: options.email,
      },
      theme: {
        color: '#FFD700',
      },
      modal: {
        ondismiss: function() {
          window.location.href = '/subscription?status=cancelled';
        }
      }
    });

    razorpay.open();
  } catch (error) {
    console.error('Failed to initialize payment:', error);
    throw error;
  }
};